import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import * as VendorsActions from '../../reducers/vendors/vendors.actions';

import { AppStore } from '../../models/app-store.model';
import { VendorsEntities } from '../../reducers/vendors/vendors.reducer';

@Injectable()
export class VendorsNgrxService {
  vendors: Observable<VendorsEntities>;
  isVendorsLoading: Observable<boolean>;
  isVendorsLoaded: Observable<boolean>;

  constructor(private store: Store<AppStore>) {
    this.vendors = store.select('vendors');
    this.isVendorsLoading = store.select(state => state.vendors.loading);
    this.isVendorsLoaded = store.select(state => state.vendors.loaded);
  }

  loadVendors(): void {
    this.store.dispatch({ type: VendorsActions.LOAD_VENDORS });
  }
}
