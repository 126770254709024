import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmountFormatPipe } from './amount-format.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [AmountFormatPipe],
  exports: [AmountFormatPipe]
})
export class AmountFormatPipeModule {}
