import { Dictionary } from '../../../../models/dictionary.model';

export const MONTHLY_COSTS: Dictionary[] = [
  {
    key: 'Ubezpieczenie pomostowe',
    label: 'Ubezp. pomostowe'
  },
  {
    key: 'Ubezpieczenie na życie',
    label: 'Ubezpieczenie na życie'
  },
  {
    key: 'Ubezpieczenie nieruchomości',
    label: 'Ubezp. nieruchomości'
  },
  {
    key: 'Ubezpieczenie od utraty pracy',
    label: 'Ubezp. od utraty pracy'
  },
  {
    key: 'Ubezpieczenia niskiego wkładu',
    label: 'Ubezp. niskiego wkładu'
  }
];
