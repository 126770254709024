import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Immutable from 'immutable';
import { Observable } from 'rxjs/Rx';
import { AppStore } from '../../models/app-store.model';
import * as OfferFiltersActions from '../../reducers/offers-filters/offers-filters.actions';
import {
  OffersAdditionalParams,
  OffersFilters,
  OffersFiltersPayload
} from '../../reducers/offers-filters/offers-filters';

@Injectable()
export class OfferFiltersNgrxService {
  filters: Observable<Immutable.Record<OffersFilters>>;
  params: Observable<Immutable.Record<OffersAdditionalParams>>;

  constructor(private store: Store<AppStore>) {
    this.filters = store.select('offerFilters');
    this.params = store.select(state => state.offerFilters.get('params'));
  }

  add(payload: OffersFiltersPayload) {
    this.store.dispatch({ type: OfferFiltersActions.ADD, payload });
  }

  merge(payload: OffersFilters) {
    this.store.dispatch({ type: OfferFiltersActions.MERGE, payload });
  }

  remove(key: string) {
    this.store.dispatch({ type: OfferFiltersActions.REMOVE, key });
  }

  removeAll() {
    this.store.dispatch({ type: OfferFiltersActions.REMOVE_ALL });
  }
}
