import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent {
  isVisible: boolean;
  scrollDistance = 100;

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isVisible = scrollPosition >= this.scrollDistance;
  }

  goTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}
