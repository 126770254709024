import { Component, Input } from '@angular/core';

@Component({
  selector: 'tab',
  styles: [
    `
      .pane {
        padding: 1em;
      }
    `
  ],
  template: `
    <div class="tab-content">
      <div class="tab-pane" [class.active]="active">
        <div class="panel-body"><ng-content></ng-content></div>
      </div>
    </div>
  `
})
export class TabComponent {
  @Input() tabTitle: string;
  @Input() active = false;
  @Input() isValid = true;

  constructor() {}
}
