import { OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CashSimulation, MortgageOffer, MortgageSimulation, CashOffer } from 'exoffice-js-client';

import * as Immutable from 'immutable';
import * as _ from 'lodash';
import { BehaviorSubject, empty, timer } from 'rxjs';
import { Subject } from 'rxjs/Subject';

import { VisibilityTreeModel } from '../../../../models/visibilityTree.model';

import { OfferFiltersNgrxService } from '../../../../services/ngrx/offer-filters-ngrx.service';
import { MortgageSimulationService } from '../../../../services/simulation/mortgage/mortgage-simulation.service';
import { VisibilityTreeService } from '../../../../services/visibility-tree/visibility-tree.service';
import { FilterByComponent } from '../components/filter-by/filter-by.component';
import { OrderByComponent } from '../components/order-by/order-by.component';
import { OFFER_FILTERS_LIST } from '../constants/offer-filters-list.constant';
import { SimulationNgrxService } from '../../../../services/ngrx/simulation-ngrx.service';
import { takeUntil } from 'rxjs/operators';
import { OffersNgrxService } from '../../../../services/ngrx/offers-ngrx.service';
import { ActivatedRoute } from '@angular/router';
import { VendorsFilterComponent } from '../../../../components/common/modal-offer-filters/partials/vendors-filter/vendors-filter.component';
import { OffersFilters } from '../../../../reducers/offers-filters/offers-filters';
import { HeadingColumnComponent } from '../components/heading-column/heading-column.component';
import { ExpandableRowNgrxService } from '../../../../services/ngrx/expandable-row-ngrx.service';
import { Dictionary } from '@ngrx/entity';
import OverlayScrollbars from 'overlayscrollbars';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-ngx';

export class CompareOffersBaseComponent implements OnInit, OnDestroy {
  total: number;
  entities: Dictionary<MortgageOffer> | Dictionary<CashOffer>;
  entitiesIds: string[] | number[];
  loading = false;
  scrollBarOptions: OverlayScrollbars.Options;

  @ViewChild(OrderByComponent, { static: false }) orderByComponent: OrderByComponent;
  @ViewChild(FilterByComponent, { static: false }) filterByComponent: FilterByComponent;
  @ViewChild('scrollOne', { static: false }) scrollOne: OverlayScrollbarsComponent;
  @ViewChild('scrollTwo', { static: false }) scrollTwo: ElementRef;
  @ViewChild('offer', { static: false }) offer: any;

  visibilityTree: VisibilityTreeModel[];
  @ViewChild(HeadingColumnComponent, { static: false }) headingColumnComponent: HeadingColumnComponent;
  @ViewChild(VendorsFilterComponent, { static: false })
  vendorsFilterComponent: VendorsFilterComponent;
  activeFilterValue: boolean;
  orderByFilterValue: string[];
  clientId: string;
  pdfLoading = false;
  latestOfferIdsSize = null;
  private refreshOffers$: BehaviorSubject<MortgageSimulation> = new BehaviorSubject(null);
  public _unsubscribeAll: Subject<any> = new Subject();
  public simulation: MortgageSimulation;
  public productVendorIdIn: Array<string | number>;

  constructor(
    visibilityTreeService: VisibilityTreeService,
    protected simulationApi: MortgageSimulationService,
    private offerFiltersService: OfferFiltersNgrxService,
    private route: ActivatedRoute,
    public simulationNgrxService: SimulationNgrxService,
    public offersEntitiesNgrxService: OffersNgrxService,
    public getOffersMethod,
    public expandableRowNgrxService: ExpandableRowNgrxService
  ) {
    this.visibilityTree = visibilityTreeService.getTree();
  }

  isAnyOfferSelected(): boolean {
    return this.simulation ? this.simulation.selectedOfferIds.length > 0 : false;
  }

  openPdfUrl(): void {
    this.pdfLoading = true;
    const visibilityArray = this.visibilityTree
      .filter(item => !item.isHidden)
      .reduce(
        (array, item) =>
          item.children
            ? array.concat(item.children.filter(child => !child.isHidden).map(child => child.id))
            : array.concat(item.id),
        []
      );

    const constantFields = ['offer.collateralValue', 'offer.loanPeriod'];
    this.simulationApi
      .addSimulationDocument(this.simulation.id, this.productVendorIdIn as string[], {
        fields: visibilityArray.concat(constantFields),
        sorts: this.orderByFilterValue
      })
      .finally(() => (this.pdfLoading = false))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        window.open(data.links.find(link => link.rel === 'download').href);
      });
  }

  ngOnInit() {
    this.simulationNgrxService.simulationContent.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      if (data) {
        this.simulation = data.toJS() as MortgageSimulation | CashSimulation;
      }
    });

    const filters = _.pickBy(this.route.snapshot.queryParams, (value, key) => _.startsWith(key, 'filters-'));
    _.forIn(filters, (value, key) => {
      key = _.last(key.split('-'));
      switch (_.find(OFFER_FILTERS_LIST, { key }).type) {
        case 'boolean': {
          value = value === 'true';
          break;
        }
        case 'array': {
          if (typeof value === 'string') {
            value = [value];
          }
        }
      }
      this.offerFiltersService.add({ key, value });
    });

    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(params => {
      this.clientId = params['clientId'];
    });

    const offerFilters$ = this.offerFiltersService.filters;
    offerFilters$.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.activeFilterValue = data.get('active');
      this.orderByFilterValue = data.get('orderBy');
      this.productVendorIdIn = data.get('productVendorIdIn');
    });

    const combined$ = this.simulationNgrxService.simulationContent.combineLatest(
      offerFilters$,
      this.refreshOffers$,
      (simulation, offerFilters) => {
        return {
          simulation,
          offerFilters
        };
      }
    );
    combined$
      .filter(arg => {
        const selectedOfferListsEqualSize = arg.simulation.get('selectedOfferIds').size === this.latestOfferIdsSize;
        const latestOfferListSizeIsNull = this.latestOfferIdsSize == null;
        this.latestOfferIdsSize = arg.simulation.get('selectedOfferIds').size;
        return selectedOfferListsEqualSize || latestOfferListSizeIsNull;
      })
      .do(() => (this.loading = true))
      .debounce(() => timer(200))
      .switchMap((params: { simulation: any; fields: string[]; offerFilters: Immutable.Record<OffersFilters> }) => {
        const offerFilters = params.offerFilters;
        const offerParams = offerFilters.get('params').toJS();
        const activeOfferFilter = offerFilters.get('active');
        const page = offerFilters.get('page').toJS();
        const productVendorIdInOfferFilter = offerFilters.get('productVendorIdIn');
        const productVariantIncludedFilter = offerFilters.get('productVariantIncluded');
        const productTypeIdInOfferFilter = offerFilters.get('productTypeIdIn');
        const creditworthinessFilter = offerFilters.get('creditworthiness');
        const orderBy = offerFilters.get('orderBy');
        this.getOffersMethod({
          simulationId: params.simulation.get('id'),
          page,
          orderBy,
          activeOfferFilter,
          creditworthinessFilter,
          fields: ['number'],
          productVendorIdInOfferFilter,
          productTypeIdInOfferFilter,
          productVariantIncludedFilter,
          ...offerParams
        });
        this.expandableRowNgrxService.reset();
        return empty();
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();

    this.scrollBarOptions = {
      className: 'os-theme-minimal-dark',
      callbacks: {
        onScroll: $event => this.onScroll($event)
      }
    };
  }

  onScroll(event) {
    const scrollLeft = event.target.scrollLeft;
    this.scrollTwo.nativeElement.scrollLeft = scrollLeft;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  updateSelectedOfferIds(offer: MortgageOffer): void {
    if (offer.selected) {
      this.simulation.selectedOfferIds.push(offer.id);
    } else {
      _.remove(this.simulation.selectedOfferIds, n => n === offer.id);
    }
    this.simulationNgrxService.update(this.simulation);
  }

  toggleVisibilityOfProductAndVendorNames() {
    if (this.orderByComponent.orderBy.value === 'product_vendor_name') {
      this.orderByComponent.getFirst();
    }
  }

  reportButtonVisibility(offer: any, visibility: boolean): void {
    offer.hover = visibility;
  }
}
