import { Component, Input, OnInit } from '@angular/core';
import { Dictionary } from '../../../../../../../models/dictionary.model';
import { VisibilityTreeService } from '../../../../../../../services/visibility-tree/visibility-tree.service';
import * as _ from 'lodash';
import { AdditionalCostsPipe } from '../../../../../../../pipes/additional-costs/additional-costs.pipe';
import { FindByPipe } from '../../../../../../../pipes/find-by/find-by.pipe';
import { FrequencyType } from '../../../../../../../components/common/frequency-type/frequency-type';
import { CrossSellService } from '../../../../../../../services/simulation/cross-sell/cross-sell.service';
import * as Immutable from 'immutable';
import { ExpandedCrossSells } from '../../../../../../../reducers/expanded-cross-sells/expanded-cross-sells.reducer';
import { MONTHLY_COSTS } from '../../../../constants/monthly-costs';

@Component({
  selector: 'cost-sum-monthly',
  templateUrl: './cost-sum-monthly.component.html',
  styleUrls: ['./cost-sum-monthly.component.scss']
})
export class CostSumMonthlyComponent implements OnInit {
  monthlyCosts: Dictionary[] = MONTHLY_COSTS;
  identity = 'offer.totalMonthlyCostsMortgage';

  @Input()
  offer: any;

  @Input()
  expandedCrossSells: Immutable.Record<ExpandedCrossSells>;

  constructor(
    public visibilityTreeService: VisibilityTreeService,
    private additionalCostsPipe: AdditionalCostsPipe,
    private crossSellService: CrossSellService,
    private findByPipe: FindByPipe
  ) {}

  ngOnInit() {}

  isDetailsVisible(identity) {
    return this.visibilityTreeService.isDetailsVisible(identity);
  }

  getCostsSum() {
    const costs = this.additionalCostsPipe.transform(this.offer.additionalCosts.content, FrequencyType.Monthly);
    const fn = (sum, item) => {
      const { cost } = this.getCostByKeyAndValue(costs, item.key) || 0;
      const { avgCost } = this.getAvgCostByKeyAndValue(this.offer.offerCrossSells, item.key) || 0;
      const totalCost = cost || avgCost ? parseFloat(avgCost || cost) : 0;
      return sum + totalCost;
    };
    return _.reduce(this.monthlyCosts, fn, 0).toFixed(2);
  }

  getCostByKeyAndValue(costs, value, key = 'name') {
    return this.findByPipe.transform(costs, key, value);
  }

  getAvgCostByKeyAndValue(crossSells, value, key = 'crossSell.name') {
    return this.findByPipe.transform(crossSells, key, value);
  }

  debtBalanceCostInRow(name: string): boolean {
    return this.crossSellService.debtBalanceCostInRow(name, this.expandedCrossSells);
  }
}
