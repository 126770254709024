import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as LoanPurposesActions from '../../reducers/loan-purposes/loan-purposes.actions';
import { MortgageSimulationService } from '../../services/simulation/mortgage/mortgage-simulation.service';
import { AppStore } from '../../models/app-store.model';

@Injectable()
export class LoanPurposesEffects {
  // Listen for the 'LOAD_LOAN_PURPOSES' action
  @Effect()
  loadLoanPurposes$: Observable<Action> = this.actions$.pipe(
    ofType(LoanPurposesActions.LOAD_LOAN_PURPOSES),
    withLatestFrom(this.store$),
    filter(([action, state]: [Action, AppStore]) => !state.loanPurposes.loaded),
    mergeMap(() => {
      return this._mortgageSimulationService.findLoanPurposes().pipe(
        map(data => ({
          type: LoanPurposesActions.LOAD_LOAN_PURPOSES_SUCCESS,
          payload: data.content
        })),
        catchError(() =>
          of({
            type: LoanPurposesActions.LOAD_LOAN_PURPOSES_FAILURE
          })
        )
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _mortgageSimulationService: MortgageSimulationService,
    private store$: Store<AppStore>
  ) {}
}
