import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  KnowledgeBaseNgrxService,
  SearchItemAttributes,
  SectionsAttributeDefinitions
} from '../../../services/ngrx/knowledge-base-ngrx.service';
import { pluck, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'knowledge-view',
  templateUrl: './knowledge-view.component.html',
  styleUrls: ['./knowledge-view.component.scss']
})
export class KnowledgeViewComponent implements OnInit, OnDestroy {
  productTypeCode: string;
  searchItemAttributes$: Subject<SearchItemAttributes> = new Subject();
  sectionsAttributeDefinitions: SectionsAttributeDefinitions;
  searchItemPhrase$: Subject<string> = new Subject();
  public _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private knowledgeBaseNgrxService: KnowledgeBaseNgrxService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  searchItemAttributesOnChange(event: SearchItemAttributes) {
    this.searchItemAttributes$.next(event);
  }

  searchItemPhraseOnChange(event: string) {
    this.searchItemPhrase$.next(event);
  }

  getAttributeDefinitions() {
    this.knowledgeBaseNgrxService.reset();
    this.knowledgeBaseNgrxService.attributeDefinitions
      .pipe(
        pluck('entities'),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(data => {
        this.sectionsAttributeDefinitions = this.knowledgeBaseNgrxService.groupAttributeDefinitionsIntoSections(data);
      });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.productTypeCode = params['productTypeCode'];
    });
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.getAttributeDefinitions();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
