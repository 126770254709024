import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GeneralInformationValidator } from './general-information-form.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [GeneralInformationValidator],
  exports: [GeneralInformationValidator],
  providers: []
})
export class GeneralInformationFormModule {}
