import * as Actions from './sections.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Section as SectionBase } from 'exoffice-js-client';

type Action = Actions.All;

export interface Section extends SectionBase {
  isVisible: boolean;
  isHidden: boolean;
}

export const sectionsEntityAdapter = createEntityAdapter<Section>({
  sortComparer: (a: Section, b: Section) => a.orderNo - b.orderNo
});

export interface SectionsEntities extends EntityState<Section> {
  loading: boolean;
  loaded: boolean;
}

export const initialState = sectionsEntityAdapter.getInitialState({
  loading: false,
  loaded: false
});

export default function SectionsReducer(state: SectionsEntities = initialState, action?: Action): any {
  switch (action.type) {
    case Actions.LOAD_SECTIONS: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case Actions.LOAD_SECTIONS_SUCCESS: {
      return {
        ...sectionsEntityAdapter.map(
          item => ({ ...item, isVisible: true, isHidden: true }),
          sectionsEntityAdapter.addAll(action.payload, state)
        ),
        loading: false,
        loaded: true
      };
    }
    case Actions.LOAD_SECTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case Actions.SECTION_REMOVE: {
      return {
        ...state
      };
    }
    case Actions.REMOVE_SECTION_SUCCESS: {
      return sectionsEntityAdapter.removeOne(action.payload.id, state);
    }
    case Actions.REMOVE_SECTION_FAILURE: {
      return {
        ...state
      };
    }
    case Actions.MAP: {
      return { ...sectionsEntityAdapter.map(action.entityMap, state) };
    }

    case Actions.SECTION_NO_API_UPDATE: {
      return sectionsEntityAdapter.updateOne({ id: action.id, changes: action.changes }, state);
    }

    case Actions.SECTION_UPDATE: {
      return { ...state };
    }
    case Actions.UPDATE_SECTION_SUCCESS: {
      return sectionsEntityAdapter.updateOne({ id: action.id, changes: action.changes }, state);
    }
    case Actions.UPDATE_SECTION_FAILURE: {
      return { ...state };
    }
    default:
      return state;
  }
}
