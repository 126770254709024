import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { VisibilityTreeService } from '../../../../../services/visibility-tree/visibility-tree.service';

@Component({
  selector: 'cross-sell-heading-cell',
  templateUrl: './cross-sell-heading-cell.component.html',
  styleUrls: ['./cross-sell-heading-cell.component.scss']
})
export class CrossSellHeadingCellComponent {
  @Input()
  id: string;

  @Input()
  label: string;

  @Input()
  bankOrInstance: boolean;

  constructor(private visibilityTreeService: VisibilityTreeService) {
    this.visibilityTreeService = visibilityTreeService;
  }

  hasExtendedHeight() {
    return !_.includes(['crossSell.bridgingInsurance', 'crossSell.lowContributionInsurance'], this.id);
  }

  isVisible() {
    return this.visibilityTreeService.isVisible(this.id);
  }

  isDetailsVisible() {
    return this.visibilityTreeService.isDetailsVisible(this.id);
  }

  detailsSwitch() {
    this.visibilityTreeService.toggleDetails(this.id);
  }
}
