import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdditionalCostsPipe } from './additional-costs.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [AdditionalCostsPipe],
  exports: [AdditionalCostsPipe]
})
export class AdditionalCostsPipeModule {}
