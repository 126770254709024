import { OrderBy } from '../../../../../../models/order-by';

export const ORDER_LIST_CASH: OrderBy[] = [
  {
    label: 'Nazwa banku malejąco',
    direction: 'DESC',
    value: 'product_vendor_name',
    isDefault: false
  },
  {
    label: 'Nazwa banku rosnąco',
    direction: 'ASC',
    value: 'product_vendor_name',
    isDefault: false
  },
  {
    label: 'Oprocentowanie nominalne malejąco',
    direction: 'DESC',
    value: 'nominal_interest_rate',
    isDefault: false
  },
  {
    label: 'Oprocentowanie nominalne rosnąco',
    direction: 'ASC',
    value: 'nominal_interest_rate',
    isDefault: false
  },
  {
    label: 'Rata malejąco',
    direction: 'DESC',
    value: 'first_installment',
    isDefault: false
  },
  {
    label: 'Rata rosnąco',
    direction: 'ASC',
    value: 'common_installment',
    isDefault: true
  },
  {
    label: 'Całkowita kwota do spłaty malejąco',
    direction: 'DESC',
    value: 'total_sum',
    isDefault: false
  },
  {
    label: 'Całkowita kwota do spłaty rosnąco',
    direction: 'ASC',
    value: 'total_sum',
    isDefault: false
  }
];
