import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { OfferCrossSell, VendorCrossSell } from 'exoffice-js-client';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'cross-sell-edit-modal',
  templateUrl: './cross-sell-edit-modal.component.html',
  styleUrls: ['./cross-sell-edit-modal.component.scss']
})
export class CrossSellEditModalComponent {
  @Input()
  offerCrossSell: OfferCrossSell;
  @Input()
  vendorCrossSell: VendorCrossSell;
  @Input()
  currency: string;

  @Output()
  updateOfferEmitter: EventEmitter<OfferCrossSell> = new EventEmitter();

  @Output() offerCrossSellChange: EventEmitter<OfferCrossSell> = new EventEmitter();
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  update() {
    this.updateOfferEmitter.next();
    this.offerCrossSellChange.next(this.offerCrossSell);
  }
}
