import { Action } from '@ngrx/store';
import { ProductType } from 'exoffice-js-client';
export const ADD_SIMULATION_PRODUCT_TYPES = '[ProductTypes] Add product types from simulation';

export const GET_PRODUCT_TYPES = '[Get products types] Get products types';
export const LOAD_PRODUCT_TYPES = '[Product type API] Load products types';

export const LOAD_PRODUCT_TYPES_SUCCESS = '[Product type API] Load products types success';
export const LOAD_PRODUCT_TYPES_FAILURE = '[Product type API] Load products types failure';
export interface GetProductTypesPayloadType {
  sorts?: string;
  withVendorProductTypes?: string;
}
export class AddSimulationProductTypes implements Action {
  readonly type = ADD_SIMULATION_PRODUCT_TYPES;
  constructor(public payload: { productTypes: ProductType[] }) {}
}

export class LoadProductTypesSuccess implements Action {
  readonly type = LOAD_PRODUCT_TYPES_SUCCESS;
  constructor(public payload: ProductType[]) {}
}

export class LoadProductTypesFailure implements Action {
  readonly type = LOAD_PRODUCT_TYPES_FAILURE;
  constructor() {}
}

export class LoadProductTypes implements Action {
  readonly type = LOAD_PRODUCT_TYPES;
  constructor(public payload: GetProductTypesPayloadType) {}
}

export class GetProductTypes implements Action {
  readonly type = GET_PRODUCT_TYPES;
  constructor(public payload: GetProductTypesPayloadType) {}
}

export type All = AddSimulationProductTypes | GetProductTypes | LoadProductTypes | LoadProductTypesSuccess;
