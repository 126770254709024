export const FILTER_ACTIVE_LIST: FilterIsActiveItem[] = [
  {
    label: 'Pokaż tylko zaznaczone',
    selected: true,
    isDefault: false
  },
  {
    label: 'Pokaż wszystkie',
    isDefault: true
  }
];

export class FilterIsActiveItem {
  label: string;
  selected?: boolean;
  isDefault: boolean;
}
