import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(private oAuthService: OAuthService, router: Router, translate: TranslateService) {
    this.oAuthService.configure({
      loginUrl: environment.oAuth.loginUrl,

      // URL of the SPA to redirect the user to after login
      redirectUri: window.location.origin + '/index.html',
      // The SPA's id. The SPA is registerd with this id at the auth-server
      clientId: environment.oAuth.clientId,

      // update the scope for the permissions the client should request
      // The first three are defined by OIDC. The 4th is a usecase-specific one
      scope: environment.oAuth.scope,

      oidc: false,

      clearHashAfterLogin: false
    });
    // Use setStorage to use sessionStorage or another implementation of the TS-type Storage
    // instead of localStorage
    this.oAuthService.setStorage(sessionStorage);

    sessionStorage.setItem('nonce', 'nonce');

    this.oAuthService.tryLogin();

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('pl');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('pl');
  }
}
