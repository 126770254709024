import { Pipe, PipeTransform } from '@angular/core';
import { AdditionalCost } from 'exoffice-js-client';
import { FrequencyType } from '../../components/common/frequency-type/frequency-type';

@Pipe({ name: 'additionalCosts' })
export class AdditionalCostsPipe implements PipeTransform {
  transform(allAdditionalCosts: AdditionalCost[], frequency: FrequencyType) {
    return allAdditionalCosts.filter(
      additionalCost =>
        additionalCost.contributionsFeeFrequency === frequency &&
        additionalCost.cost !== null &&
        additionalCost.totalCost !== null
    );
  }
}
