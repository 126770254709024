import { Component, OnDestroy, OnInit, AfterViewInit, Input, TemplateRef } from '@angular/core';
import { OfferFiltersNgrxService } from '../../../services/ngrx/offer-filters-ngrx.service';
import { Subject } from 'rxjs';
import { VisibilityTreeService } from '../../../services/visibility-tree/visibility-tree.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { OffersNgrxService } from '../../../services/ngrx/offers-ngrx.service';

@Component({
  selector: 'modal-cross-sells-management',
  templateUrl: './modal-cross-sells-management.component.html',
  styleUrls: ['./modal-cross-sells-management.component.scss']
})
export class ModalCrossSellsManagementComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() simulationId: string;
  crossSells = [
    {
      name: 'Ubezpieczenie pomostowe',
      key: 'bridging_insurance',
      value: null
    },
    {
      name: 'Ubezpieczenie na życie',
      key: 'life_insurance',
      value: null
    },
    {
      name: 'Ubezpieczenie nieruchomości',
      key: 'real_estate_insurance',
      value: null
    },
    {
      name: 'Karta kredytowa',
      key: 'credit_card',
      value: null
    },
    {
      name: 'Konto bankowe',
      key: 'bank_account',
      value: null
    },
    {
      name: 'Wariant eko',
      key: 'product_variant',
      value: null
    }
  ];
  model: any;

  applyFilters$: Subject<Event> = new Subject();
  private _unsubscribeAll: Subject<any> = new Subject();

  modalRef: BsModalRef;

  constructor(
    private offerFiltersNgrxService: OfferFiltersNgrxService,
    private visibilityTreeService: VisibilityTreeService,
    private modalService: BsModalService,
    private offersNgrxService: OffersNgrxService
  ) {}

  ngOnInit() {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  mapCrossSells(value) {
    this.crossSells = this.crossSells.map(item => {
      item.value = value;
      return item;
    });
  }

  updateOffers() {
    const offerCrossSells = this.crossSells
      .filter(item => item.value !== null)
      .map(item => {
        const newItem: any = {};
        newItem.crossSellKey = item.key;
        newItem.included = item.value;
        return newItem;
      });
    console.log(offerCrossSells);
    this.offersNgrxService.updateMortgageOffersCrossSells(this.simulationId, null, offerCrossSells);
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
