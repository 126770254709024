import { Action } from '@ngrx/store';
import { Account } from 'exoffice-js-client';

export const LOAD_CURRENT_ACCOUNT = '[Current account - API] Load current account';
export const LOAD_CURRENT_ACCOUNT_SUCCESS = '[Current account - API] Load current account success';
export const LOAD_CURRENT_ACCOUNT_FAILURE = '[Current account - API] Load current account failure';

export class LoadCurrentAccount implements Action {
  readonly type = LOAD_CURRENT_ACCOUNT;
  constructor() {}
}

export class LoadCurrentAccountSuccess implements Action {
  readonly type = LOAD_CURRENT_ACCOUNT_SUCCESS;
  constructor(public payload: Account) {}
}

export class LoadCurrentAccountFailure implements Action {
  readonly type = LOAD_CURRENT_ACCOUNT_FAILURE;
  constructor() {}
}

export type All = LoadCurrentAccount | LoadCurrentAccountSuccess | LoadCurrentAccountFailure;
