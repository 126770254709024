import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DescriptionModalContentComponent } from './description-modal-content/description-modal-content.component';
import { ExpandableRows } from '../../../../../../../reducers/expandable-rows/expandable-rows';

@Component({
  selector: 'description-item',
  templateUrl: './description-item.component.html',
  styleUrls: ['./description-item.component.scss']
})
export class DescriptionItemComponent {
  @Input()
  description: string;
  @Input()
  modalTitle: string;
  @Input()
  expandableRowId: keyof ExpandableRows;

  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {}

  openModal() {
    this.modalRef = this.modalService.show(DescriptionModalContentComponent, {
      initialState: {
        title: this.modalTitle,
        description: this.description
      }
    });
  }
}
