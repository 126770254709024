import * as ItemAttributesActions from '../../reducers/knowledge-base/item-attributes/item-attributes.actions';
import * as SectionsActions from '../../reducers/knowledge-base/sections/sections.actions';
import * as VendorProductsTypesActions from '../../reducers/knowledge-base/vendor-product-types/vendor-product-types.actions';
import * as AttributeDefinitionsActions from '../../reducers/knowledge-base/attribute-definitions/attribute-definitions.actions';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Store } from '@ngrx/store';
import { AppStore } from '../../models/app-store.model';
import { ItemAttributesEntities } from '../../reducers/knowledge-base/item-attributes/item-attributes.reducer';
import { ItemAttribute, VendorProductType } from 'exoffice-js-client';
import { Dictionary, EntityMap } from '@ngrx/entity';
import { Section, SectionsEntities } from '../../reducers/knowledge-base/sections/sections.reducer';
import {
  VendorProductsTypesEntities,
  VendorProductTypeExtended
} from '../../reducers/knowledge-base/vendor-product-types/vendor-product-types.reducer';
import * as _ from 'lodash';
import getUuid from 'uuid-by-string';
import {
  AttributeDefinition,
  AttributeDefinitionsEntities
} from '../../reducers/knowledge-base/attribute-definitions/attribute-definitions.reducer';

export interface SearchItemAttributes {
  itemIds: string[];
  attributeDefinitionAndItemIds: string[];
  attributeDefinitionIds: string[];
  attributeDefinitionLabelIds: string[];
}

interface SectionsAttributeDefinition {
  attributeDefinitionsIds: string[];
}

export interface SectionsAttributeDefinitions {
  [id: string]: SectionsAttributeDefinition;
}

@Injectable({
  providedIn: 'root'
})
export class KnowledgeBaseNgrxService {
  itemAttributes: Observable<ItemAttributesEntities>;
  itemAttributesEntities: Observable<Dictionary<ItemAttribute>>;
  sections: Observable<SectionsEntities>;
  sectionsEntities: Observable<Dictionary<Section>>;
  vendorProductsTypes: Observable<VendorProductsTypesEntities>;
  vendorProductsTypesEntities: Observable<Dictionary<VendorProductTypeExtended>>;
  attributeDefinitions: Observable<AttributeDefinitionsEntities>;

  constructor(public store: Store<AppStore>) {
    this.itemAttributes = this.store.select('itemAttributes');
    this.itemAttributesEntities = this.store.select((state: AppStore) => state.itemAttributes.entities);
    this.sections = this.store.select('sections');
    this.attributeDefinitions = this.store.select('attributeDefinitions');

    this.sectionsEntities = this.store.select((state: AppStore) => state.sections.entities);
    this.vendorProductsTypes = this.store.select('vendorProductsTypes');

    this.vendorProductsTypesEntities = this.store.select((state: AppStore) => state.vendorProductsTypes.entities);
  }

  getItemAttributes(): void {
    this.store.dispatch({ type: ItemAttributesActions.GET_ITEM_ATTRIBUTES });
  }

  groupAttributeDefinitionsIntoSections(attributeDefinitions: Dictionary<AttributeDefinition>) {
    const groupedBy = _.groupBy(attributeDefinitions, item => {
      return item.sectionId;
    });
    const mapped = _.mapValues(groupedBy, item => {
      const attributeDefinitionsIds = _.map(item, el => el.id);
      return { attributeDefinitionsIds };
    });
    return mapped;
  }

  getAttributeDefinitions(productCategoryCode: string): void {
    this.store.dispatch({ type: AttributeDefinitionsActions.GET_ATTRIBUTE_DEFINITIONS, productCategoryCode });
  }

  getItemAttributesById(id: string | number): Observable<ItemAttribute> {
    return this.store.select((state: AppStore) => state.itemAttributes.entities[id]);
  }

  toggleSections(expand: boolean) {
    const entityMap: EntityMap<Section> = entity => {
      entity.isHidden = expand;
      return entity;
    };
    this.store.dispatch({ type: SectionsActions.MAP, entityMap });
  }

  searchItemAttributes(searchBy = ''): Observable<SearchItemAttributes> {
    return this.store.select((state: AppStore) => {
      const filtered = _.filter(state.itemAttributes.entities, (o: ItemAttribute) =>
        _.includes(_.toLower(o.value), _.toLower(searchBy))
      );
      const attributeDefinitionLabelIds = _.map(
        _.filter(
          state.attributeDefinitions.entities,
          (o: AttributeDefinition) => !_.isEmpty(searchBy) && _.includes(_.toLower(o.label), _.toLower(searchBy))
        ),
        i => i.id
      );
      const attributeDefinitionAndItemIds = [];
      const attributeDefinitionIds = [...attributeDefinitionLabelIds];
      const itemIds = [];
      if (!_.isEmpty(searchBy)) {
        filtered.forEach(item => {
          if (
            !state.vendorProductsTypes.entities[item.itemId].isHidden &&
            state.attributeDefinitions.entities[item.attributeDefinitionId].isVisible !== false
          ) {
            attributeDefinitionIds.push(item.attributeDefinitionId);
            attributeDefinitionAndItemIds.push(getUuid(item.attributeDefinitionId + item.itemId));
            itemIds.push(item.itemId);
          }
        });
      }
      return {
        itemIds: _.uniq(itemIds),
        attributeDefinitionAndItemIds,
        attributeDefinitionIds: _.uniq(attributeDefinitionIds),
        attributeDefinitionLabelIds
      };
    });
  }

  getSections(productCategoryCode: string): void {
    this.store.dispatch({ type: SectionsActions.LOAD_SECTIONS, productCategoryCode });
  }

  removeSection(id: string): void {
    this.store.dispatch({ type: SectionsActions.SECTION_REMOVE, id });
  }

  sectionUpdate(section: Section): void {
    this.store.dispatch({ type: SectionsActions.SECTION_UPDATE, id: section.id, changes: section });
  }

  itemAttributeUpdate(itemAttribute: ItemAttribute): void {
    this.store.dispatch({ type: ItemAttributesActions.UPDATE_ITEM_ATTRIBUTE, id: itemAttribute.id, itemAttribute });
  }

  hideVendorProductsTypes(payload: string[] | number[]): void {
    const entityMap: EntityMap<VendorProductTypeExtended> = (item: VendorProductTypeExtended) => {
      item.isHidden = !_.includes(payload, item.id);
      return item;
    };
    this.store.dispatch({
      type: VendorProductsTypesActions.HIDE_VENDOR_PRODUCT_TYPES,
      entityMap
    });
  }

  reset() {
    this.store.dispatch({ type: SectionsActions.LOAD_SECTIONS_FAILURE });
    this.store.dispatch({ type: AttributeDefinitionsActions.LOAD_ATTRIBUTE_DEFINITIONS_FAILURE });
    this.store.dispatch({ type: ItemAttributesActions.LOAD_ITEM_ATTRIBUTES_FAILURE });
    this.store.dispatch({ type: VendorProductsTypesActions.LOAD_VENDOR_PRODUCT_TYPES_FAILURE });
  }

  getVendorProductsTypesAndItemAttributes(
    payload: VendorProductsTypesActions.GetVendorsProductsTypesPayloadType
  ): void {
    this.store.dispatch({ type: VendorProductsTypesActions.LOAD_VENDOR_PRODUCT_TYPES });
    this.store.dispatch({ type: ItemAttributesActions.LOAD_ITEM_ATTRIBUTES });
    this.store.dispatch({
      type: VendorProductsTypesActions.GET_VENDOR_PRODUCTS_TYPES_AND_ITEM_ATTRIBUTES,
      payload
    });
  }

  setAttrbuteDefinitionVisibility(id: number | string, isVisible: boolean) {
    this.store.dispatch({ type: AttributeDefinitionsActions.UPDATE_ONE, id, changes: { isVisible } });
  }

  setSection(id: number | string, changes: Partial<Section>) {
    this.store.dispatch({ type: SectionsActions.SECTION_UPDATE, id, changes });
  }

  setSectionNoApi(id: number | string, changes: Partial<Section>) {
    this.store.dispatch({ type: SectionsActions.SECTION_NO_API_UPDATE, id, changes });
  }

  setSectionVisibility(id: number | string, isVisible: boolean) {
    this.store.dispatch({ type: SectionsActions.SECTION_NO_API_UPDATE, id, changes: { isVisible } });
  }

  setSectionAndAttributesVisibility(id: number | string, isVisible: boolean) {
    const entityMap: EntityMap<AttributeDefinition> = (item: AttributeDefinition) => {
      if (item.sectionId === id) {
        item.isVisible = isVisible;
      }
      return item;
    };
    this.store.dispatch({ type: SectionsActions.SECTION_NO_API_UPDATE, id, changes: { isVisible } });
    this.store.dispatch({ type: AttributeDefinitionsActions.MAP, entityMap });
  }

  setAllSectionVisibility(visibility: boolean) {
    const entityMap: EntityMap<AttributeDefinition> = (item: AttributeDefinition) => {
      item.isVisible = visibility;
      return item;
    };
    this.store.dispatch({ type: SectionsActions.MAP, entityMap });
    this.store.dispatch({ type: AttributeDefinitionsActions.MAP, entityMap });
  }
}
