import { Action } from '@ngrx/store';
import { Section } from './sections.reducer';
import { EntityMap } from '@ngrx/entity';

export const LOAD_SECTIONS = '[Sections API] Load sections';
export const LOAD_SECTIONS_SUCCESS = '[Sections API] Load sections success';
export const LOAD_SECTIONS_FAILURE = '[Sections API] Load sections failure';
export const GET_SECTIONS = '[Section API] Get sections';
export const SECTION_UPDATE = '[Section API] Update section';
export const SECTION_NO_API_UPDATE = '[Section NO API] Update section';
export const UPDATE_SECTION_SUCCESS = '[Sections API] Update section success';
export const UPDATE_SECTION_FAILURE = '[Sections API] Update section failure';
export const SECTION_REMOVE = '[Section API] Remove section';
export const REMOVE_SECTION_SUCCESS = '[Sections API] Remove section success';
export const REMOVE_SECTION_FAILURE = '[Sections API] Remove section failure';
export const SECTION_ADD = '[Section API] Remote section';
export const MAP = '[Section] map update';

export class Sections implements Action {
  readonly type = LOAD_SECTIONS;

  constructor(public productCategoryCode: string) {}
}

export class SectionsSuccess implements Action {
  readonly type = LOAD_SECTIONS_SUCCESS;

  constructor(public payload: Section[]) {}
}

export class SectionNoApiUpdate implements Action {
  readonly type = SECTION_NO_API_UPDATE;
  constructor(public id: string, public changes: Partial<Section>) {}
}

export class SectionUpdate implements Action {
  readonly type = SECTION_UPDATE;
  constructor(public id: string, public changes: Partial<Section>) {}
}

export class SectionUpdateFailure implements Action {
  readonly type = UPDATE_SECTION_FAILURE;

  constructor() {}
}

export class SectionUpdateSuccess implements Action {
  readonly type = UPDATE_SECTION_SUCCESS;

  constructor(public id: string, public changes: Partial<Section>) {}
}

export class SectionRemove implements Action {
  readonly type = SECTION_REMOVE;
  constructor(public id: string) {}
}

export class SectionRemoveFailure implements Action {
  readonly type = REMOVE_SECTION_FAILURE;

  constructor() {}
}

export class SectionRemoveSuccess implements Action {
  readonly type = REMOVE_SECTION_SUCCESS;

  constructor(public payload: { id: string }) {}
}

export class SectionAdd implements Action {
  readonly type = SECTION_ADD;
  constructor(public id: string, public changes: Partial<Section>) {}
}

export class MapSections implements Action {
  readonly type = MAP;
  constructor(public entityMap: EntityMap<Section>) {}
}

export class SectionsFailure implements Action {
  readonly type = LOAD_SECTIONS_FAILURE;

  constructor() {}
}

export class GetSections implements Action {
  readonly type = GET_SECTIONS;

  constructor(public productCategoryCode: string) {}
}

export type All =
  | Sections
  | SectionsSuccess
  | SectionNoApiUpdate
  | SectionUpdate
  | SectionUpdateSuccess
  | SectionUpdateFailure
  | SectionsFailure
  | MapSections
  | SectionAdd
  | SectionRemove
  | SectionRemoveFailure
  | SectionRemoveSuccess;
