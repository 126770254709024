import { Component, Input, OnInit } from '@angular/core';
import { OfferFiltersNgrxService } from '../../../../../services/ngrx/offer-filters-ngrx.service';
import { FILTER_ACTIVE_LIST, FilterIsActiveItem } from './filter-by.constant';
import { CashSimulation, MortgageSimulation } from 'exoffice-js-client';

@Component({
  selector: 'filter-by',
  templateUrl: './filter-by.component.html',
  styleUrls: ['./filter-by.component.scss']
})
export class FilterByComponent implements OnInit {
  filterList: FilterIsActiveItem[] = FILTER_ACTIVE_LIST;
  activeFilterValue: boolean;
  simulation: MortgageSimulation | CashSimulation;
  @Input()
  selectedOfferIds: string[];

  setFilter(key, value) {
    this.offerFiltersService.add({ key, value });
  }

  tooltipLabel(): string {
    return this.filterList.find(filter => {
      return filter.selected !== this.activeFilterValue;
    }).label;
  }

  constructor(private offerFiltersService: OfferFiltersNgrxService) {}
  ngOnInit() {
    this.offerFiltersService.filters.subscribe(data => {
      this.activeFilterValue = data.get('active');
    });
  }
}
