import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';
import { ExpandableColumnsNgrxService } from '../../services/ngrx/expandable-columns-ngrx.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[expandableColumn]'
})
export class ExpandableColumnDirective implements AfterViewInit, OnDestroy {
  width: number;
  public _unsubscribeAll: Subject<any> = new Subject();
  private COLUMN_WIDTH_100 = 320;

  private mouseMove: Subscription;
  private $mouseMove: Observable<Event> = Observable.fromEvent(window, 'mousemove');

  @Input()
  id: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private expandableColumnsNgrxService: ExpandableColumnsNgrxService
  ) {}

  @HostListener('document:mouseup', [])
  onMouseUp() {
    if (this.mouseMove) {
      this.mouseMove.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    const resizeColumn = (event: MouseEvent) => {
      event.preventDefault();
      const { offsetWidth } = this.el.nativeElement;
      const newWidth = offsetWidth + event.movementX;
      if (newWidth >= this.COLUMN_WIDTH_100 * 0.75) {
        this.expandableColumnsNgrxService.setWidth(this.id, newWidth);
      } else {
        this.expandableColumnsNgrxService.setWidth(this.id, this.COLUMN_WIDTH_100 * 0.75);
      }
    };

    const resizeFrame = this.renderer.createElement('div');
    this.renderer.addClass(resizeFrame, 'resize-frame');

    this.renderer.listen(resizeFrame, 'mousedown', $event => {
      this.mouseMove = this.$mouseMove.pipe(takeUntil(this._unsubscribeAll)).subscribe(resizeColumn);
    });

    this.renderer.appendChild(this.el.nativeElement, resizeFrame);
    this.expandableColumnsNgrxService.setWidth(this.id, this.COLUMN_WIDTH_100);
    this.expandableColumnsNgrxService
      .getItemById(this.id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        if (data && data.width) {
          this.setNativeElementWidth(data.width);
        }
      });
  }

  setNativeElementWidth(width: number): void {
    this.renderer.setStyle(this.el.nativeElement, 'min-width', `${width}px`);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
