import { Action } from '@ngrx/store';
import { CashOffer, MortgageOffer } from 'exoffice-js-client';
import { EntityMap } from '@ngrx/entity';

export const ADD_ALL = '[Cash offers] Add all';
export const ADD_ONE = '[Cash offers] Add One';
export const GET_ONE = '[Cash offers] Get One';
export const UPDATE_CASH_OFFER = '[Cash offers API] Update cash offer';
export const UPDATE_ONE = '[Cash offers] Update One';
export const DELETE_ONE = '[Cash offers] Delete One';
export const REMOVE_ALL = '[Cash offers] Remove all';
export const GET_ALL = '[Cash offers] Get All';
export const UPSERT_OFFERS = '[Cash offers] Upsert offers';
export const LOAD_CASH_OFFERS = '[Cash offers - API] Load cash offers';
export const LOAD_CASH_OFFERS_SUCCESS = '[Cash offers - API] Load cash offers success';
export const LOAD_CASH_OFFERS_FAILED = '[Cash offers - API] Load cash offers failed';
export const MAP_OFFERS = '[Cash offers] Update offers using map fn';

export class LoadCashOffers implements Action {
  readonly type = LOAD_CASH_OFFERS;

  constructor(public payload: any) {}
}

export class LoadOffersSuccess implements Action {
  readonly type = LOAD_CASH_OFFERS_SUCCESS;

  constructor(public payload: { content: CashOffer[] }) {}
}

export class LoadOffersFailed implements Action {
  readonly type = LOAD_CASH_OFFERS_FAILED;

  constructor() {}
}

export class AddAll implements Action {
  readonly type = ADD_ALL;

  constructor(public payload: { offers: CashOffer[] }) {}
}

export class AddOne implements Action {
  readonly type = ADD_ONE;
  constructor(public offer: CashOffer) {}
}

export class GetOne implements Action {
  readonly type = GET_ONE;
  constructor(public id: string) {}
}

export class UpdateCashOffer implements Action {
  readonly type = UPDATE_CASH_OFFER;
  constructor(public id: string, public changes: Partial<MortgageOffer>) {}
}

export class UpdateOne implements Action {
  readonly type = UPDATE_ONE;
  constructor(public id: string, public changes: Partial<CashOffer>) {}
}
export class DeleteOne implements Action {
  readonly type = DELETE_ONE;
  constructor(public id: number) {}
}
export class RemoveAll implements Action {
  readonly type = REMOVE_ALL;
  constructor() {}
}
export class GetAll implements Action {
  readonly type = GET_ALL;
  constructor() {}
}

export class UpsertOffers implements Action {
  readonly type = UPSERT_OFFERS;

  constructor(public payload: { offers: CashOffer[] }) {}
}

export class MapOffers implements Action {
  readonly type = MAP_OFFERS;

  constructor(public map: EntityMap<CashOffer>) {}
}

export type All =
  | LoadCashOffers
  | LoadOffersSuccess
  | LoadOffersFailed
  | AddAll
  | AddOne
  | GetOne
  | UpdateCashOffer
  | UpdateOne
  | DeleteOne
  | RemoveAll
  | GetAll
  | UpsertOffers
  | MapOffers;
