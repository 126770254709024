const cashOfferMutation = `
mutation updateCashOffer($input: UpdateCashOfferInput!){
  updateCashOffer(input: $input){
    errors,
    cashOffer{
      annualPercentageRate,
      selected,
      available,
      currency,
      grossAmount,
      id,
      installment,
      originId,
      provisionCost,
      provisionPercentage,
      simulationId,
      totalSum,
      nominalInterestRate,
      links{
        rel
      },
      product{
        name
        ltvMax
        ltvMin
        description
        provisionDescription
        tranchesSettlementDescription
        earlyLoanPaymentDescription
        realEstateAppraisalDescription
        interestRateType
        productType {
          name
        }
        selfRealEstateAppraisal
        vendor {
          name
        }
      },
    }
  }
}`;
export { cashOfferMutation };
