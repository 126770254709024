import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CostlessCrossSellPipe } from './costless-cross-sell.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CostlessCrossSellPipe],
  exports: [CostlessCrossSellPipe]
})
export class CostlessCrossSellPipeModule {}
