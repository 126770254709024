import { Component, Input } from '@angular/core';
import { OfferCrossSell, VendorCrossSell } from 'exoffice-js-client';

@Component({
  selector: 'base-insurance-content',
  templateUrl: './base-insurance-content.component.html',
  styleUrls: ['./base-insurance-content.component.scss']
})
export class BaseInsuranceContentComponent {
  @Input()
  offerCrossSell: OfferCrossSell;
  @Input()
  vendorCrossSell: VendorCrossSell;
  @Input()
  showDescription = false;
  @Input()
  useInnerHtml = false;
  @Input()
  size = 'small';
  @Input()
  currency: string;
}
