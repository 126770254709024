import { HttpClient } from '@angular/common/http';
import { Inject, Optional } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import {
  BASE_PATH,
  Configuration,
  MortgageSimulation,
  MortgageSimulationInput,
  MortgageSimulationService as OriginalMortgageSimulationService
} from 'exoffice-js-client';
import { SimulationDocumentInput } from 'exoffice-js-client/model/simulationDocumentInput';
import { Observable } from 'rxjs/Rx';
import { ServiceFactory } from '../../service-factory';

export class MortgageSimulationService extends OriginalMortgageSimulationService {
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    super(httpClient, basePath, configuration);
  }

  public findSimulationById(id: string): Observable<MortgageSimulation> {
    return this.findMortgageSimulationById(id);
  }

  public addSimulation(mortgageSimulation: MortgageSimulationInput): Observable<MortgageSimulation> {
    return this.addMortgageSimulation(mortgageSimulation);
  }

  public updateSimulation(id: string, simulation: MortgageSimulationInput): Observable<MortgageSimulation> {
    return this.updateMortgageSimulation(id, simulation);
  }

  public addSimulationDocument(
    id: string,
    productVendorIdIn: string[],
    simulationDocumentInput: SimulationDocumentInput
  ) {
    return this.addMortgageSimulationDocument(id, productVendorIdIn, simulationDocumentInput);
  }
}

export function mortgageSimulationServiceFactory(oAuthService: OAuthService, http: HttpClient) {
  return new ServiceFactory(MortgageSimulationService, oAuthService, http).createService();
}

export let mortgageSimulationServiceProvider = [
  {
    provide: MortgageSimulationService,
    useFactory: mortgageSimulationServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
