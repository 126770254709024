import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { AppStore } from '../models/app-store.model';
import OfferFiltersReducer from './offers-filters/offers-filters.reducer';
import { CurrentAccountReducer } from './current-account/current-account.reducer';
import SimulationReducer from './simulation/simulation.reducer';
import LoanPurposesReducer from './loan-purposes/loan-purposes.reducer';
import CitiesReducer from './cities/cities.reducer';
import IncomeTypesReducer from './income-types/income-types.reducer';
import MortgageOffersReducer from './offers/mortgage-offers/mortgage-offers.reducer';
import CashOffersReducer from './offers/cash-offers/cash-offers.reducer';
import VendorsReducer from './vendors/vendors.reducer';
import ExpandableRowsReducer from './expandable-rows/expandable-rows.reducer';
import { ExpandedCrossSellsReducer } from './expanded-cross-sells/expanded-cross-sells.reducer';
import ItemAttributesReducer from './knowledge-base/item-attributes/item-attributes.reducer';
import SectionsReducer from './knowledge-base/sections/sections.reducer';
import VendorProductsTypesReducer from './knowledge-base/vendor-product-types/vendor-product-types.reducer';
import ExpandableKnowledgeRowsReducer from './knowledge-base/expandable-knowledge-rows/expandable-knowledge-rows.reducer';
import ExpandableColumnsReducer from './expandable-columns/expandable-columns.reducer';
import AttributeDefinitionsReducer from './knowledge-base/attribute-definitions/attribute-definitions.reducer';
import ProductTypesReducer from './product-types/product-types.reducer';

export const reducers: ActionReducerMap<AppStore> = {
  attributeDefinitions: AttributeDefinitionsReducer,
  offerFilters: OfferFiltersReducer,
  currentAccount: CurrentAccountReducer,
  cities: CitiesReducer,
  incomeTypes: IncomeTypesReducer,
  loanPurposes: LoanPurposesReducer,
  simulation: SimulationReducer,
  mortgageOffers: MortgageOffersReducer,
  cashOffers: CashOffersReducer,
  vendors: VendorsReducer,
  expandableRows: ExpandableRowsReducer,
  expandedCrossSells: ExpandedCrossSellsReducer,
  itemAttributes: ItemAttributesReducer,
  sections: SectionsReducer,
  vendorProductsTypes: VendorProductsTypesReducer,
  expandableKnowledgeRows: ExpandableKnowledgeRowsReducer,
  expandableColumns: ExpandableColumnsReducer,
  productTypes: ProductTypesReducer
};

export const reducerToken = new InjectionToken<ActionReducerMap<AppStore>>('Registered Reducers');

export function getReducers() {
  return reducers;
}
