import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalErrorsReportingComponent } from './modal-errors-reporting.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ModalErrorsReportingComponent],
  imports: [CommonModule, FormsModule],
  exports: [ModalErrorsReportingComponent]
})
export class ModalErrorsReportingModule {}
