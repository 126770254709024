import { AfterViewInit, Component, OnDestroy } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'blank',
  templateUrl: 'blank.template.html'
})
export class BlankComponent implements AfterViewInit, OnDestroy {
  ngAfterViewInit() {
    jQuery('body').addClass('gray-bg');
  }

  ngOnDestroy() {
    jQuery('body').removeClass('gray-bg');
  }
}
