import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CashBorrowerInput, CashHouseholdInput } from 'exoffice-js-client';
import { BorrowersTabsComponent } from '../borrowers-tabs.component';

@Component({
  selector: 'borrowers-tabs-cash',
  styleUrls: ['borrowers-tabs-cash.component.scss'],
  templateUrl: 'borrower-tabs-cash.component.html'
})
export class BorrowersTabsCashComponent extends BorrowersTabsComponent {
  @Input() borrowers: CashBorrowerInput[];
  @Input() household: CashHouseholdInput;

  constructor(protected cdRef: ChangeDetectorRef) {
    super(cdRef);
  }
}
