import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SpinWithBlockModule } from '../spin-with-block/spin-with-block.module';
import { WizardStepComponent } from './wizard-step.component';
import { WizardComponent } from './wizard.component';

export * from './wizard.component';
export * from './wizard-step.component';

@NgModule({
  imports: [CommonModule, SpinWithBlockModule],
  declarations: [WizardComponent, WizardStepComponent],
  exports: [WizardComponent, WizardStepComponent]
})
export class FormWizardModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: FormWizardModule
    };
  }
}
