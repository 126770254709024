import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { ItemAttribute, Section } from 'exoffice-js-client';
import { AttributeDefinition } from '../../../../reducers/knowledge-base/attribute-definitions/attribute-definitions.reducer';

import { Subject, combineLatest } from 'rxjs';
import {
  KnowledgeBaseNgrxService,
  SearchItemAttributes,
  SectionsAttributeDefinitions
} from '../../../../services/ngrx/knowledge-base-ngrx.service';
import getUuid from 'uuid-by-string';
import { takeUntil, pluck, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SectionsEntities } from '../../../../reducers/knowledge-base/sections/sections.reducer';
import { VendorProductTypeExtended } from '../../../../reducers/knowledge-base/vendor-product-types/vendor-product-types.reducer';
import * as _ from 'lodash';
import OverlayScrollbars from 'overlayscrollbars';
import { AttributeModalComponent } from './attribute-modal/attribute-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'knowledge-base',
  templateUrl: './knowledge-base.component.html',
  styleUrls: ['./knowledge-base.component.scss']
})
export class KnowledgeBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  productCategoryCode: string;
  searchItemAttributes: SearchItemAttributes;
  @Input()
  searchItemAttributes$: Subject<SearchItemAttributes> = new Subject();
  @Input()
  searchItemPhrase$: Subject<string> = new Subject();
  searchPhrase: string;
  scrollBarOptions: OverlayScrollbars.Options;
  sections: SectionsEntities;
  originalSectionsIds: SectionsEntities['ids'];
  vendorProductsTypes: any;
  itemAttributesEntities: Dictionary<ItemAttribute>;
  vendorProductsTypesIds: string[] | number[];
  vendorProductsTypesEntities: Dictionary<VendorProductTypeExtended>;
  @Input()
  sectionsAttributeDefinitions: SectionsAttributeDefinitions;
  loaded = false;
  canDownloadReport = false;
  attributeDefinitionsEntities: Dictionary<AttributeDefinition>;
  @ViewChild('scrollTwo', { static: false }) scrollTwo: ElementRef;
  public _unsubscribeAll: Subject<any> = new Subject();
  modalRef: BsModalRef;

  constructor(private knowledgeBaseNgrxService: KnowledgeBaseNgrxService, private modalService: BsModalService) {}

  ngAfterViewInit() {
    this.knowledgeBaseNgrxService.getSections(this.productCategoryCode);
    this.knowledgeBaseNgrxService.getVendorProductsTypesAndItemAttributes({
      sorts: 'vendor_name ASC',
      productCategoryId: null,
      productCategoryCode: this.productCategoryCode
    });
    this.knowledgeBaseNgrxService.getAttributeDefinitions(this.productCategoryCode);
    this.knowledgeBaseNgrxService.attributeDefinitions
      .pipe(pluck('entities'))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.attributeDefinitionsEntities = data;
      });

    this.searchItemAttributes$.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.searchItemAttributes = data;
      this.filterSectionIds(data);
    });

    this.searchItemPhrase$
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(data => {
        this.searchPhrase = data;
        this.toggleSectionsIfNeeded(this.sections.ids);
      });

    const combined$ = combineLatest(
      this.knowledgeBaseNgrxService.vendorProductsTypes,
      this.knowledgeBaseNgrxService.itemAttributes,
      this.knowledgeBaseNgrxService.sections,
      this.knowledgeBaseNgrxService.attributeDefinitions
    ).subscribe(([vendorProductsTypes, itemAttributes, sections, attributeDefinitions]) => {
      this.loaded =
        vendorProductsTypes.loaded && itemAttributes.loaded && sections.loaded && attributeDefinitions.loaded;
      if (this.loaded) {
        combined$.unsubscribe();
      }
    });
  }

  getAttributeDefinition(attributeId) {
    return this.attributeDefinitionsEntities[attributeId];
  }

  openModal(attributeDefinitionId, itemId, section) {
    const attributeDefinition = this.getAttributeDefinition(attributeDefinitionId);
    const title = section.name + ':' + attributeDefinition.label;
    const entity = _.cloneDeep(this.getItemAttribute(attributeDefinitionId, itemId));
    this.modalRef = this.modalService.show(AttributeModalComponent, { initialState: { attribute: entity, title } });
  }

  isNameIncluded(name, phrase): boolean {
    name = _.lowerCase(name);
    phrase = _.lowerCase(phrase);
    return name.includes(phrase);
  }

  filterSectionIds(searchItemAttributes: SearchItemAttributes) {
    const attributeDefinitionIds = searchItemAttributes.attributeDefinitionIds;
    if (_.isEmpty(searchItemAttributes.attributeDefinitionIds)) {
      this.sections.ids = this.originalSectionsIds;
    } else {
      const filteredSections = [];
      attributeDefinitionIds.forEach(itemId => {
        const attributeDefinition = this.getAttributeDefinition(itemId) as AttributeDefinition;
        filteredSections.push(attributeDefinition.sectionId);
      });
      this.sections.ids = _.intersection(this.sections.ids, _.uniq(filteredSections));
    }
    if (this.searchPhrase) {
      const matchingSections: Section = _.filter(this.sections.entities, section =>
        this.isNameIncluded(section.name, this.searchPhrase)
      );
      const matchingSectionsIds = _.map(matchingSections, section => section.id);
      if (!_.isEmpty(matchingSectionsIds)) {
        if (_.isEmpty(searchItemAttributes.attributeDefinitionIds)) {
          this.sections.ids = matchingSectionsIds;
        } else {
          this.sections.ids = _.concat(this.sections.ids, matchingSectionsIds);
          this.sections.ids = _.uniq(this.sections.ids);
        }
      }
    }
  }

  toggleSectionsIfNeeded(sectionIds: string[] | number[]) {
    let isToggleSectionsNeeded = false;
    sectionIds.forEach(id => {
      if (this.sections.entities[id].isHidden) {
        isToggleSectionsNeeded = true;
      }
    });
    if (isToggleSectionsNeeded) {
      this.knowledgeBaseNgrxService.toggleSections(false);
    }
  }

  trackByFn(index, item) {
    return item;
  }

  isInSearchItemAttributes(id: string, type: keyof SearchItemAttributes, itemId?: string) {
    if (type === 'attributeDefinitionAndItemIds') {
      id = getUuid(id + itemId);
    }
    return this.searchItemAttributes ? _.includes(this.searchItemAttributes[type], id) : false;
  }

  getSectionValue(id) {
    return this.sections.entities[id];
  }

  getItemAttribute(attributeDefinitionId, itemId) {
    const uuid = getUuid(attributeDefinitionId + itemId);
    return this.itemAttributesEntities[uuid];
  }

  getVendorValue(id) {
    return this.vendorProductsTypes.entities[id].vendor;
  }

  getValue(attributeDefinitionId, itemId) {
    const entity = this.getItemAttribute(attributeDefinitionId, itemId);
    return entity ? entity.value : '-';
  }

  toggle(sectionId) {
    this.sections.entities[sectionId].isHidden = !this.sections.entities[sectionId].isHidden;
    this.knowledgeBaseNgrxService.setSectionNoApi(sectionId, this.sections.entities[sectionId]);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    this.knowledgeBaseNgrxService.store.pipe(takeUntil(this._unsubscribeAll)).subscribe(store => {
      const notHidden = _.filter(store.vendorProductsTypes.entities, item => !item.isHidden);
      this.vendorProductsTypesIds = _.map(notHidden, item => item.id);
    });
    this.knowledgeBaseNgrxService.vendorProductsTypesEntities.subscribe(data => {
      this.vendorProductsTypesEntities = data;
    });
    this.knowledgeBaseNgrxService.itemAttributes.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.canDownloadReport = data.canDownloadReport;
    });
    this.knowledgeBaseNgrxService.itemAttributesEntities.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.itemAttributesEntities = data;
    });
    this.knowledgeBaseNgrxService.sections
      .pipe(
        filter(data => data.loaded),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(
        data => {
          this.sections = data;
          this.originalSectionsIds = _.isEmpty(this.originalSectionsIds) ? data.ids : this.originalSectionsIds;
        },
        (error: any) => console.log(error)
      );
    this.scrollBarOptions = {
      className: 'os-theme-minimal-dark',
      callbacks: {
        onScroll: $event => this.onScroll($event)
      }
    };
  }

  onScroll(event) {
    const scrollLeft = event.target.scrollLeft;
    this.scrollTwo.nativeElement.scrollLeft = scrollLeft;
  }
}
