import { Action } from '@ngrx/store';
import { ExpandableColumn } from './expandable-columns.reducer';
import { Update } from '@ngrx/entity';

export const UPDATE_ALL_EXPANDABLE_COLUMNS = '[Expandable columns] map all entities';
export const UPSERT_EXPANDABLE_COLUMNS = '[Expandable columns] Upsert one';

export class UpsertExpandableColumns implements Action {
  readonly type = UPSERT_EXPANDABLE_COLUMNS;
  constructor(public payload: ExpandableColumn) {}
}

export class UpdateAllExpandableColumns implements Action {
  readonly type = UPDATE_ALL_EXPANDABLE_COLUMNS;
  constructor(public updates: Array<Update<ExpandableColumn>>) {}
}

export type All = UpsertExpandableColumns | UpdateAllExpandableColumns;
