import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'awesome-slider',
  styleUrls: ['./awesome-slider.scss'],
  template: `
    <nouislider [config]="config" [disabled]="disabled" [(ngModel)]="ngModel"></nouislider>
    <span class="awesome-slider__plus-icon" *ngIf="plusIcon">➕</span>
  `
})
export class AwesomeSliderComponent implements OnInit {
  @Input() start = 0;
  @Input() max = 5;
  @Input() min = 0;
  @Input() step = 1;
  @Input() disabled = false;
  @Input() pips: object;
  @Input() plusIcon = false;

  private _ngModel: number;
  public config: any = {
    start: this.start,
    step: this.step,
    tooltips: true,
    disabled: false,
    range: {
      min: this.max,
      max: this.min
    }
  };
  @Output() ngModelChange = new EventEmitter();

  get ngModel() {
    return this._ngModel;
  }

  @Input('ngModel')
  set ngModel(value) {
    this._ngModel = value;
    this.ngModelChange.emit(this._ngModel);
  }

  ngOnInit() {
    this.config.start = this.start;
    this.config.range.max = this.max;
    this.config.range.min = this.min;
    this.config.step = this.step;
    this.config.pips = this.pips;
  }
}
