import * as Actions from './current-account.actions';
import * as Immutable from 'immutable';
import { Account } from 'exoffice-js-client';
type Action = Actions.All;

export const initialState = { firstName: 'Niezalogowany', lastName: '', email: 'exoffice@expander.pl' };

export function CurrentAccountReducer(
  state: Immutable.Record<Account> = Immutable.fromJS(initialState),
  action: Action
): any {
  switch (action.type) {
    case Actions.LOAD_CURRENT_ACCOUNT:
      return state;
    case Actions.LOAD_CURRENT_ACCOUNT_SUCCESS:
      return Immutable.merge(state, action.payload);
    case Actions.LOAD_CURRENT_ACCOUNT_FAILURE:
      return state.clear().merge(initialState);
    default:
      return state;
  }
}
