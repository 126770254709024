import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import {
  MortgageOffer,
  MortgageOfferInput,
  MortgageOfferList,
  MortgageOfferService as OriginalMortgageOfferService
} from 'exoffice-js-client';
import { Observable } from 'rxjs/Rx';
import { ServiceFactory } from '../service-factory';

export class MortgageOfferService extends OriginalMortgageOfferService {
  public findOffersBySimulationId(
    simulationId: string,
    limit?: number,
    offset?: number,
    sorts?: string[],
    selected?: boolean,
    creditworthiness?: boolean,
    fields?: string[],
    productVendorIdIn?: string[],
    productPromotion?: boolean,
    productNoEarlyRepaymentFee?: boolean,
    productInterestRateType?: string,
    noProvision?: boolean
  ): Observable<MortgageOfferList> {
    return this.findOffersByMortgageSimulationId(
      simulationId,
      limit,
      offset,
      sorts,
      selected,
      creditworthiness,
      fields,
      productVendorIdIn,
      productPromotion,
      productNoEarlyRepaymentFee,
      productInterestRateType,
      noProvision
    );
  }

  public updateOffer(id: string, mortgageOffer: MortgageOfferInput): Observable<MortgageOffer> {
    return this.updateMortgageOffer(id, mortgageOffer);
  }
}

export function mortgageOfferServiceFactory(oAuthService: OAuthService, http: HttpClient) {
  return new ServiceFactory(MortgageOfferService, oAuthService, http).createService();
}

export let mortgageOfferServiceProvider = [
  {
    provide: MortgageOfferService,
    useFactory: mortgageOfferServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
