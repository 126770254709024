import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Dictionary } from '../../../../../../models/dictionary.model';
import { FindByPipe } from '../../../../../../pipes/find-by/find-by.pipe';
import { MONTHLY_COSTS } from '../../../constants/monthly-costs';
import { TOTAL_SUM_COSTS } from '../../../constants/total-sum-costs';
import { OfferCrossSell } from 'exoffice-js-client';
import {
  MortgageOfferService,
  mortgageOfferServiceProvider
} from '../../../../../../services/providers/mortgage-offer-service.provider';
import { VisibilityTreeService } from '../../../../../../services/visibility-tree/visibility-tree.service';
import { OfferComponent } from '../base/offer.component';
import { SimulationNgrxService } from '../../../../../../services/ngrx/simulation-ngrx.service';
import { OffersNgrxService } from '../../../../../../services/ngrx/offers-ngrx.service';
import { AdditionalCostsPipe } from '../../../../../../pipes/additional-costs/additional-costs.pipe';
import { CrossSellService } from '../../../../../../services/simulation/cross-sell/cross-sell.service';
import { FrequencyType } from '../../../../../../components/common/frequency-type/frequency-type';
import { INITIAL_COSTS } from '../../../constants/initial-costs';

@Component({
  selector: 'mortgage-offer',
  templateUrl: '../base/offer.component.html',
  styleUrls: ['../base/offer.component.scss'],
  providers: [mortgageOfferServiceProvider],
  encapsulation: ViewEncapsulation.None
})
export class MortgageOfferComponent extends OfferComponent implements OnInit, OnDestroy {
  showCreditworthiness = true;
  monthlyCosts: Dictionary[] = MONTHLY_COSTS;
  totalSumCosts: Dictionary[] = TOTAL_SUM_COSTS;
  initialCosts: Dictionary[] = INITIAL_COSTS;

  constructor(
    public offerApi: MortgageOfferService,
    public visibilityTreeService: VisibilityTreeService,
    public simulationNgrxService: SimulationNgrxService,
    private findByPipe: FindByPipe,
    private additionalCostsPipe: AdditionalCostsPipe,
    private offersNgrxService: OffersNgrxService,
    private crossSellService: CrossSellService
  ) {
    super(visibilityTreeService, simulationNgrxService);
  }

  updateOffer(offer, withRRSO = true) {
    this.offersNgrxService.updateMortgageOffer(offer.id, offer, withRRSO);
  }

  selectOffer(offer) {
    offer.selected = !offer.selected;
    this.onOfferSelected.emit(offer);
    this.updateOffer({ id: offer.id, selected: offer.selected }, false);
  }

  cloneOffer(offer) {
    super.cloneOffer(this.offerApi.cloneMortgageOffer({ originId: offer.id }));
  }

  freezeOffer(offer) {
    this.offerIsUpdating = true;
    if (!offer.frozen) {
      this.offerApi.cloneMortgageOffer({ originId: offer.id }).subscribe(response => {
        this.updateOffer({ id: response.id, frozen: !offer.frozen }, false);
        this.refreshOffers$.next(response);
        this.offerIsUpdating = false;
      });
    } else {
      this.removeOffer(offer);
    }
  }

  removeOffer(offer) {
    super.removeOffer(this.offerApi.deleteMortgageOffer(offer.id), offer.id);
  }

  findInitialCost(value) {
    const initialCosts = this.additionalCostsPipe.transform(this.offer.additionalCosts.content, FrequencyType.Once);
    return this.findCost(initialCosts, value);
  }

  findOfferCrossSell(name: string): OfferCrossSell {
    return this.crossSellService.findOfferCrossSell(this.offer, name);
  }

  findMonthlyCost(value) {
    const monthlyCosts = this.additionalCostsPipe.transform(this.offer.additionalCosts.content, FrequencyType.Monthly);
    return this.findCost(monthlyCosts, value);
  }

  findAdditionalCost(value) {
    return this.findCost(this.offer.additionalCosts.content, value);
  }

  findCost(costs, value, key = 'name') {
    return this.findByPipe.transform(costs, key, value);
  }
}
