import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgSpinKitModule } from 'ng-spin-kit';
import { SpinWithBlockComponent } from './spin-with-block.component';

@NgModule({
  declarations: [SpinWithBlockComponent],
  imports: [BrowserModule, NgSpinKitModule],
  exports: [SpinWithBlockComponent]
})
export class SpinWithBlockModule {}
