import { Component, Input } from '@angular/core';
import { MortgageOffer } from 'exoffice-js-client';
import { FindByPipe } from '../../../../../../../pipes/find-by/find-by.pipe';

@Component({
  selector: 'cross-sell-cost',
  templateUrl: './cross-sell-cost.component.html'
})
export class CrossSellCostComponent {
  @Input() offer: MortgageOffer;
  @Input() cost: any;

  constructor(private findByPipe: FindByPipe) {}

  findAdditionalCost(value) {
    return this.findByPipe.transform(this.offer.additionalCosts.content, 'name', value);
  }

  findOfferCrossSell(value) {
    return this.findByPipe.transform(this.offer.offerCrossSells, 'crossSell.name', value);
  }

  findCrossSellCost(value) {
    const crossSell = this.findOfferCrossSell(value);
    return crossSell && crossSell.included ? crossSell : null;
  }
}
