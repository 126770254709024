import { Component, Input } from '@angular/core';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { ItemAttributeService } from 'exoffice-js-client';

@Component({
  selector: 'get-csv-button',
  templateUrl: './get-csv-button.component.html',
  styles: [':host { position: absolute; right: 10px;top:13px}']
})
export class GetCsvButtonComponent {
  @Input()
  vendorProductTypeId: string;
  faFileCSV = faFileCsv;
  constructor(private itemAttributeService: ItemAttributeService) {}

  getCSV() {
    this.itemAttributeService.addItemAttributesReport([this.vendorProductTypeId]).subscribe(res => {
      const downloadLink = res.links.find(link => link.rel === 'download');
      const api = this.itemAttributeService.configuration.basePath.slice(0, -4);
      const url = api + (downloadLink ? downloadLink.href : '');
      window.open(url, 'csvReport');
    });
  }
}
