import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExpandableColumnsNgrxService } from '../../../services/ngrx/expandable-columns-ngrx.service';
import { ExpandableColumn } from '../../../reducers/expandable-columns/expandable-columns.reducer';
import * as _ from 'lodash';
import { Dictionary } from '@ngrx/entity';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'columns-size',
  templateUrl: './columns-size.component.html',
  styleUrls: ['./columns-size.component.scss']
})
export class ColumnsSizeComponent implements OnInit, OnDestroy {
  columnWidth = 320;
  entities: Dictionary<ExpandableColumn>;
  public _unsubscribeAll: Subject<any> = new Subject();
  constructor(private expandableColumnsNgrxService: ExpandableColumnsNgrxService) {}

  changeSize() {
    const width = this.columnWidth;
    this.expandableColumnsNgrxService.updateAll(
      _.map(this.entities, entity => ({ id: entity.id, changes: { width } }))
    );
  }

  ngOnInit(): void {
    this.expandableColumnsNgrxService.columns.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.entities = data.entities;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
