import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CustomFormsModule } from 'ng2-validation';
import { AwesomeSliderModule } from '../../../components/common/awesome-slider/awesome-slider.module';
import { SpinWithBlockModule } from '../../../components/common/spin-with-block/spin-with-block.module';
import { TabComponent } from '../../../components/common/tabs/tab.component';
import { TabsComponent } from '../../../components/common/tabs/tabs.component';
import { FormWizardModule } from '../../../components/common/wizard/wizard.module';
import { BorrowersTabsCashComponent } from '../../../components/simulation/borrowers-tabs/cash/borrower-tabs-cash.component';
import { BorrowersTabsMortgageComponent } from '../../../components/simulation/borrowers-tabs/mortgage/borrower-tabs-mortgage.component';
import { HouseholdsTabsComponent } from '../../../components/simulation/households-tabs/households-tabs.component';
import { UpkeepCostsComponent } from '../../../components/simulation/upkeep-costs-form/upkeep-costs-form.component';
import { AmountModule } from '../../../directives/amount/amount.module';
import { ProvideParentFormDirective } from '../../../directives/provide-parent-form.directive';
import { AmountFormatPipeModule } from '../../../pipes/amount-format/amount-format.module';
import { SimulationWizardCashComponent } from './cash/simulation-wizard-cash.component';
import { SimulationWizardMortgageComponent } from './mortgage/simulation-wizard-mortgage.component';

import { BorrowerAgeComponent } from '../../../components/simulation/borrowers-tabs/borrower-age/borrower-age.component';
import { BorrowerIncomeComponent } from '../../../components/simulation/borrowers-tabs/borrower-income/borrower-income.component';
import { DurationAsModule } from '../../../directives/duration-as/duration-as.module';
import { DurationAsPipeModule } from '../../../pipes/duration-as/duration-as.module';
import { ModalOfferFiltersModule } from '../../../components/common/modal-offer-filters/modal-offer-filters.module';
import { CollateralValueModule } from '../../../directives/collateral-value/collateral-value.module';
import { GeneralInformationFormModule } from '../../../directives/general-information-form/general-information-form.module';

@NgModule({
  imports: [
    GeneralInformationFormModule,
    BrowserModule,
    CollateralValueModule,
    FormsModule,
    FormWizardModule,
    AwesomeSliderModule,
    SpinWithBlockModule,
    AmountFormatPipeModule,
    DurationAsPipeModule,
    AmountModule,
    DurationAsModule,
    CustomFormsModule,
    ReactiveFormsModule,
    ModalOfferFiltersModule
  ],
  declarations: [
    TabsComponent,
    TabComponent,
    HouseholdsTabsComponent,
    BorrowersTabsCashComponent,
    BorrowersTabsMortgageComponent,
    SimulationWizardCashComponent,
    SimulationWizardMortgageComponent,
    UpkeepCostsComponent,
    ProvideParentFormDirective,
    BorrowerAgeComponent,
    BorrowerIncomeComponent
  ]
})
export class SimulationWizardModule {}
