import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SeparatorFormatPipe } from './separator-format.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [SeparatorFormatPipe],
  exports: [SeparatorFormatPipe]
})
export class SeparatorFormatPipeModule {}
