// ./effects/auth.effects.ts
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as VendorsActions from '../../reducers/vendors/vendors.actions';
import * as ProductTypesActions from '../../reducers/product-types/product-types.actions';

import * as SimulationActions from '../../reducers/simulation/simulation.actions';
import { MortgageSimulationService } from '../../services/simulation/mortgage/mortgage-simulation.service';
import { CashSimulationService } from '../../services/simulation/cash/cash-simulation.service';
const addMortgageSimulationDispatchActionsMap = switchMap(data => [
  { type: SimulationActions.ADD_SIMULATION_SUCCESS, payload: data },
  { type: VendorsActions.ADD_SIMULATION_VENDORS, payload: data }
]);

const findMortgageSimulationDispatchActionsMap = switchMap(data => [
  { type: SimulationActions.FIND_SIMULATION_BY_ID_SUCCESS, payload: data },
  { type: VendorsActions.ADD_SIMULATION_VENDORS, payload: data }
]);

const addCashSimulationDispatchActionsMap = switchMap(data => [
  { type: SimulationActions.ADD_SIMULATION_SUCCESS, payload: data },
  { type: VendorsActions.ADD_SIMULATION_VENDORS, payload: data },
  { type: ProductTypesActions.ADD_SIMULATION_PRODUCT_TYPES, payload: data }
]);

const findCashSimulationDispatchActionsMap = switchMap(data => [
  { type: SimulationActions.FIND_SIMULATION_BY_ID_SUCCESS, payload: data },
  { type: VendorsActions.ADD_SIMULATION_VENDORS, payload: data },
  { type: ProductTypesActions.ADD_SIMULATION_PRODUCT_TYPES, payload: data }
]);

@Injectable()
export class SimulationEffects {
  // Listen for the 'ADD_MORTGAGE_SIMULATION' action
  @Effect()
  addMortgageSimulation$: Observable<Action> = this.actions$.pipe(
    ofType(SimulationActions.ADD_MORTGAGE_SIMULATION),

    mergeMap((action: any) => {
      return this._mortgageSimulationService.addMortgageSimulation(action.payload).pipe(
        // If successful, dispatch success action with result
        addMortgageSimulationDispatchActionsMap,
        // If request fails, dispatch failed action
        catchError(data => of({ type: SimulationActions.ADD_SIMULATION_FAILURE, payload: data }))
      );
    })
  );

  // Listen for the 'ADD_CASH_SIMULATION' action
  @Effect()
  addCashSimulation$: Observable<Action> = this.actions$.pipe(
    ofType(SimulationActions.ADD_CASH_SIMULATION),

    mergeMap((action: any) => {
      return this._cashSimulationService.addCashSimulation(action.payload).pipe(
        // If successful, dispatch success action with result
        addCashSimulationDispatchActionsMap,
        // If request fails, dispatch failed action
        catchError(data => of({ type: SimulationActions.ADD_SIMULATION_FAILURE, payload: data }))
      );
    })
  );

  // Listen for the 'FIND_MORTGAGE_SIMULATION_BY_ID' action
  @Effect()
  findMortgageSimulation$: Observable<Action> = this.actions$.pipe(
    ofType(SimulationActions.FIND_MORTGAGE_SIMULATION_BY_ID),
    mergeMap((action: SimulationActions.FindMortgageSimulationById) => {
      return this._mortgageSimulationService.findSimulationById(action.payload as string).pipe(
        // If successful, dispatch success action with result
        findMortgageSimulationDispatchActionsMap,
        // If request fails, dispatch failed action
        catchError(data => of({ type: SimulationActions.ADD_SIMULATION_FAILURE, payload: data }))
      );
    })
  );

  // Listen for the 'FIND_CASH_SIMULATION_BY_ID' action
  @Effect()
  findCashSimulation$: Observable<Action> = this.actions$.pipe(
    ofType(SimulationActions.FIND_CASH_SIMULATION_BY_ID),

    mergeMap((action: any) => {
      return this._cashSimulationService.findCashSimulationById(action.payload).pipe(
        // If successful, dispatch success action with result
        findCashSimulationDispatchActionsMap,
        // If request fails, dispatch failed action
        catchError(data => of({ type: SimulationActions.FIND_SIMULATION_BY_ID_FAILURE, payload: data }))
      );
    })
  );

  // Listen for the 'UPDATE_MORTGAGE_SIMULATION' action
  @Effect()
  updateMortgageSimulation$: Observable<Action> = this.actions$.pipe(
    ofType(SimulationActions.UPDATE_MORTGAGE_SIMULATION),

    mergeMap((action: any) => {
      return this._mortgageSimulationService.updateMortgageSimulation(action.payload.id, action.payload).pipe(
        // If successful, dispatch success action with result
        map(data => ({ type: SimulationActions.UPDATE_SIMULATION_SUCCESS, payload: action.payload })),
        // If request fails, dispatch failed action
        catchError(data => of({ type: SimulationActions.UPDATE_SIMULATION_FAILURE, payload: data }))
      );
    })
  );

  // Listen for the 'UPDATE_CASH_SIMULATION' action
  @Effect()
  updateCashSimulation$: Observable<Action> = this.actions$.pipe(
    ofType(SimulationActions.UPDATE_CASH_SIMULATION),

    mergeMap((action: any) => {
      return this._cashSimulationService.updateSimulation(action.payload.id, action.payload).pipe(
        // If successful, dispatch success action with result
        map(data => ({ type: SimulationActions.UPDATE_SIMULATION_SUCCESS, payload: action.payload })),
        // If request fails, dispatch failed action
        catchError(data => of({ type: SimulationActions.UPDATE_SIMULATION_FAILURE, payload: data }))
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _mortgageSimulationService: MortgageSimulationService,
    private _cashSimulationService: CashSimulationService
  ) {}
}
