import { Component, Input } from '@angular/core';
import { MortgageOffer } from 'exoffice-js-client';
import { CrossSellService } from '../../../../../../../services/simulation/cross-sell/cross-sell.service';
import { AdditionalCostsPipe } from '../../../../../../../pipes/additional-costs/additional-costs.pipe';
import { FindByPipe } from '../../../../../../../pipes/find-by/find-by.pipe';
import { OfferCrossSellCost } from '../../../../../../../models/offer-cross-sell-cost.model';
import { FrequencyType } from '../../../../../../../components/common/frequency-type/frequency-type';

@Component({
  selector: 'cross-sell-monthly-cost',
  templateUrl: './cross-sell-monthly-cost.component.html'
})
export class CrossSellMonthlyCostComponent {
  @Input() offer: MortgageOffer;
  @Input() cost: OfferCrossSellCost;

  constructor(
    private crossSellService: CrossSellService,
    private additionalCostsPipe: AdditionalCostsPipe,
    private findByPipe: FindByPipe
  ) {}

  findOfferCrossSell() {
    return this.crossSellService.findOfferCrossSell(this.offer, this.cost.key);
  }

  findMonthlyCost(value) {
    const additionalMonthlyCosts = this.additionalCostsPipe.transform(
      this.offer.additionalCosts.content,
      FrequencyType.Monthly
    );
    const scope = this.findByPipe.transform(additionalMonthlyCosts, 'name', value);
    return scope && scope.cost > 0 ? scope.cost : 0;
  }

  debtBalanceCost(): boolean {
    return this.crossSellService.findContributionsFeeCalculationBase(this.offer, this.cost.key, 'debt_balance');
  }

  marginIncreaseCost(): boolean {
    return this.crossSellService.findContributionsFeeCalculationBase(this.offer, this.cost.key, 'margin_increase');
  }
}
