import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/Rx';

@Injectable()
export class ErrorStatusHttpInterceptor implements HttpInterceptor {
  constructor(private _router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).catch(err => {
      const status = err.status;
      if (status === 404 || status === 500 || status === 403 || status === 401) {
        this._router.navigate([`/${status}`]);
        return Observable.empty();
      } else {
        return Observable.throw(err);
      }
    }) as any;
  }
}
