import * as Actions from './expanded-cross-sells.actions';
import * as Immutable from 'immutable';
import * as _ from 'lodash';
type Action = Actions.All;

export const initialState: ExpandedCrossSells = {
  bridgingInsurance: { key: 'Ubezpieczenie pomostowe', value: false },
  realEstateInsurance: { key: 'Ubezpieczenie nieruchomości', value: false },
  lifeInsurance: { key: 'Ubezpieczenie na życie', value: false },
  unemploymentInsurance: { key: 'Ubezpieczenie od utraty pracy', value: false },
  lowContributionInsurance: { key: 'Ubezpieczenia niskiego wkładu', value: false },
  account: { key: 'Konto bankowe', value: false },
  creditCard: { key: 'Karta kredytowa', value: false },
  productVariant: { key: 'Wariant produktu', value: false }
};

export interface ExpandedCrossSells {
  bridgingInsurance: ExpandedCrossSell;
  realEstateInsurance: ExpandedCrossSell;
  lifeInsurance: ExpandedCrossSell;
  unemploymentInsurance: ExpandedCrossSell;
  lowContributionInsurance: ExpandedCrossSell;
  account: ExpandedCrossSell;
  creditCard: ExpandedCrossSell;
  productVariant: ExpandedCrossSell;
}

export interface ExpandedCrossSell {
  key:
    | 'Ubezpieczenie pomostowe'
    | 'Ubezpieczenie nieruchomości'
    | 'Ubezpieczenie nieruchomości'
    | 'Ubezpieczenie na życie'
    | 'Ubezpieczenie od utraty pracy'
    | 'Ubezpieczenia niskiego wkładu'
    | 'Konto bankowe'
    | 'Karta kredytowa'
    | 'Wariant produktu';
  value: boolean;
}

export function ExpandedCrossSellsReducer(
  state: Immutable.Record<ExpandedCrossSells> = Immutable.fromJS(initialState),
  action: Action
): any {
  switch (action.type) {
    case Actions.LOAD_INITIAL_EXPANDED_CROSS_SELL:
      return state.merge(Immutable.fromJS(initialState));
    case Actions.SET_EXPANDED_CROSS_SELL:
      const crossSellKey = _.findKey(state.toJS(), o => {
        return o.key === action.payload.key;
      });
      return state.setIn([crossSellKey, 'value'], action.payload.value);
    default:
      return state;
  }
}
