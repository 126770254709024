import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { OfferCrossSell, VendorCrossSell } from 'exoffice-js-client';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { VisibilityTreeService } from '../../../../../../../services/visibility-tree/visibility-tree.service';
import { MortgageOffer } from '../../../../../../../../../graphql/generated';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'cross-sell',
  templateUrl: './cross-sell.component.html',
  styleUrls: ['./cross-sell.component.scss']
})
export class CrossSellComponent implements OnInit {
  private offer$: BehaviorSubject<MortgageOffer> = new BehaviorSubject<MortgageOffer>(null);
  private isSelected: false;
  @Input()
  set offer(value) {
    this.offer$.next(value);
  }

  get offer() {
    return this.offer$.getValue();
  }

  get vendorCrossSell(): VendorCrossSell {
    return this.offerCrossSell.vendorCrossSells.find(
      vendorCrossSell => vendorCrossSell.id === this.offerCrossSell.vendorCrossSellId
    );
  }

  set vendorCrossSell(vendorCrossSell) {
    this.offerCrossSell.vendorCrossSellId = vendorCrossSell.id;
  }

  @Input()
  name: string;

  @Input()
  currency: string;

  @Input()
  id: string;

  @Input()
  warningStatus: boolean;

  @Output()
  updateEmitter: EventEmitter<object> = new EventEmitter();
  offerCrossSell: OfferCrossSell;
  modalRef: BsModalRef;

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  constructor(public visibilityTreeService: VisibilityTreeService, private modalService: BsModalService) {}

  ngOnInit() {
    this.offer$.subscribe(offer => {
      this.setOfferCrossSell(offer);
      if (!this.vendorCrossSell && this.offerCrossSell.vendorCrossSells.length > 0) {
        this.vendorCrossSell = this.offerCrossSell.vendorCrossSells[0];
      }
    });
  }

  detailsSwitch() {
    this.visibilityTreeService.toggleDetails(this.id);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  isDetailsVisible() {
    return this.visibilityTreeService.isDetailsVisible(this.id);
  }

  setOfferCrossSell(offer) {
    this.offerCrossSell = this.findOfferCrossSell(offer) || this.offerCrossSell;
  }

  findOfferCrossSell(offer): OfferCrossSell {
    return offer.offerCrossSells.find(offerCrossSell => offerCrossSell.crossSell.name === this.name);
  }

  isInsuranceContentExtended() {
    return !_.includes(['Ubezpieczenie pomostowe', 'Ubezpieczenia niskiego wkładu'], this.name);
  }

  emitUpdateOffer(offer) {
    const offerCrossSellsData = offer.offerCrossSells.map(cs => {
      return {
        included: cs.included,
        vendorCrossSellId: cs.vendorCrossSellId,
        crossSellId: cs.crossSell.id,
        contributionAmount: cs.contributionAmount
      };
    });
    const offerPartsToUpdate = {
      id: offer.id,
      offerCrossSellsData
    };
    this.updateEmitter.emit(offerPartsToUpdate);
  }
}
