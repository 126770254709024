import { VisibilityTreeModel } from '../../../models/visibilityTree.model';
import { VisibilityTreeService } from '../visibility-tree.service';

const CASH_TREE: VisibilityTreeModel[] = [
  {
    name: 'Nazwa banku',
    id: 'product.vendor.name',
    isDetailsVisible: true,
    isBlocked: true
  },
  {
    name: 'Nazwa produktu',
    id: 'product.name',
    isBlocked: true
  },
  {
    name: 'Kwota kredytu brutto',
    id: 'offer.grossAmount',
    isBlocked: true
  },
  {
    name: 'Oprocentowanie nominalne',
    id: 'offer.nominalInterestRate',
    isBlocked: true
  },
  {
    name: 'RRSO',
    id: 'rrso',
    isBlocked: true
  },

  {
    name: 'Całkowita kwota do spłaty',
    id: 'offer.totalSum',
    isBlocked: true
  },
  {
    name: 'Prowizja',
    id: 'offer.provisionCash',
    isHidden: false
  },
  {
    name: 'Typ produktu',
    id: 'offer.productTypeName',
    isBlocked: false
  },
  {
    name: 'Rata kredytu',
    id: 'offer.installment',
    isBlocked: true
  },
  {
    name: 'Opis produktu',
    id: 'product.description',
    isBlocked: true
  }
];

export function visibilityTreeCashServiceFactory() {
  return new VisibilityTreeService(CASH_TREE);
}

export let visibilityTreeCashServiceProvider = [
  {
    provide: VisibilityTreeService,
    useFactory: visibilityTreeCashServiceFactory
  }
];
