import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { VendorProductTypeService } from 'exoffice-js-client';
import { ServiceFactory } from '../../service-factory';

export function vendorProductTypeServiceFactory(oAuthService: OAuthService, httpClient: HttpClient) {
  return new ServiceFactory(VendorProductTypeService, oAuthService, httpClient).createService();
}

export let vendorProductTypeServiceProvider = [
  {
    provide: VendorProductTypeService,
    useFactory: vendorProductTypeServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
