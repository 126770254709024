import { Action } from '@ngrx/store';
import { ItemAttribute } from 'exoffice-js-client/model/itemAttribute';

export const LOAD_ITEM_ATTRIBUTES = '[Item attributes - API] Load item attributes';
export const LOAD_ITEM_ATTRIBUTES_SUCCESS = '[Item attributes - API] Load item attributes success';
export const LOAD_ITEM_ATTRIBUTES_FAILURE = '[Item attributes - API] Load item attributes failure';
export const UPDATE_ITEM_ATTRIBUTE = '[Item attributes - API] Update item attribute';
export const UPDATE_ITEM_ATTRIBUTE_SUCCESS = '[Item attributes - API] Update item attribute success';
export const UPDATE_ITEM_ATTRIBUTE_FAILURE = '[Item attributes - API] Update item attribute failure';
export const GET_ITEM_ATTRIBUTES = '[Get Item attributes - API] Get item attributes';
export const SET_CAN_DOWNLOAD_REPORT = '[Item attributes] Set can download report';

export interface GetItemAttributesPayload {
  sorts?: string;
  itemIdIn?: string[];
  attributeDefinitionIdIn?: string[];
}

export class ItemAttributes implements Action {
  readonly type = LOAD_ITEM_ATTRIBUTES;
  constructor() {}
}

export class SetCanDownloadReport implements Action {
  readonly type = SET_CAN_DOWNLOAD_REPORT;
  constructor(public payload) {}
}

export class GetItemAttributes implements Action {
  readonly type = GET_ITEM_ATTRIBUTES;
  constructor(public payload: GetItemAttributesPayload) {}
}

export class ItemAttributesSuccess implements Action {
  readonly type = LOAD_ITEM_ATTRIBUTES_SUCCESS;
  constructor(public payload: ItemAttribute[]) {}
}

export class ItemAttributesFailure implements Action {
  readonly type = LOAD_ITEM_ATTRIBUTES_FAILURE;
  constructor() {}
}

export class UpdateItemAttribute implements Action {
  readonly type = UPDATE_ITEM_ATTRIBUTE;
  constructor(public id: string, public itemAttribute: Partial<ItemAttribute>) {}
}

export class UpdateItemAttributeSuccess implements Action {
  readonly type = UPDATE_ITEM_ATTRIBUTE_SUCCESS;
  constructor(public id: string, public changes: Partial<ItemAttribute>) {}
}

export class UpdateItemAttributeFailure implements Action {
  readonly type = UPDATE_ITEM_ATTRIBUTE_FAILURE;
  constructor() {}
}

export type All =
  | SetCanDownloadReport
  | ItemAttributes
  | ItemAttributesSuccess
  | ItemAttributesFailure
  | GetItemAttributes
  | UpdateItemAttribute
  | UpdateItemAttributeSuccess
  | UpdateItemAttributeFailure;
