import { Component, ElementRef } from '@angular/core';
import { SimulationBoxComponent } from '../simulation-box.component';
import { SimulationNgrxService } from '../../../../../services/ngrx/simulation-ngrx.service';
import { EditBoxService } from '../../../../../services/simulation/edit-box/edit-box.service';

@Component({
  selector: 'simulation-box-cash',
  templateUrl: './simulation-box-cash.component.html',
  styleUrls: ['../simulation-box.component.scss']
})
export class SimulationBoxCashComponent extends SimulationBoxComponent {
  constructor(public _eref: ElementRef, simulationNgrxService: SimulationNgrxService, editBoxService: EditBoxService) {
    super(_eref, simulationNgrxService, editBoxService);
  }
}
