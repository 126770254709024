import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as CitiesActions from '../../reducers/cities/cities.actions';
import { CityService } from 'exoffice-js-client';
import { AppStore } from '../../models/app-store.model';

@Injectable()
export class CitiesEffects {
  // Listen for the 'LOAD_CITIES' action
  @Effect()
  loadCities$: Observable<Action> = this.actions$.pipe(
    ofType(CitiesActions.LOAD_CITIES),
    withLatestFrom(this.store$),
    filter(([action, state]: [Action, AppStore]) => !state.cities.loaded),
    mergeMap(() => {
      return this._cityService.findCities(40, 0, 'province_name').pipe(
        map(data => ({
          type: CitiesActions.LOAD_CITIES_SUCCESS,
          payload: data.content
        })),
        catchError(() =>
          of({
            type: CitiesActions.LOAD_CITIES_FAILURE
          })
        )
      );
    })
  );

  constructor(private actions$: Actions, private _cityService: CityService, private store$: Store<AppStore>) {}
}
