import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MortgageOffer } from 'exoffice-js-client';
import * as _ from 'lodash';
import { MarginSchedule } from '../../../../../../../models/margin-schedule.model';
import { SnakeToCamelPipe } from '../../../../../../../pipes/snake-to-camel/snake-to-camel.pipe';

@Component({
  selector: 'edit-offer-multiple-attribute',
  templateUrl: './edit-offer-multiple-attribute.component.html',
  styleUrls: ['./edit-offer-multiple-attribute.component.scss']
})
export class EditOfferMultipleAttributeComponent {
  editMode = false;
  originalValue: MarginSchedule[];
  @Input() values: MarginSchedule[];
  @Input() offer: MortgageOffer;
  @Input() linkRelName: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  constructor(private _SnakeToCamelPipe: SnakeToCamelPipe) {}

  isPossibleToRevert() {
    return this.offer.links.find(link => {
      return link.rel === this.linkRelName;
    });
  }

  setFlagToRevertAndUpdateOffer() {
    this.offer[this._SnakeToCamelPipe.transform(this.linkRelName)] = true;
    this.valueChange.emit();
    this.editMode = false;
  }

  updateOfferIfFieldChanged() {
    this.editMode = false;
    if (!_.isEqual(this.originalValue, this.values)) {
      this.originalValue = this.values;
      this.valueChange.emit();
    }
  }

  cancelEdit() {
    this.editMode = false;
    this.values = this.originalValue;
  }

  openEdit() {
    this.editMode = true;
    this.originalValue = _.cloneDeep(this.values);
  }
}
