import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { SimulationService } from 'exoffice-js-client';
import { ServiceFactory } from '../service-factory';

export function simulationServiceFactory(oAuthService: OAuthService, httpClient: HttpClient) {
  return new ServiceFactory(SimulationService, oAuthService, httpClient).createService();
}

export let simulationServiceProvider = [
  {
    provide: SimulationService,
    useFactory: simulationServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
