import * as Actions from './product-types.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ProductType } from 'exoffice-js-client';

type Action = Actions.All;

export const productTypesAdapter = createEntityAdapter<ProductType>();

export interface ProductTypesEntities extends EntityState<ProductType> {
  loading: boolean;
  loaded: boolean;
}

export const initialState: ProductTypesEntities = productTypesAdapter.getInitialState({
  // additional entity state properties
  loading: false,
  loaded: false
});

export default function ProductTypesReducer(state: ProductTypesEntities = initialState, action?: Action): any {
  switch (action.type) {
    case Actions.ADD_SIMULATION_PRODUCT_TYPES: {
      return {
        ...productTypesAdapter.addAll(action.payload.productTypes, state),
        loaded: true,
        loading: false
      };
    }
    case Actions.LOAD_PRODUCT_TYPES_SUCCESS: {
      return {
        ...productTypesAdapter.addAll(action.payload, state),
        loading: false,
        loaded: true
      };
    }

    default:
      return state;
  }
}
