import { AfterViewInit, Directive, ElementRef, Input, HostListener, Renderer2 } from '@angular/core';
import { ExpandableKnowledgeRowNgrxService } from '../../services/ngrx/expandable-knowledge-row-ngrx.service';
import { ExpandableKnowledgeRow } from '../../reducers/knowledge-base/expandable-knowledge-rows/expandable-knowledge-rows.reducer';

@Directive({
  selector: '[expandableKnowledgeRowHeading]'
})
export class ExpandableKnowledgeRowHeadingDirective implements AfterViewInit {
  height: ExpandableKnowledgeRow['height'];
  storageIsExpanded: ExpandableKnowledgeRow['isExpanded'];
  @Input()
  id: ExpandableKnowledgeRow['id'];

  @HostListener('click', ['$event.target'])
  onClick() {
    this.expandableKnowledgeRowNgrxService.toggleExpand(this.id, !this.storageIsExpanded);
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private expandableKnowledgeRowNgrxService: ExpandableKnowledgeRowNgrxService
  ) {}

  ngAfterViewInit() {
    this.renderer.addClass(this.el.nativeElement, 'expandable-knowledge-row-heading');
    this.expandableKnowledgeRowNgrxService.getItemById(this.id).subscribe(data => {
      if (data) {
        const storedHeight = data.height;
        this.storageIsExpanded = data.isExpanded;
        this.el.nativeElement.style.height = this.storageIsExpanded && storedHeight !== 0 ? storedHeight + 'px' : null;
      }
    });
  }
}
