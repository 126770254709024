import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { ProductType, Vendor } from 'exoffice-js-client';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OfferFiltersNgrxService } from '../../../services/ngrx/offer-filters-ngrx.service';
import { OffersFilters } from '../../../reducers/offers-filters/offers-filters';
import { KnowledgeBaseNgrxService } from '../../../services/ngrx/knowledge-base-ngrx.service';

@Component({
  selector: 'list-of-entities',
  templateUrl: './list-of-entities.component.html',
  styleUrls: ['./list-of-entities.component.scss']
})
export class ListOfEntitiesComponent implements OnInit, OnDestroy {
  @Input()
  entities: Dictionary<Vendor | ProductType>;
  @Input()
  entitiesIds: string[] | number[];
  @Input()
  title: string;
  @Input()
  filterKey: keyof OffersFilters;
  @Input()
  applyFilters$: Subject<Event>;
  @Input()
  type: 'knowledge' | 'offer' = 'offer';

  listOfActiveIds: string[] | number[] = [];
  private _unsubscribeAll: Subject<any> = new Subject();
  @Output()
  onApplyFilters = new EventEmitter<string[] | number[]>();

  constructor(
    private offerFiltersService: OfferFiltersNgrxService,
    private knowledgeBaseNgrxService: KnowledgeBaseNgrxService
  ) {}

  ngOnInit() {
    this.applyFilters$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.onApplyFilters.emit(_.clone(this.listOfActiveIds));
    });
    if (this.type === 'offer') {
      this.offerFiltersService.filters
        .pipe(take(1))
        .subscribe(filters => this.updateListOfActiveIds(filters.get(this.filterKey)));
    } else if (this.type === 'knowledge') {
      this.knowledgeBaseNgrxService.vendorProductsTypes.pipe(takeUntil(this._unsubscribeAll)).subscribe(categories => {
        const notHiddenIds = _.map(_.filter(categories.entities, category => !category.isHidden), item => item.id);
        this.updateListOfActiveIds(notHiddenIds);
      });
    }
  }

  updateListOfActiveIds(values?): void {
    if (values) {
      this.listOfActiveIds = _.clone(values);
    } else {
      this.showAll();
    }
  }

  isInTheList(id: string | number): boolean {
    return _.includes(this.listOfActiveIds, id);
  }

  toggleItem(id: string & number): void {
    this.listOfActiveIds = this.isInTheList(id)
      ? _.filter(this.listOfActiveIds, n => n !== id)
      : _.concat(this.listOfActiveIds, id);
  }

  showAll(): void {
    this.listOfActiveIds = _.clone(this.entitiesIds);
  }

  hideAll(): void {
    this.listOfActiveIds = [];
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
