import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as AttributeDefinitionsActions from '../../reducers/knowledge-base/attribute-definitions/attribute-definitions.actions';
import { AttributeDefinitionService } from 'exoffice-js-client';

@Injectable()
export class AttributeDefinitionsEffects {
  // Listen for the 'GET_ITEM_ATTRIBUTES' action
  @Effect()
  getAttributeDefinitions$: Observable<Action> = this.actions$.pipe(
    ofType(AttributeDefinitionsActions.GET_ATTRIBUTE_DEFINITIONS),
    mergeMap((action: AttributeDefinitionsActions.GetAttributeDefinitions) => {
      return this._attributeDefinitionService.findAttributeDefinitions(action.productCategoryCode).pipe(
        // If successful, dispatch success action with result
        map(data => ({ type: AttributeDefinitionsActions.LOAD_ATTRIBUTE_DEFINITIONS_SUCCESS, payload: data.content })),
        // If request fails, dispatch failed action
        catchError(data => of({ type: AttributeDefinitionsActions.LOAD_ATTRIBUTE_DEFINITIONS_FAILURE }))
      );
    })
  );

  constructor(private actions$: Actions, private _attributeDefinitionService: AttributeDefinitionService) {}
}
