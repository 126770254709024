import { BorrowerIncomeInput, MortgageBorrowerInput } from 'exoffice-js-client';
import * as _ from 'lodash';

export const BORROWER_INCOME_CONSTANT: BorrowerIncomeInput = {
  incomeDuration: 12,
  remainingContractPeriod: 12,
  net: ''
};

export const BORROWER_DEFAULTS_CONSTANT: MortgageBorrowerInput = {
  gender: 'male',
  profession: 'other',
  age: 360,
  incomes: [_.clone(BORROWER_INCOME_CONSTANT)]
};
