import * as Actions from './expandable-knowledge-rows.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

type Action = Actions.All;
export interface ExpandableKnowledgeRow {
  id: string;
  height: number;
  isExpanded: boolean;
}

export const expandableKnowledgeRowsAdapter = createEntityAdapter<ExpandableKnowledgeRow>();
export const initialState: EntityState<ExpandableKnowledgeRow> = expandableKnowledgeRowsAdapter.getInitialState({});

export default function ExpandableKnowledgeRowsReducer(
  state: EntityState<ExpandableKnowledgeRow> = initialState,
  action?: Action
): any {
  switch (action.type) {
    case Actions.UPSERT_EXPANDABLE_KNOWLEDGE_ROW: {
      return expandableKnowledgeRowsAdapter.upsertOne(action.payload, state);
    }

    default:
      return state;
  }
}
