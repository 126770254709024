import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { KnowledgeBaseNgrxService } from '../../../../services/ngrx/knowledge-base-ngrx.service';
import { Subject } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SectionsEntities, Section } from '../../../../reducers/knowledge-base/sections/sections.reducer';

@Component({
  selector: 'modal-confirmation-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">Czy na pewno usunąć sekcję?</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        Czy na pewno usunąć sekcję - <strong>{{ name }}</strong
        >?
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="confirmRemoval(id)">Tak</button>
      <button type="button" class="btn btn-primary" (click)="declineRemoval()">Nie</button>
    </div>
  `
})
export class ModalConfirmationComponent {
  id: string;
  public onClose: Subject<string | null> = new Subject<null>();

  constructor(public modalRef: BsModalRef) {}
  confirmRemoval(id: string): void {
    this.onClose.next(id);
    this.modalRef.hide();
  }

  declineRemoval(): void {
    this.onClose.next(null);
    this.modalRef.hide();
  }
}

@Component({
  selector: 'section-management-modal',
  templateUrl: './section-management-modal.component.html',
  styleUrls: ['./section-management-modal.component.scss']
})
export class SectionManagementModalComponent implements OnInit, OnDestroy {
  entities: Dictionary<Section>;
  sections: SectionsEntities;
  entitiesCopy: Dictionary<Section>;
  entitiesIds: string[] | number[];
  private _unsubscribeAll: Subject<any> = new Subject();
  @Input()
  applyFilters$: Subject<Event> = new Subject();
  modalBaseRef: BsModalRef;
  modalRef: BsModalRef;
  hasAuthorization = false;

  constructor(private knowledgeBaseNgrxService: KnowledgeBaseNgrxService, private modalService: BsModalService) {}

  openModal(template: TemplateRef<any>) {
    this.modalBaseRef = this.modalService.show(template);
  }

  ngOnInit() {
    this.modalService.onHidden.subscribe(data => {
      this.entities = _.cloneDeep(this.entitiesCopy);
    });
    this.knowledgeBaseNgrxService.sections.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.sections = data;
      this.entities = _.cloneDeep(data.entities);
      this.hasAuthorization = _.some(
        data.entities,
        (item: Section) => !_.isEmpty(_.find(item.links, link => link.rel === 'update'))
      );
      this.entitiesCopy = _.cloneDeep(this.entities);
    });
  }

  canDestroy(links): boolean {
    return _.some(links, link => link.rel === 'destroy');
  }

  openConfirmationModal(id, name) {
    this.modalRef = this.modalService.show(ModalConfirmationComponent, { initialState: { id, name } });
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        this.knowledgeBaseNgrxService.removeSection(result);
      }
    });
  }

  save() {
    const ids = this.sections.ids;
    const changes: string[] = [];
    _.forEach(ids, id => {
      if (
        this.entities[id].orderNo !== this.entitiesCopy[id].orderNo ||
        this.entities[id].name !== this.entitiesCopy[id].name
      ) {
        changes.push(id);
      }
    });
    _.forEach(changes, id => {
      this.knowledgeBaseNgrxService.sectionUpdate(this.entities[id]);
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
