import { Component, Input } from '@angular/core';
import { VisibilityTreeModel } from '../../../../../models/visibilityTree.model';
import { VisibilityTreeService } from '../../../../../services/visibility-tree/visibility-tree.service';

@Component({
  selector: 'costs-heading',
  templateUrl: './costs-heading.component.html',
  styleUrls: ['./costs-heading.component.scss']
})
export class CostsHeadingComponent {
  @Input()
  title: string;

  @Input()
  id: string;

  visibilityTree: VisibilityTreeModel[];

  constructor(private visibilityTreeService: VisibilityTreeService) {
    this.visibilityTree = this.visibilityTreeService.getTree();
  }

  isDetailsVisible(id: string): boolean {
    return this.visibilityTreeService.isDetailsVisible(id);
  }
}
