import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AwesomeSliderModule } from '../../../components/common/awesome-slider/awesome-slider.module';
import { UiSwitchModule } from '../../../components/common/ui-switch/ui-switch.module';
import { RateFormatPipeModule } from '../../../pipes/rate-format/rate-format.module';
import { SeparatorFormatPipeModule } from '../../../pipes/separator-format/separator-format.module';
import { CompareOffersBaseModule } from '../compare-offers/base/compare-offers-base.module';
import { SimulationCashComponent } from './cash/simulation-cash.component';
import { SimulationMortgageComponent } from './mortgage/simulation-mortgage.component';

@NgModule({
  declarations: [SimulationMortgageComponent, SimulationCashComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AwesomeSliderModule,
    RateFormatPipeModule,
    UiSwitchModule,
    SeparatorFormatPipeModule,
    RouterModule,
    CompareOffersBaseModule
  ]
})
export class SimulationModule {}
