import { Action } from '@ngrx/store';
import { ExpandedCrossSells, ExpandedCrossSell } from './expanded-cross-sells.reducer';

export const LOAD_INITIAL_EXPANDED_CROSS_SELL = '[Cross sell - API] Load initial expanded cross sell';
export const SET_EXPANDED_CROSS_SELL = '[Cross sell - API] Set expanded cross sell';

interface ExpandedCrossSellPayload {
  key: keyof ExpandedCrossSells;
  value: ExpandedCrossSell;
}

export class SetExpandedCrossSell implements Action {
  readonly type = SET_EXPANDED_CROSS_SELL;
  constructor(public payload: ExpandedCrossSellPayload) {}
}

export class LoadInitialExpandedCrossSell implements Action {
  readonly type = LOAD_INITIAL_EXPANDED_CROSS_SELL;
  constructor() {}
}

export type All = LoadInitialExpandedCrossSell | SetExpandedCrossSell;
