import * as Actions from './vendors.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Vendor } from 'exoffice-js-client';

type Action = Actions.All;

export const vendorsAdapter = createEntityAdapter<Vendor>();

export interface VendorsEntities extends EntityState<Vendor> {
  loading: boolean;
  loaded: boolean;
}

export const initialState: VendorsEntities = vendorsAdapter.getInitialState({
  // additional entity state properties
  loading: false,
  loaded: false
});

export default function VendorsReducer(state: VendorsEntities = initialState, action?: Action): any {
  switch (action.type) {
    case Actions.LOAD_VENDORS: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }

    case Actions.LOAD_VENDORS_SUCCESS: {
      return {
        ...vendorsAdapter.addAll(action.payload.content, state),
        loaded: true,
        loading: false
      };
    }

    case Actions.LOAD_VENDORS_FAILURE: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }

    case Actions.ADD_SIMULATION_VENDORS: {
      return {
        ...vendorsAdapter.addAll(action.payload.vendors, state),
        loaded: true,
        loading: false
      };
    }

    default:
      return state;
  }
}
