import { Component, Input, OnInit } from '@angular/core';
import { OfferFiltersNgrxService } from '../../../../../services/ngrx/offer-filters-ngrx.service';

@Component({
  selector: 'credit-worthiness',
  templateUrl: './credit-worthiness.component.html',
  styleUrls: ['./credit-worthiness.component.scss']
})
export class CreditWorthinessComponent implements OnInit {
  creditWorthinessValue: boolean;

  @Input() loading = false;

  setFilter(key, value) {
    value = value ? undefined : true;

    this.offerFiltersService.add({ key, value });
  }

  constructor(private offerFiltersService: OfferFiltersNgrxService) {}

  ngOnInit() {
    this.offerFiltersService.filters.subscribe(data => {
      this.creditWorthinessValue = data.get('creditworthiness');
    });
  }
}
