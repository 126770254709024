import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AmountFormatPipeModule } from '../../../pipes/amount-format/amount-format.module';
import { RateFormatPipeModule } from '../../../pipes/rate-format/rate-format.module';
import { SanitizatorsModule } from '../sanitizators/sanitizators.module';
import { BankContentComponent } from './bank-content/bank-content.component';
import { BaseInsuranceContentComponent } from './base-insurance-content/base-insurance-content.component';
import { InsuranceContentComponent } from './insurance-content/insurance-content.component';

@NgModule({
  imports: [CommonModule, TranslateModule, SanitizatorsModule, AmountFormatPipeModule, RateFormatPipeModule],
  declarations: [BankContentComponent, InsuranceContentComponent, BaseInsuranceContentComponent],
  exports: [BankContentComponent, InsuranceContentComponent, BaseInsuranceContentComponent]
})
export class OfferContentModule {}
