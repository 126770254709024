import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import 'rxjs/add/operator/finally';
import { BORROWER_DEFAULTS_CONSTANT } from '../../../../components/simulation/borrower-defaults.constant';
import { PurposeActivity } from '../../../../models/purpose-activity';
import { simulationServiceProvider } from '../../../../services/providers/simulation-service.provider';

import { ToastrService } from 'ngx-toastr';
import { SimulationWizardBaseComponent } from '../base/simulation-wizard-base.component';
import { DictionariesNgrxService } from '../../../../services/ngrx/dictionaries-ngrx.service';
import { takeUntil } from 'rxjs/operators';
import { SimulationNgrxService } from '../../../../services/ngrx/simulation-ngrx.service';
import { Dictionary } from '@ngrx/entity';
import { VendorsNgrxService } from '../../../../services/ngrx/vendors-ngrx.service';
import { visibilityTreeMortgageServiceProvider } from '../../../../services/visibility-tree/mortgage/visibility-tree.mortgage.provider';
import {
  MortgageSimulationInput,
  MortgageSimulation,
  LoanPurpose,
  LoanPurposeRealEstateType
} from 'exoffice-js-client';
import { OfferFiltersNgrxService } from '../../../../services/ngrx/offer-filters-ngrx.service';

@Component({
  selector: 'simulation-wizard-mortgage',
  templateUrl: 'simulation-wizard-mortgage.component.html',
  styleUrls: ['../base/simulation-wizard-base.component.scss', 'simulation-wizard-mortgage.component.scss'],
  providers: [simulationServiceProvider, visibilityTreeMortgageServiceProvider]
})
export class SimulationWizardMortgageComponent extends SimulationWizardBaseComponent implements AfterViewInit {
  model: MortgageSimulationInput;
  loanPurposeActivities: PurposeActivity[] = [];
  loanPurposes: Dictionary<LoanPurpose>;
  Math = Math;
  loanPurposesIds: string[] | number[];
  selectedLoanPurposeId: string;
  selectedLoanPurposeActivityId: string;
  selectedLoanPurposeRealEstateTypeId: string;
  loanPurposeRealEstateTypes: LoanPurposeRealEstateType[] = [];
  isCollateralMarketRequired: boolean;
  selectedGovProgram = 'twoPercentSafeCredit';
  showGovPrograms = false;
  loanPurposeHelper: string;
  selectedLoanPurposeName: string;
  isLoanPurposeInvalid = false;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public dictionariesNgrxService: DictionariesNgrxService,
    public toastr: ToastrService,
    public vendorsNgrxService: VendorsNgrxService,
    private cd: ChangeDetectorRef,
    public simulationNgrxService: SimulationNgrxService,
    public offerFiltersNgrxService: OfferFiltersNgrxService
  ) {
    super(router, route, dictionariesNgrxService, toastr, vendorsNgrxService, offerFiltersNgrxService);
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  toNumber(value: string | number): number {
    return _.toNumber(value);
  }

  async setInitialModel() {
    if (this.editMode) {
      await this.route.params.pipe(takeUntil(this._unsubscribeAll)).subscribe(item => {
        this.simulationId = item['id'];
      });
      this.simulationNgrxService.findMortgageSimulationById(this.simulationId);

      this.simulationNgrxService.simulationContent.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
        if (data) {
          const simulation = data.toJS() as MortgageSimulation;
          this.model = data.toJS() as MortgageSimulationInput;
          if (simulation.guaranteedOwnContribution || (simulation as any).twoPercentSafeCredit) {
            this.showGovPrograms = true;
            this.selectedGovProgram = simulation.guaranteedOwnContribution
              ? 'guaranteedOwnContribution'
              : 'twoPercentSafeCredit';
          }
          this.selectedLoanPurposeActivityId = _.isEmpty(simulation.loanPurpose)
            ? null
            : simulation.loanPurpose.loanPurposeActivity.id;
          this.selectedLoanPurposeRealEstateTypeId = _.isEmpty(simulation.loanPurpose)
            ? null
            : simulation.loanPurpose.loanPurposeRealEstateType
            ? simulation.loanPurpose.loanPurposeRealEstateType.id
            : null;
          this.monthsAreDivisibleToYears(this.model.loanPeriod);
        }
      });
    } else {
      this.simulationNgrxService.removeLastSimulation();
      this.model = {
        loanAmount: 200000,
        currency: 'PLN',
        collateralValue: '250000',
        loanPeriod: 360,
        collateralMarket: 'primary',
        installmentType: 'fixed',
        households: []
      };
      this.restoreLoanPeriod();
    }
  }

  firstStepEnter() {
    this.dictionariesNgrxService.loadLoanPurposes();
    this.dictionariesNgrxService.loanPurposes.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.loanPurposes = data.entities;
      this.loanPurposesIds = data.ids;
    });
    this.dictionariesNgrxService.isLoanPurposesLoaded.pipe(takeUntil(this._unsubscribeAll)).subscribe(loaded => {
      this.loading = !loaded;
      if (loaded) {
        this.groupLoanPurposeActivities(this.loanPurposes);
        if (!this.editMode) {
          this.setDefaultLoanPurpose();
        } else {
          this.prepareLoanPurposeRealEstateTypes(this.selectedLoanPurposeActivityId);
          this.setLoanPurpose();
        }
      }
    });
  }

  secondStepEnter() {
    this.dictionariesNgrxService.loadIncomeTypes();
    this.loadIncomeTypes();
    this.addOrSelectLastHousehold(this.tabs);
  }

  addOrSelectLastHousehold(tabs) {
    if (this.model.households.length === 0) {
      this.model.households.push({
        borrowers: [_.cloneDeep(BORROWER_DEFAULTS_CONSTANT)]
      });
    }
    tabs.householdTabs.selectLast();
  }

  thirdStepEnter() {
    this.dictionariesNgrxService.loadCities();
    this.dictionariesNgrxService.citiesLoading.pipe(takeUntil(this._unsubscribeAll)).subscribe(loading => {
      this.loading = loading;
    });
    this.getProvinces()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(provinces => {
        this.provinces = provinces;
      });
    this.addOrSelectLastHousehold(this.tabsUpkeepCosts);
  }

  onComplete() {
    let govProgram = {};
    if (this.showGovPrograms) {
      if (this.selectedGovProgram === 'twoPercentSafeCredit') {
        govProgram = {
          twoPercentSafeCredit: true
        };
      } else {
        govProgram = {
          guaranteedOwnContribution: true
        };
      }
    } else {
      govProgram = {
        guaranteedOwnContribution: false,
        twoPercentSafeCredit: false
      };
    }

    this.simulationNgrxService.addMortgageSimulation({
      loanPurposeId: this.selectedLoanPurposeId,
      ...this.model,
      ...govProgram
    });
    this.loading = true;
    this.loadingNewSimulation = true;
    this.simulationNgrxService.simulationId.pipe(takeUntil(this._unsubscribeAll)).subscribe(content => {
      if (!this.editMode && content) {
        this.router.navigate(['/simulations/mortgage', content], { queryParams: { clientId: this.clientId } });
      } else if (content && content !== this.simulationId) {
        this.router.navigate(['/simulations/mortgage', content], { queryParams: { clientId: this.clientId } });
      }
    });
    this.simulationNgrxService.simulationError.pipe(takeUntil(this._unsubscribeAll)).subscribe(error => {
      if (error && error.size) {
        this.getSimulationError(error.toJS());
      }
    });
  }

  restoreLoanPeriod() {
    this.loanPeriodIsMonthly = false;
    this.model.loanPeriod = 360;
    this.loanPeriodYears = this.model.loanPeriod / 12;
  }

  setDefaultLoanPurpose() {
    this.selectedLoanPurposeActivityId = this.loanPurposeActivities.find(
      loanPurposeActivity => loanPurposeActivity.isDefault
    ).id;
    this.selectedLoanPurposeRealEstateTypeId = _.find(
      this.loanPurposes,
      loanPurpose => loanPurpose.isDefault
    ).loanPurposeRealEstateType.id;
    this.setLoanPurpose();
    this.prepareLoanPurposeRealEstateTypes(this.selectedLoanPurposeActivityId);
  }

  groupLoanPurposeActivities(loanPurposes: Dictionary<LoanPurpose>) {
    this.loanPurposeActivities = _(_.values(loanPurposes))
      .groupBy(lp => lp.loanPurposeActivity.id)
      .map(group => {
        return {
          id: group[0].loanPurposeActivity.id,
          name: group[0].loanPurposeActivity.name,
          isDefault: group[0].isDefault,
          loanPurposeRealEstateTypes: group.map(property => property['loanPurposeRealEstateType']).filter(Boolean)
        };
      })
      .value();
  }

  findLoanPurpose(loanPurposeActivityId: string, loanPurposeRealEstateTypeId: string) {
    return _.findKey(this.loanPurposes, lp => {
      if (lp.loanPurposeActivity.id === loanPurposeActivityId) {
        if (
          _.isEmpty(lp.loanPurposeRealEstateType) ||
          lp.loanPurposeRealEstateType.id === loanPurposeRealEstateTypeId
        ) {
          return lp;
        }
      }
    });
  }

  prepareLoanPurposeRealEstateTypes(loanPurposeActivityId: string) {
    if (!loanPurposeActivityId) {
      return;
    }
    const activity = _.find(this.loanPurposeActivities, data => {
      return data.id === loanPurposeActivityId;
    });
    this.loanPurposeRealEstateTypes = !_.isEmpty(activity.loanPurposeRealEstateTypes)
      ? activity.loanPurposeRealEstateTypes
      : [];
  }

  setFirstLoanPurposeRealEstateType() {
    const realEstateType = _.first(this.loanPurposeRealEstateTypes);
    this.selectedLoanPurposeRealEstateTypeId = realEstateType ? realEstateType.id : null;
  }

  loanPurposeActivityOnChange() {
    this.prepareLoanPurposeRealEstateTypes(this.selectedLoanPurposeActivityId);
    this.setFirstLoanPurposeRealEstateType();
    this.setLoanPurpose();
    this.validateLoanPurpose();
  }

  validateLoanPurpose() {
    this.isLoanPurposeInvalid =
      this.showGovPrograms &&
      this.selectedGovProgram === 'twoPercentSafeCredit' &&
      (this.selectedLoanPurposeName !== 'budowa' && this.selectedLoanPurposeName !== 'zakup');
  }

  setLoanPurpose() {
    this.selectedLoanPurposeId = this.findLoanPurpose(
      this.selectedLoanPurposeActivityId,
      this.selectedLoanPurposeRealEstateTypeId
    );
    if (this.selectedLoanPurposeId) {
      this.isCollateralMarketRequired = this.loanPurposes[this.selectedLoanPurposeId].collateralMarketRequired;
      this.selectedLoanPurposeName = this.loanPurposes[this.selectedLoanPurposeId].loanPurposeActivity.name;
    }
  }
}
