import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import * as Immutable from 'immutable';
import * as Actions from '../../reducers/simulation/simulation.actions';
import { AppStore } from '../../models/app-store.model';
import { MortgageSimulation, CashSimulation, MortgageSimulationInput, CashSimulationInput } from 'exoffice-js-client';

@Injectable()
export class SimulationNgrxService {
  simulation: Observable<Immutable.Map<string, any>>;
  simulationContent: Observable<Immutable.Map<string, any>>;
  simulationContentSelectedOfferIds: Observable<Immutable.List<string>>;
  simulationLoading: Observable<boolean>;
  simulationId: Observable<string>;
  simulationError: Observable<Immutable.Map<string, any>>;

  constructor(private store: Store<AppStore>) {
    this.simulation = store.select(state => state.simulation.get('last'));
    this.simulationContent = store.select(state => state.simulation.get('last').get('content'));
    this.simulationError = store.select(state => state.simulation.get('last').get('error'));
    this.simulationLoading = store.select(state => state.simulation.get('last').get('loading'));
    this.simulationId = store.select(state => {
      return state.simulation.size &&
        state.simulation.get('last').size &&
        state.simulation.get('last').get('content') &&
        state.simulation.get('last').get('content').size
        ? state.simulation
            .get('last')
            .get('content')
            .get('id')
        : null;
    });
  }

  update(simulation: MortgageSimulation | CashSimulation): void {
    this.store.dispatch({ type: Actions.SET, payload: simulation });
  }

  unselectAllMortgageOffers(simulation: MortgageSimulation | CashSimulation): void {
    simulation.selectedOfferIds = [];
    this.updateMortgageSimulation(simulation);
  }

  unselectAllCashOffers(simulation: MortgageSimulation | CashSimulation): void {
    simulation.selectedOfferIds = [];
    this.updateCashSimulation(simulation);
  }

  removeLastSimulation() {
    this.store.dispatch({ type: Actions.REMOVE_LAST });
  }

  updateMortgageSimulation(simulation: MortgageSimulation): void {
    this.store.dispatch({ type: Actions.UPDATE_MORTGAGE_SIMULATION, payload: simulation });
  }

  updateCashSimulation(simulation: CashSimulation): void {
    return this.store.dispatch({ type: Actions.UPDATE_CASH_SIMULATION, payload: simulation });
  }

  addMortgageSimulation(simulationInput: MortgageSimulationInput): void {
    this.store.dispatch({ type: Actions.ADD_MORTGAGE_SIMULATION, payload: simulationInput });
  }

  addCashSimulation(simulationInput: CashSimulationInput): void {
    this.store.dispatch({ type: Actions.ADD_CASH_SIMULATION, payload: simulationInput });
  }

  findMortgageSimulationById(simulationId: number | string): void {
    this.store.dispatch({ type: Actions.FIND_MORTGAGE_SIMULATION_BY_ID, payload: simulationId });
  }

  findCashSimulationById(simulationId: number | string): void {
    this.store.dispatch({ type: Actions.FIND_CASH_SIMULATION_BY_ID, payload: simulationId });
  }
}
