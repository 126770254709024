import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandableColumnDirective } from './expandable-column.directive';
import { WidthUpdateDirective } from './width-update.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ExpandableColumnDirective, WidthUpdateDirective],
  exports: [ExpandableColumnDirective, WidthUpdateDirective]
})
export class ExpandableColumnModule {}
