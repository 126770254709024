import { Component, HostListener, Input, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { EditBoxService } from '../../../../../services/simulation/edit-box/edit-box.service';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-ngx';

enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: 'horizontal-scroll-arrows',
  templateUrl: './horizontal-scroll-arrows.component.html',
  styleUrls: ['./horizontal-scroll-arrows.component.scss']
})
export class HorizontalScrollArrowsComponent {
  @Input()
  scrollOne: OverlayScrollbarsComponent;
  @Input()
  scrollTwo: ElementRef['nativeElement'];

  @Input()
  numberOfOffers: number;

  constructor(private editBoxService: EditBoxService) {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.RIGHT_ARROW && !this.editBoxService.isActive()) {
      this.slide('right');
    }
    if (event.keyCode === KEY_CODE.LEFT_ARROW && !this.editBoxService.isActive()) {
      this.slide('left');
    }
  }

  slide(direction: 'left' | 'right') {
    const positions = [];
    const offerWidth = this.scrollTwo.scrollWidth / this.numberOfOffers;

    const preparePositions = () => {
      let init = 0;
      while (this.scrollTwo.scrollWidth > init + offerWidth) {
        positions.push(init);
        init += offerWidth;
      }
    };
    preparePositions();

    const findClosestLowerValue = (x: number) => _.findLast(positions, value => x >= value);
    const findClosestHigherValue = (x: number) => _.find(positions, value => x <= value);

    if (direction === 'left') {
      this.scrollTo(findClosestLowerValue(this.scrollTwo.scrollLeft - offerWidth / 3));
    } else {
      this.scrollTo(findClosestHigherValue(this.scrollTwo.scrollLeft + offerWidth / 3));
    }
  }

  scrollTo(position = 0) {
    this.scrollOne.osInstance().scroll(
      {
        x: position
      },
      500,
      { x: 'linear' }
    );
  }
}
