import { Action } from '@ngrx/store';
import { OffersFilters, OffersFiltersPayload } from './offers-filters';

export const ADD = '[OfferFilters] Add new or update filter';
export const REMOVE = '[OfferFilters] Remove filter';
export const REMOVE_ALL = '[OfferFilters] Remove all filters';
export const MERGE = '[OfferFilters] Merge filters';

export class Add implements Action {
  readonly type = ADD;

  constructor(public payload: OffersFiltersPayload) {}
}

export class Merge implements Action {
  readonly type = MERGE;

  constructor(public payload: OffersFilters) {}
}

export class Remove implements Action {
  readonly type = REMOVE;

  constructor(public key: keyof OffersFilters) {}
}

export class RemoveAll implements Action {
  readonly type = REMOVE_ALL;
}

export type All = Add | Remove | RemoveAll | Merge;
