import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import sanitizeHtml from 'sanitize-html';

@Component({
  selector: 'safe-html',
  templateUrl: './safe-html.component.html',
  styleUrls: ['./safe-html.component.scss']
})
export class SafeHtmlComponent {
  private _htmlProperty: SafeHtml;

  constructor(private _sanitizer: DomSanitizer) {}

  @Input()
  set htmlProperty(value: SafeHtml) {
    this._htmlProperty = value;
  }

  get htmlProperty(): SafeHtml {
    const sanitized = sanitizeHtml(this._htmlProperty, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'span']),
      allowedAttributes: {
        span: ['style'],
        div: ['style'],
        a: ['href', 'target'],
        img: ['src'],
        li: ['style'],
        ol: ['style']
      }
    });
    return this._sanitizer.bypassSecurityTrustHtml(sanitized);
  }
}
