import { Input, OnInit, OnDestroy } from '@angular/core';
import { MortgageSimulationInput, CashSimulationInput } from 'exoffice-js-client';
import { SimulationNgrxService } from '../../../../../services/ngrx/simulation-ngrx.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export class SaveSimulationComponent implements OnInit, OnDestroy {
  simulation: MortgageSimulationInput | CashSimulationInput;
  @Input()
  clientId: string;
  simulationSaved = false;
  public _unsubscribeAll: Subject<any> = new Subject();
  constructor(public simulationNgrxService: SimulationNgrxService) {}

  ngOnInit() {
    this.simulationNgrxService.simulationContent.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      if (data) {
        this.simulation = data.toJS() as MortgageSimulationInput | CashSimulationInput;
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
