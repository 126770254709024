import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { SectionService } from 'exoffice-js-client';
import { ServiceFactory } from '../../service-factory';

export function sectionServiceFactory(oAuthService: OAuthService, httpClient: HttpClient) {
  return new ServiceFactory(SectionService, oAuthService, httpClient).createService();
}

export let sectionServiceProvider = [
  {
    provide: SectionService,
    useFactory: sectionServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
