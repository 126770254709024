import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GraphQLService {
  constructor(readonly http: HttpClient, private oAuthService: OAuthService) {}

  getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + this.oAuthService.getAccessToken() })
    };
  }

  fetch(query: string, variables: object = {}) {
    return this.http.post(environment.basePath + '/graphql', { query, variables }, this.getHttpOptions());
  }
  mutate(query: string, variables: object = {}) {
    return this.http.post(environment.basePath + '/graphql', { query, variables }, this.getHttpOptions());
  }
}
