import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'borrower-age',
  templateUrl: './borrower-age.component.html',
  styleUrls: ['./borrower-age.component.scss']
})
export class BorrowerAgeComponent {
  @Input() age;
  @Input() index: string;
  @Output() ageChange = new EventEmitter();

  ageOnChange($event) {
    this.ageChange.emit($event);
  }
}
