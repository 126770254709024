import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SimulationNgrxService } from '../../../../../../../services/ngrx/simulation-ngrx.service';
import { ReviewInput, ReviewsService } from 'exoffice-js-client';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'modal-errors-reporting',
  templateUrl: './modal-errors-reporting.component.html',
  styleUrls: ['./modal-errors-reporting.component.scss']
})
export class ModalErrorsReportingComponent implements OnInit, OnDestroy {
  @Input()
  offer: any = undefined;

  @Input()
  topic: string;

  public _unsubscribeAll: Subject<any> = new Subject();
  private simulationId: string;
  modalRef: BsModalRef;

  constructor(
    public toastr: ToastrService,
    public simulationNgrxService: SimulationNgrxService,
    public reviewsService: ReviewsService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.simulationNgrxService.simulationId.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.simulationId = data;
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  send(data: ReviewInput, modal: BsModalRef) {
    this.reviewsService.sendReview({ simulationId: this.simulationId, ...data }).subscribe(response => {
      this.toastr.success('Dziękujemy za zgłoszenie uwagi.');
      modal.hide();
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
