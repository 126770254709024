import { Action } from '@ngrx/store';
import { ExpandableRow, ExpandableRows } from './expandable-rows';

export const SET_HEIGHT = '[Expandable rows] Update row height';
export const TOGGLE_EXPAND = '[Expandable rows] Toggle expand';
export const RESET = '[Expandable rows] Reset to initial state';

export class SetHeight implements Action {
  readonly type = SET_HEIGHT;

  constructor(public payload: { key: keyof ExpandableRows; value: ExpandableRow['height'] }) {}
}

export class ToggleExpand implements Action {
  readonly type = TOGGLE_EXPAND;

  constructor(public payload: { key: keyof ExpandableRows; value: ExpandableRow['isExpanded'] }) {}
}

export class Reset implements Action {
  readonly type = RESET;
}

export type All = SetHeight | ToggleExpand | Reset;
