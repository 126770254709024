import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CurrentAccountNgrxService } from '../../../services/ngrx/current-account-ngrx.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Account } from 'exoffice-js-client';

@Component({
  selector: 'full-width-navbar',
  templateUrl: './full-width-navbar.component.html',
  styleUrls: ['./full-width-navbar.component.scss']
})
export class FullWidthNavbarComponent implements OnInit, OnDestroy {
  account: Account;
  @Input()
  homeUrl: string;

  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(private currentAccountNgrxService: CurrentAccountNgrxService) {}

  ngOnInit(): void {
    this.currentAccountNgrxService.load();
    this.currentAccountNgrxService.account.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.account = data.toJS();
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
