import { Component, Input } from '@angular/core';

@Component({
  selector: 'loan-type-labels',
  templateUrl: './loan-type-labels.component.html',
  styleUrls: ['./loan-type-labels.component.scss']
})
export class LoanTypeLabelsComponent {
  @Input()
  homeUrl: string;

  isActive(routeName) {
    return this.homeUrl.includes(routeName);
  }
}
