import * as Actions from './vendor-product-types.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { VendorProductType } from 'exoffice-js-client';
import * as _ from 'lodash';

type Action = Actions.All;

export interface VendorProductTypeExtended extends VendorProductType {
  isHidden: boolean;
}

export const vendorProductTypeEntityAdapter = createEntityAdapter<VendorProductTypeExtended>();

export interface VendorProductsTypesEntities extends EntityState<VendorProductTypeExtended> {
  loading: boolean;
  loaded: boolean;
}

export const initialState = vendorProductTypeEntityAdapter.getInitialState({
  loading: false,
  loaded: false
});

export default function VendorProductsTypesReducer(
  state: VendorProductsTypesEntities = initialState,
  action?: Action
): any {
  switch (action.type) {
    case Actions.LOAD_VENDOR_PRODUCT_TYPES: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case Actions.LOAD_VENDOR_PRODUCT_TYPES_SUCCESS: {
      return {
        ...vendorProductTypeEntityAdapter.addAll(action.payload, state),
        loading: false,
        loaded: true
      };
    }
    case Actions.HIDE_VENDOR_PRODUCT_TYPES: {
      return vendorProductTypeEntityAdapter.map(action.entityMap, state);
    }
    case Actions.LOAD_VENDOR_PRODUCT_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    default:
      return state;
  }
}
