import { AfterContentInit, Component, ContentChildren, EventEmitter, OnInit, Output, QueryList } from '@angular/core';
import * as _ from 'lodash';
import { WizardStepComponent } from './wizard-step.component';

@Component({
  selector: 'form-wizard',
  template: `
    <div class="wizard">
      <div class="steps">
        <ul class="nav nav-justified">
          <li
            class="nav-item"
            *ngFor="let step of steps"
            [style.width]="100 / steps.length + '%'"
            [ngClass]="{
              current: step.isCurrent,
              done: !step.isCurrent,
              error: !step.isValid || step.errors.length !== 0
            }"
          >
            <a (click)="goToStep(step)">{{ step.title }}</a>
          </li>
        </ul>
      </div>
      <div class="content"><ng-content></ng-content></div>
      <div class="actions" [hidden]="isCompleted">
        <button type="button" class="btn" (click)="previous()" [disabled]="!hasPrevStep || !activeStep.showPrev">
          Poprzedni
        </button>
        <button type="button" class="btn" (click)="next()" [disabled]="!hasNextStep || !activeStep.isValid">
          Następny
        </button>
        <button type="button" class="btn" (click)="complete()" [disabled]="allStepsNotValid(steps)">Przelicz</button>
      </div>
    </div>
  `,
  styleUrls: ['./wizard.component.css']
})
export class WizardComponent implements OnInit, AfterContentInit {
  @ContentChildren(WizardStepComponent)
  wizardSteps: QueryList<WizardStepComponent>;

  private _steps: WizardStepComponent[] = [];
  private _isCompleted = false;

  @Output()
  onStepChanged: EventEmitter<WizardStepComponent> = new EventEmitter<WizardStepComponent>();

  constructor() {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.wizardSteps.forEach(step => this._steps.push(step));
    this.goToStep(_.first(this.steps));
  }

  get steps(): WizardStepComponent[] {
    return this._steps.filter(step => !step.hidden);
  }

  get isCompleted(): boolean {
    return this._isCompleted;
  }

  get activeStep(): WizardStepComponent {
    return this.steps.find(step => step.isCurrent);
  }

  set activeStep(step: WizardStepComponent) {
    if (step !== this.activeStep) {
      if (this.activeStep) {
        this.activeStep.isCurrent = false;
      }
      step.isCurrent = true;
      step.onEnter.emit();
      this.onStepChanged.emit(step);
    }
  }

  private get activeStepIndex(): number {
    return this.steps.indexOf(this.activeStep);
  }

  get hasNextStep(): boolean {
    return this.activeStepIndex < this.steps.length - 1;
  }

  get hasPrevStep(): boolean {
    return this.activeStepIndex > 0;
  }

  goToFirstStep() {
    this.goToStep(_.first(this.steps));
  }

  setAsNotCompleted() {
    this._isCompleted = false;
  }

  setAsCompleted() {
    this._isCompleted = true;
  }

  goToStep(step: WizardStepComponent) {
    this.activeStep = step;
  }

  allStepsNotValid(steps: WizardStepComponent[]) {
    return steps.filter(step => !step.isValid).length;
  }

  next() {
    if (this.hasNextStep) {
      const nextStep: WizardStepComponent = this.steps[this.activeStepIndex + 1];
      this.activeStep.onNext.emit();
      this.goToStep(nextStep);
    }
  }

  previous() {
    if (this.hasPrevStep) {
      const prevStep: WizardStepComponent = this.steps[this.activeStepIndex - 1];
      this.activeStep.onPrev.emit();
      this.goToStep(prevStep);
    }
  }

  complete() {
    this.setAsCompleted();
    this.activeStep.onComplete.emit();
  }
}
