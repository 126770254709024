import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/pluck';
import { OrderBy } from '../../../../models/order-by';
import { OfferFiltersNgrxService } from '../../../../services/ngrx/offer-filters-ngrx.service';
import {
  CashSimulationService,
  cashSimulationServiceProvider
} from '../../../../services/simulation/cash/cash-simulation.service';
import { visibilityTreeCashServiceProvider } from '../../../../services/visibility-tree/cash/visibility-tree.cash.provider';
import { VisibilityTreeService } from '../../../../services/visibility-tree/visibility-tree.service';
import { CompareOffersBaseComponent } from '../base/compare-offers-base.component';
import { ORDER_LIST_CASH } from '../components/order-by/cash/compare-offers-cash.order-list.constant';
import { SimulationNgrxService } from '../../../../services/ngrx/simulation-ngrx.service';
import { OffersNgrxService } from '../../../../services/ngrx/offers-ngrx.service';
import { takeUntil } from 'rxjs/operators';
import { cashOfferServiceProvider } from '../../../../services/providers/cash-offer-service.provider';
import { ExpandableRowNgrxService } from '../../../../services/ngrx/expandable-row-ngrx.service';
import { CashSimulation } from 'exoffice-js-client';
import * as _ from 'lodash';

@Component({
  selector: 'compare-offers-cash',
  templateUrl: './compare-offers-cash.component.html',
  styleUrls: ['../base/compare-offers-base.component.scss'],
  providers: [
    cashOfferServiceProvider,
    cashSimulationServiceProvider,
    visibilityTreeCashServiceProvider,
    OfferFiltersNgrxService
  ]
})
export class CompareOffersCashComponent extends CompareOffersBaseComponent {
  orderList: OrderBy[] = ORDER_LIST_CASH;
  constructor(
    @Inject(VisibilityTreeService) visibilityTreeService,
    @Inject(CashSimulationService) cashSimulationService,
    @Inject(OfferFiltersNgrxService) offerFiltersService,
    @Inject(ActivatedRoute) activatedRoute,
    @Inject(SimulationNgrxService) simulationNgrxService,
    @Inject(OffersNgrxService) offersNgrxService,
    @Inject(ExpandableRowNgrxService) expandableRowNgrxService
  ) {
    super(
      visibilityTreeService,
      cashSimulationService,
      offerFiltersService,
      activatedRoute,
      simulationNgrxService,
      offersNgrxService,
      params => offersNgrxService.loadCashOffers(params),
      expandableRowNgrxService
    );
    let previousEntitiesIds = [];
    this.offersEntitiesNgrxService.cashOffers.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.entities = data.entities;
      this.entitiesIds = data.ids;
      this.loading = data.loading;
      if (this.entitiesIds !== previousEntitiesIds) {
        previousEntitiesIds = this.entitiesIds;
      }
    });
  }

  updateSimulation(simulation?: CashSimulation) {
    this.loading = true;
    _.assign(this.simulation, simulation);
    this.simulationNgrxService.updateCashSimulation(this.simulation);
  }
}
