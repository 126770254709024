import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { unitOfTime } from 'moment';
import { DurationAsPipe } from '../../pipes/duration-as/duration-as.pipe';

@Directive({
  selector: '[ngModel][durationAs]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DurationAsDirective),
      multi: true
    }
  ]
})
export class DurationAsDirective implements ControlValueAccessor {
  @Input('unitOfTime') unitOfTime: unitOfTime.All;
  @Input('unitOfTimeAs') unitOfTimeAs: unitOfTime.All;
  @HostListener('blur', ['$event.target.value'])
  blur(value) {
    this.writeValue(this.modifyValue(value));
  }
  @HostListener('input', ['$event.target.value'])
  input(value) {
    this.onChange(this.modifyValue(value));
  }
  constructor(private el: ElementRef, private renderer: Renderer2, private _durationAsPipe: DurationAsPipe) {}

  onChange = (fn: any) => {};
  onTouched = () => {};

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  modifyValue(value) {
    return this._durationAsPipe.transform(value, this.unitOfTimeAs, this.unitOfTime);
  }

  writeValue(value: any): void {
    if (!_.isUndefined(value)) {
      this.renderer.setProperty(
        this.el.nativeElement,
        'value',
        this._durationAsPipe.transform(value, this.unitOfTime, this.unitOfTimeAs)
      );
    }
  }
}
