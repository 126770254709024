import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { AttributeDefinitionService } from 'exoffice-js-client';
import { ServiceFactory } from '../../service-factory';

export function attributeDefinitionServiceFactory(oAuthService: OAuthService, httpClient: HttpClient) {
  return new ServiceFactory(AttributeDefinitionService, oAuthService, httpClient).createService();
}

export let attributeDefinitionServiceProvider = [
  {
    provide: AttributeDefinitionService,
    useFactory: attributeDefinitionServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
