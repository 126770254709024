import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CashSimulationInput } from 'exoffice-js-client';
import { BORROWER_DEFAULTS_CONSTANT } from '../../../../components/simulation/borrower-defaults.constant';
import { cashSimulationServiceProvider } from '../../../../services/simulation/cash/cash-simulation.service';
import { simulationServiceProvider } from '../../../../services/simulation/simulation.service';
import { SimulationWizardBaseComponent } from '../base/simulation-wizard-base.component';

import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { DictionariesNgrxService } from '../../../../services/ngrx/dictionaries-ngrx.service';
import { SimulationNgrxService } from '../../../../services/ngrx/simulation-ngrx.service';
import { VendorsNgrxService } from '../../../../services/ngrx/vendors-ngrx.service';
import { ProductTypesNgrxService } from '../../../../services/ngrx/product-types-ngrx.service';
import { takeUntil } from 'rxjs/operators';
import { visibilityTreeCashServiceProvider } from '../../../../services/visibility-tree/cash/visibility-tree.cash.provider';
import { OfferFiltersNgrxService } from '../../../../services/ngrx/offer-filters-ngrx.service';

@Component({
  selector: 'simulation-wizard-cash',
  templateUrl: 'simulation-wizard-cash.component.html',
  styleUrls: ['simulation-wizard-cash.component.scss'],
  providers: [cashSimulationServiceProvider, simulationServiceProvider, visibilityTreeCashServiceProvider]
})
export class SimulationWizardCashComponent extends SimulationWizardBaseComponent implements OnInit {
  model: CashSimulationInput;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public toastr: ToastrService,
    public dictionariesNgrxService: DictionariesNgrxService,
    public vendorsNgrxService: VendorsNgrxService,
    public productTypesNgrxService: ProductTypesNgrxService,
    public simulationNgrxService: SimulationNgrxService,
    public offerFiltersNgrxService: OfferFiltersNgrxService
  ) {
    super(router, route, dictionariesNgrxService, toastr, vendorsNgrxService, offerFiltersNgrxService);
  }

  onComplete() {
    this.simulationNgrxService.addCashSimulation(this.model);
    this.loading = true;
    this.loadingNewSimulation = true;
    this.simulationNgrxService.simulationId.pipe(takeUntil(this._unsubscribeAll)).subscribe(content => {
      if (!this.editMode && content) {
        this.router.navigate(['/simulations/cash', content], { queryParams: { clientId: this.clientId } });
      } else if (content && content !== this.simulationId) {
        this.router.navigate(['/simulations/cash', content], { queryParams: { clientId: this.clientId } });
      }
    });
    this.simulationNgrxService.simulationError.pipe(takeUntil(this._unsubscribeAll)).subscribe(error => {
      if (error && error.size) {
        this.getSimulationError(error.toJS());
      }
    });
  }

  async setInitialModel() {
    let simulationId = null;
    if (this.editMode) {
      await this.route.params.subscribe(item => {
        simulationId = item['id'];
      });
      this.simulationNgrxService.findCashSimulationById(simulationId);
      this.simulationNgrxService.simulationContent.subscribe(data => {
        if (data) {
          this.model = data.toJS() as CashSimulationInput;
          this.monthsAreDivisibleToYears(this.model.loanPeriod);
        }
      });
    } else {
      this.simulationNgrxService.removeLastSimulation();
      this.model = {
        loanAmount: 20000,
        currency: 'PLN',
        loanPeriod: 60,
        purposeOfCrediting: 'any',
        household: {
          borrowers: []
        }
      };
      this.restoreLoanPeriod();
    }
  }

  secondStepEnter() {
    this.dictionariesNgrxService.loadIncomeTypes();
    this.loadIncomeTypes();
    if (this.model.household.borrowers.length === 0) {
      this.model.household.borrowers.push(_.cloneDeep(BORROWER_DEFAULTS_CONSTANT));
    }
    this.tabs.selectLast();
  }

  restoreLoanPeriod() {
    this.loanPeriodIsMonthly = false;
    this.model.loanPeriod = 60;
    this.loanPeriodYears = this.model.loanPeriod / 12;
  }
}
