import { Component, OnInit } from '@angular/core';
import { ProductTypesNgrxService } from '../../../../../services/ngrx/product-types-ngrx.service';
import { ProductType } from 'exoffice-js-client';
import { Dictionary } from '@ngrx/entity';

@Component({
  selector: 'knowledge-base-navigation',
  templateUrl: './knowledge-base-navigation.component.html',
  styleUrls: ['./knowledge-base-navigation.component.scss']
})
export class KnowledgeBaseNavigationComponent implements OnInit {
  entities: Dictionary<ProductType>;
  entitiesIds: string[] | number[];
  constructor(private productTypesNgrxService: ProductTypesNgrxService) {}

  ngOnInit() {
    this.productTypesNgrxService.loadProductTypes({ sorts: null, withVendorProductTypes: true });
    this.productTypesNgrxService.productTypes.subscribe(data => {
      this.entities = data.entities;
      this.entitiesIds = data.ids;
    });
  }
}
