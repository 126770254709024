import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimulationNgrxService } from '../../../../services/ngrx/simulation-ngrx.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'simulation-mortgage',
  templateUrl: 'simulation-mortgage.component.html'
})
export class SimulationMortgageComponent implements OnInit {
  loading: Observable<boolean>;

  constructor(public route: ActivatedRoute, public simulationNgrxService: SimulationNgrxService) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.simulationNgrxService.findMortgageSimulationById(params['id']);
    });
    this.loading = this.simulationNgrxService.simulationLoading;
  }
}
