import { Action } from '@ngrx/store';
import { SimulationStateInterface } from './simulation.reducer';
import { CashSimulationInput, MortgageSimulationInput } from 'exoffice-js-client';

export const SET = '[Simulation] Set';
export const SET_IN = '[Simulation] Set in';
export const UPDATE_MANY = '[Simulation] Update many';
export const REMOVE_ALL = '[Simulation] Remove all';
export const REMOVE_LAST = '[Simulation] Remove last simulation';
export const ADD_CASH_SIMULATION = '[Cash simulation - API] Add simulation';
export const ADD_MORTGAGE_SIMULATION = '[Mortgage simulation - API] Add simulation';
export const ADD_SIMULATION_FAILURE = '[Simulation - API] Add simulation error';
export const ADD_SIMULATION_SUCCESS = '[Simulation - API] Add simulation success';
export const FIND_CASH_SIMULATION_BY_ID = '[Cash simulation - API] Find simulation by id';
export const FIND_MORTGAGE_SIMULATION_BY_ID = '[Mortgage simulation - API] Find simulation by id';
export const FIND_SIMULATION_BY_ID_FAILURE = '[Simulation - API] Find simulation error';
export const FIND_SIMULATION_BY_ID_SUCCESS = '[Simulation - API] Find simulation success';
export const UPDATE_CASH_SIMULATION = '[Cash simulation - API] Update simulation';
export const UPDATE_MORTGAGE_SIMULATION = '[Mortgage simulation - API] Update simulation';
export const UPDATE_SIMULATION_FAILURE = '[Simulation - API] Update simulation error';
export const UPDATE_SIMULATION_SUCCESS = '[Simulation - API] Update simulation success';
export class Set implements Action {
  readonly type = SET;

  constructor(public payload: SimulationStateInterface) {}
}

export class SetIn implements Action {
  readonly type = SET_IN;

  constructor(public payload: { keyPath: string; content: SimulationStateInterface }) {}
}

export class UpdateMany implements Action {
  readonly type = UPDATE_MANY;

  constructor(public payload: any) {}
}

export class AddSimulationSuccess implements Action {
  readonly type = ADD_SIMULATION_SUCCESS;

  constructor(public payload: SimulationStateInterface) {}
}

export class AddSimulationFailure implements Action {
  readonly type = ADD_SIMULATION_FAILURE;

  constructor(public payload: SimulationStateInterface) {}
}

export class UpdateCashSimulation implements Action {
  readonly type = UPDATE_CASH_SIMULATION;

  constructor(public payload: CashSimulationInput) {}
}

export class UpdateMortgageSimulation implements Action {
  readonly type = UPDATE_MORTGAGE_SIMULATION;

  constructor(public payload: MortgageSimulationInput) {}
}

export class UpdateSimulationSuccess implements Action {
  readonly type = UPDATE_SIMULATION_SUCCESS;

  constructor(public payload: SimulationStateInterface) {}
}

export class UpdateSimulationFailure implements Action {
  readonly type = UPDATE_SIMULATION_FAILURE;

  constructor(public payload: SimulationStateInterface) {}
}

export class FindCashSimulationById implements Action {
  readonly type = FIND_CASH_SIMULATION_BY_ID;

  constructor(public payload: number | string) {}
}

export class FindMortgageSimulationById implements Action {
  readonly type = FIND_MORTGAGE_SIMULATION_BY_ID;

  constructor(public payload: number | string) {}
}

export class FindSimulationByIdSuccess implements Action {
  readonly type = FIND_SIMULATION_BY_ID_SUCCESS;

  constructor(public payload: SimulationStateInterface) {}
}

export class FindSimulationByIdFailure implements Action {
  readonly type = FIND_SIMULATION_BY_ID_FAILURE;

  constructor(public payload: SimulationStateInterface) {}
}

export class RemoveAll implements Action {
  readonly type = REMOVE_ALL;
}

export class RemoveLast implements Action {
  readonly type = REMOVE_LAST;
}

export class AddCashSimulation implements Action {
  readonly type = ADD_CASH_SIMULATION;
}

export class AddMortgageSimulation implements Action {
  readonly type = ADD_MORTGAGE_SIMULATION;
}

export type All =
  | Set
  | SetIn
  | UpdateMany
  | RemoveAll
  | RemoveLast
  | AddCashSimulation
  | AddSimulationSuccess
  | AddSimulationFailure
  | AddMortgageSimulation
  | UpdateCashSimulation
  | UpdateSimulationSuccess
  | UpdateSimulationFailure
  | UpdateMortgageSimulation
  | FindCashSimulationById
  | FindSimulationByIdSuccess
  | FindSimulationByIdFailure
  | FindMortgageSimulationById;
