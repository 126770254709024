import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as VendorsActions from '../../reducers/vendors/vendors.actions';
import { AppStore } from '../../models/app-store.model';
import { VendorService } from 'exoffice-js-client';

@Injectable()
export class VendorsEffects {
  // Listen for the 'LOAD_VENDORS' action
  @Effect()
  loadVendors$: Observable<Action> = this.actions$.pipe(
    ofType(VendorsActions.LOAD_VENDORS),
    withLatestFrom(this.store$),
    filter(([action, state]: [Action, AppStore]) => !state.vendors.loaded),
    mergeMap(() => {
      return this._vendorService.findVendors(100, 0, 'name ASC', true).pipe(
        map(data => ({
          type: VendorsActions.LOAD_VENDORS_SUCCESS,
          payload: data
        })),
        catchError(() =>
          of({
            type: VendorsActions.LOAD_VENDORS_FAILURE
          })
        )
      );
    })
  );

  constructor(private actions$: Actions, private _vendorService: VendorService, private store$: Store<AppStore>) {}
}
