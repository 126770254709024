import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NouisliderModule } from 'ng2-nouislider';
import { AwesomeSliderComponent } from './awesome-slider.component';

@NgModule({
  declarations: [AwesomeSliderComponent],
  imports: [BrowserModule, FormsModule, NouisliderModule],
  exports: [AwesomeSliderComponent]
})
export class AwesomeSliderModule {}
