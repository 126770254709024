import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { Observable } from 'rxjs/Rx';

@Component({
  selector: 'spin-kit',
  templateUrl: 'spin-with-block.component.html',
  styleUrls: ['spin-with-block.component.css']
})
export class SpinWithBlockComponent implements OnInit, OnDestroy {
  @Input() isRunning: boolean;
  @Input() showQuotes = false;
  @Input() fillBackground = false;

  color = '#1ab394';
  quotesArray: string[] = [
    'Uwzględnianie kwoty kredytu...',
    'Uwzględnianie łącznej wartości zabezpieczenia...',
    'Weryfikacja celu kredytowania...',
    'Weryfikacja okresu kredytowania...',
    'Przeliczanie marży...',
    'Pobieranie stawki referencyjnej...',
    'Obliczenie RRSO...',
    'Obliczanie rat...',
    'Obliczanie kosztów początkowych...',
    'Obliczanie kosztów miesięcznych...',
    'Obliczanie prowizji...',
    'Przygotowywanie opisów produktów...',
    'Przeprowadzanie obliczeń...',
    'Ubezpieczenia pomostowe...',
    'Ubezpieczenia nieruchomości...',
    'Ubezpieczenia na życie...',
    'Ubezpieczenie od utraty pracy...',
    'Ubezpieczenia od niskiego wkładu...',
    'Konta bankowe...',
    'Karty kredytowe...'
  ];
  quote: string;
  alive = true;

  ngOnInit() {
    Observable.interval(1000)
      .takeWhile(() => this.alive)
      .subscribe(() => {
        this.quote = _.first(_.shuffle(this.quotesArray));
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
