import * as Actions from './item-attributes.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ItemAttribute } from 'exoffice-js-client';
import * as _ from 'lodash';

type Action = Actions.All;
import getUuid from 'uuid-by-string';

export const itemAttributesAdapter = createEntityAdapter<ItemAttribute>({
  selectId: (model: ItemAttribute) => getUuid(model.attributeDefinitionId + model.itemId)
});

export interface ItemAttributesEntities extends EntityState<ItemAttribute> {
  loading: boolean;
  loaded: boolean;
  canDownloadReport: boolean;
}

export const initialState: ItemAttributesEntities = itemAttributesAdapter.getInitialState({
  // additional entity state properties
  loading: false,
  loaded: false,
  canDownloadReport: false
});

export default function ItemAttributesReducer(state: ItemAttributesEntities = initialState, action?: Action): any {
  switch (action.type) {
    case Actions.LOAD_ITEM_ATTRIBUTES: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }

    case Actions.LOAD_ITEM_ATTRIBUTES_SUCCESS: {
      return {
        ...itemAttributesAdapter.addAll(action.payload, state),
        loaded: true,
        loading: false
      };
    }

    case Actions.LOAD_ITEM_ATTRIBUTES_FAILURE: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }

    case Actions.UPDATE_ITEM_ATTRIBUTE: {
      return {
        ...state
      };
    }

    case Actions.UPDATE_ITEM_ATTRIBUTE_SUCCESS: {
      return itemAttributesAdapter.updateOne(
        {
          id: getUuid(action.changes.attributeDefinitionId + action.changes.itemId),
          changes: action.changes
        },
        state
      );
    }

    case Actions.UPDATE_ITEM_ATTRIBUTE_FAILURE: {
      return {
        ...state
      };
    }

    case Actions.SET_CAN_DOWNLOAD_REPORT: {
      const canDownloadReport = _.some(action.payload, link => link.rel === 'download_report');
      return {
        ...state,
        canDownloadReport
      };
    }

    default:
      return state;
  }
}
