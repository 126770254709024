import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExpandableKnowledgeRowDirective } from './expandable-knowledge-row.directive';
import { ExpandableKnowledgeRowHeadingDirective } from './expandable-knowledge-row-heading.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ExpandableKnowledgeRowDirective, ExpandableKnowledgeRowHeadingDirective],
  exports: [ExpandableKnowledgeRowDirective, ExpandableKnowledgeRowHeadingDirective]
})
export class ExpandableKnowledgeRowModule {}
