import { Component, ElementRef, OnInit } from '@angular/core';
import { SimulationBoxComponent } from '../simulation-box.component';
import { SimulationNgrxService } from '../../../../../services/ngrx/simulation-ngrx.service';
import { EditBoxService } from '../../../../../services/simulation/edit-box/edit-box.service';
import OverlayScrollbars from 'overlayscrollbars';
import * as _ from 'lodash';

@Component({
  selector: 'simulation-box-mortgage',
  templateUrl: './simulation-box-mortgage.component.html',
  styleUrls: ['../simulation-box.component.scss']
})
export class SimulationBoxMortgageComponent extends SimulationBoxComponent implements OnInit {
  showGovPrograms = false;
  selectedGovProgram = 'twoPercentSafeCredit';
  scrollBarOptions: OverlayScrollbars.Options = {
    className: 'os-theme-minimal-dark'
  };
  constructor(public _eref: ElementRef, simulationNgrxService: SimulationNgrxService, editBoxService: EditBoxService) {
    super(_eref, simulationNgrxService, editBoxService);
  }

  toNumber(value: string): number {
    return _.toNumber(value);
  }

  ngOnInit() {
    super.ngOnInit();

    if ((this.simulation as any).guaranteedOwnContribution || (this.simulation as any).twoPercentSafeCredit) {
      this.showGovPrograms = true;
      this.selectedGovProgram = (this.simulation as any).guaranteedOwnContribution
        ? 'guaranteedOwnContribution'
        : 'twoPercentSafeCredit';
    }
  }

  govProgramHasChanged() {
    if (this.showGovPrograms === false) {
      (this.simulation as any).guaranteedOwnContribution = false;
      (this.simulation as any).twoPercentSafeCredit = false;
    } else if (this.selectedGovProgram === 'guaranteedOwnContribution') {
      (this.simulation as any).guaranteedOwnContribution = true;
      (this.simulation as any).twoPercentSafeCredit = false;
    } else {
      (this.simulation as any).guaranteedOwnContribution = false;
      (this.simulation as any).twoPercentSafeCredit = true;
    }
  }

  loanAmountMin(): number {
    return (this.simulation as any).guaranteedOwnContribution
      ? Math.min((this.simulation as any).collateralValue * 0.9, 100000)
      : 0;
  }
}
