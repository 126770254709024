import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { City, CityService, MortgageHouseholdInput } from 'exoffice-js-client';
import * as _ from 'lodash';
import { CityServiceFactory } from '../../../services/providers/city/city-service.provider';
interface MortgageHousehold extends MortgageHouseholdInput {
  city?: City;
}

type ApproximationTypes = 'minimalCosts' | 'manualEstimation';

@Component({
  selector: 'upkeep-costs-form',
  styleUrls: ['./upkeep-costs-form.component.scss'],
  templateUrl: './upkeep-costs-form.component.html',
  providers: [
    {
      provide: CityService,
      useFactory: CityServiceFactory,
      deps: [OAuthService, HttpClient]
    }
  ]
})
export class UpkeepCostsComponent implements OnInit {
  @Input() household: MortgageHousehold;
  @Input() provinces: any;
  province: string = null;
  cities: any[];
  approximationType: ApproximationTypes = 'minimalCosts';
  pipsConfig: object = {
    mode: 'count',
    values: 7,
    stepped: true
  };

  setListOfCities(id) {
    this.cities = this.provinces.find(province => province.id === id).cities;
    this.household.cityId = this.cities.find(city => city.name === 'Inne').id;
  }

  setApproximationType(type: ApproximationTypes) {
    this.approximationType = type;
    if (type === 'minimalCosts') {
      this.household.livingCost = null;
    }
  }

  areBorrowersIncomesFilled(): boolean {
    const borrowers = this.household.borrowers.filter(borrower => {
      const incomes = borrower.incomes.find(income => !income.net);
      return _.isEmpty(incomes);
    });
    return borrowers.length > 0;
  }

  ngOnInit() {
    this.household.numberOfAdults = this.household.numberOfAdults || 1;
    this.household.numberOfChildren = this.household.numberOfChildren || 0;
    if (this.household.livingCost) {
      this.approximationType = 'manualEstimation';
    }
    if (this.household.city && !_.isEmpty(this.household.city.province)) {
      this.province = this.household.city.province.id;
      this.setListOfCities(this.province);
      this.household.cityId = this.household.city.id;
    }
  }
}
