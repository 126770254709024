import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { AccountService } from 'exoffice-js-client';
import { ServiceFactory } from '../service-factory';

export function accountServiceFactory(oAuthService: OAuthService, httpClient: HttpClient) {
  return new ServiceFactory(AccountService, oAuthService, httpClient).createService();
}

export let accountServiceProvider = [
  {
    provide: AccountService,
    useFactory: accountServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
