import * as Actions from './cities.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { City } from 'exoffice-js-client';

type Action = Actions.All;

export const citiesAdapter = createEntityAdapter<City>();

export interface CitiesEntities extends EntityState<City> {
  loading: boolean;
  loaded: boolean;
}

export const initialState: CitiesEntities = citiesAdapter.getInitialState({
  // additional entity state properties
  loading: false,
  loaded: false
});

export default function CitiesReducer(state: CitiesEntities = initialState, action?: Action): any {
  switch (action.type) {
    case Actions.LOAD_CITIES: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }

    case Actions.LOAD_CITIES_SUCCESS: {
      return {
        ...citiesAdapter.addAll(action.payload, state),
        loaded: true,
        loading: false
      };
    }

    case Actions.LOAD_CITIES_FAILURE: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }
    default:
      return state;
  }
}
