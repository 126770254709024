import { Routes } from '@angular/router';
import { BlankComponent } from './components/common/layouts/blank.component';
import { TopNavigationComponent } from './components/common/layouts/top-navigation.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import { ErrorHandling401Component } from './views/error-handling/401/401.component';
import { ErrorHandling403Component } from './views/error-handling/403/403.component';
import { ErrorHandling404Component } from './views/error-handling/404/404.component';
import { ErrorHandling500Component } from './views/error-handling/500/500.component';
import { SimulationWizardCashComponent } from './views/simulations/simulation-wizard/cash/simulation-wizard-cash.component';
import { SimulationWizardMortgageComponent } from './views/simulations/simulation-wizard/mortgage/simulation-wizard-mortgage.component';
import { SimulationCashComponent } from './views/simulations/simulation/cash/simulation-cash.component';
import { SimulationMortgageComponent } from './views/simulations/simulation/mortgage/simulation-mortgage.component';
import { KnowledgeViewComponent } from './views/knowledge-base/knowledge-view/knowledge-view.component';

export const ROUTES: Routes = [
  // Main redirect
  { path: '', redirectTo: '/simulations/new/mortgage', pathMatch: 'full' },

  // App views
  {
    path: '',
    component: BlankComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: '401', component: ErrorHandling401Component },
      { path: '404', component: ErrorHandling404Component },
      { path: '403', component: ErrorHandling403Component },
      { path: '500', component: ErrorHandling500Component }
    ]
  },
  {
    path: 'simulations',
    component: TopNavigationComponent,
    children: [
      { path: 'new/cash', component: SimulationWizardCashComponent },
      { path: 'new/mortgage', component: SimulationWizardMortgageComponent },
      { path: 'edit/cash/:id', component: SimulationWizardCashComponent },
      { path: 'edit/mortgage/:id', component: SimulationWizardMortgageComponent },
      { path: 'mortgage/:id', component: SimulationMortgageComponent },
      { path: 'cash/:id', component: SimulationCashComponent }
    ]
  },
  {
    path: 'knowledge',
    component: TopNavigationComponent,
    children: [{ path: ':productTypeCode', component: KnowledgeViewComponent }]
  },
  {
    path: '',
    component: TopNavigationComponent
  },

  // Handle all other routes
  { path: '**', redirectTo: '/simulations/new/mortgage', pathMatch: 'full' }
];
