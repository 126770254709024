import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ExpandableColumnsNgrxService } from '../../services/ngrx/expandable-columns-ngrx.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[widthUpdate]'
})
export class WidthUpdateDirective implements OnInit, OnDestroy {
  public _unsubscribeAll: Subject<any> = new Subject();

  @Input()
  id: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private expandableColumnsNgrxService: ExpandableColumnsNgrxService
  ) {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.expandableColumnsNgrxService
      .getItemById(this.id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        if (data && data.width) {
          this.setNativeElementWidth(data.width);
        }
      });
  }

  setNativeElementWidth(width: number): void {
    this.renderer.setStyle(this.el.nativeElement, 'width', `${width}px`);
    this.renderer.setStyle(this.el.nativeElement, 'min-width', `${width}px`);
  }
}
