import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DurationAsPipe } from './duration-as.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [DurationAsPipe],
  exports: [DurationAsPipe]
})
export class DurationAsPipeModule {}
