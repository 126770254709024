import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExpandableRowDirective } from './expandable-row.directive';
import { ExpandableRowHeadingDirective } from './expandable-row-heading.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ExpandableRowDirective, ExpandableRowHeadingDirective],
  exports: [ExpandableRowDirective, ExpandableRowHeadingDirective]
})
export class ExpandableRowModule {}
