import * as Immutable from 'immutable';
import * as OffersFiltersActions from './offers-filters.actions';
import { OffersFilters, Page } from './offers-filters';

type Action = OffersFiltersActions.All;

const page: Immutable.Record<Page> = Immutable.fromJS({ limit: 120, offset: 0 });
const offerFiltersInit: OffersFilters = {
  page,
  productVariantIncluded: null,
  params: Immutable.fromJS({
    productPromotion: null,
    productNoEarlyRepaymentFee: null,
    productInterestRateType: null,
    noProvision: null
  })
};

export const initialState = Immutable.fromJS(offerFiltersInit);

export default function OfferFiltersReducer(state: Immutable.Record<OffersFilters> = initialState, action?: Action) {
  switch (action.type) {
    case OffersFiltersActions.ADD:
      return state.set(action.payload.key, action.payload.value);

    case OffersFiltersActions.REMOVE:
      return state.remove(action.key);

    case OffersFiltersActions.MERGE:
      return state.merge(action.payload);

    case OffersFiltersActions.REMOVE_ALL:
      return state.clear();

    default:
      return state;
  }
}
