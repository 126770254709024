import { Component } from '@angular/core';

@Component({
  selector: 'jumping-dots',
  template: `
    <div id="wave"><span class="dot"></span> <span class="dot"></span> <span class="dot"></span></div>
  `,
  styleUrls: ['./jumping-dots.component.scss']
})
export class JumpingDotsComponent {}
