import { Component, Input } from '@angular/core';
import { OfferCrossSell } from 'exoffice-js-client';

@Component({
  selector: 'tooltip-content-included',
  templateUrl: './tooltip-content-included.component.html',
  styleUrls: []
})
export class TooltipContentIncludedComponent {
  @Input()
  offerCrossSell: OfferCrossSell;
}
