import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { CityService } from 'exoffice-js-client';
import { ServiceFactory } from '../../service-factory';

export function CityServiceFactory(oAuthService: OAuthService, http: HttpClient) {
  return new ServiceFactory(CityService, oAuthService, http).createService();
}

export let cityServiceProvider = [
  {
    provide: CityService,
    useFactory: CityServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
