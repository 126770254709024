import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FindByPipe } from './find-by.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FindByPipe],
  exports: [FindByPipe]
})
export class FindByPipeModule {}
