import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { ExpandableRowNgrxService } from '../../services/ngrx/expandable-row-ngrx.service';
import { ExpandableRows } from '../../reducers/expandable-rows/expandable-rows';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[expandableRow]'
})
export class ExpandableRowDirective implements AfterViewInit, OnDestroy {
  height: number;
  storedHeight: number;
  @Input()
  expandableRowId: keyof ExpandableRows;
  @Input()
  isNotExpandable = false;
  public _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private expandableRowNgrxService: ExpandableRowNgrxService
  ) {
    this.renderer.addClass(this.el.nativeElement, 'expandable-row');
    this.el.nativeElement.style.height = 'auto';
  }

  ngAfterViewInit() {
    this.height = this.el.nativeElement.offsetHeight;
    this.expandableRowNgrxService
      .getItemById(this.expandableRowId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        if (data) {
          this.storedHeight = data.get('height');
          const isExpanded = data.get('isExpanded') || this.isNotExpandable;
          this.el.nativeElement.style.height = isExpanded ? this.storedHeight + 'px' : null;
          this.el.nativeElement.style.overflow = isExpanded ? 'hidden' : null;
          if (this.storedHeight < this.height) {
            this.expandableRowNgrxService.setHeight(this.expandableRowId, this.height);
          }
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
  }
}
