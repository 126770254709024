import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DurationAsPipe } from '../../pipes/duration-as/duration-as.pipe';
import { DurationAsDirective } from './duration-as.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DurationAsDirective],
  exports: [DurationAsDirective],
  providers: [DurationAsPipe]
})
export class DurationAsModule {}
