import { Directive, ElementRef, Input, OnDestroy, Renderer2, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExpandableKnowledgeRowNgrxService } from '../../services/ngrx/expandable-knowledge-row-ngrx.service';
import { ExpandableKnowledgeRow } from '../../reducers/knowledge-base/expandable-knowledge-rows/expandable-knowledge-rows.reducer';

@Directive({
  selector: '[expandableKnowledgeRow]'
})
export class ExpandableKnowledgeRowDirective implements OnInit, OnDestroy {
  height: number;
  storedHeight: number;
  @Input()
  id: ExpandableKnowledgeRow['id'];

  public _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private expandableKnowledgeRowNgrxService: ExpandableKnowledgeRowNgrxService
  ) {
    this.renderer.addClass(this.el.nativeElement, 'expandable-row');
  }

  ngOnInit() {
    this.registerSubscriber();
  }

  registerSubscriber() {
    this.expandableKnowledgeRowNgrxService
      .getItemById(this.id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        if (data) {
          const scrollHeight = this.el.nativeElement.scrollHeight;
          this.storedHeight = data.height ? data.height : 0;
          const isExpanded = data.isExpanded;
          this.el.nativeElement.style.height = isExpanded ? this.storedHeight + 'px' : null;
          this.el.nativeElement.style.overflow = isExpanded ? 'hidden' : null;
          if (this.storedHeight < scrollHeight) {
            this.expandableKnowledgeRowNgrxService.setHeight(this.id, scrollHeight);
          }
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
  }
}
