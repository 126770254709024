// ./effects/auth.effects.ts
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import * as IncomeTypesActions from '../../reducers/income-types/income-types.actions';
import { SimulationService } from 'exoffice-js-client';
import { AppStore } from '../../models/app-store.model';

@Injectable()
export class IncomeTypesDictionaryEffects {
  // Listen for the 'LOAD_INCOME_TYPES' action
  @Effect()
  loadIncomeTypes$: Observable<Action> = this.actions$.pipe(
    ofType(IncomeTypesActions.LOAD_INCOME_TYPES),
    withLatestFrom(this.store$),
    filter(([action, state]: [Action, AppStore]) => !state.incomeTypes.loaded),
    mergeMap(() => {
      return this._simulationService.findIncomeTypes().pipe(
        map(data => ({
          type: IncomeTypesActions.LOAD_INCOME_TYPES_SUCCESS,
          payload: data
        })),
        catchError(() =>
          of({
            type: IncomeTypesActions.LOAD_INCOME_TYPES_FAILURE
          })
        )
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _simulationService: SimulationService,
    private store$: Store<AppStore>
  ) {}
}
