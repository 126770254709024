import { AfterViewChecked, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IncomeType, MortgageBorrowerInput, MortgageHouseholdInput } from 'exoffice-js-client';
import * as _ from 'lodash';
import { BORROWER_DEFAULTS_CONSTANT } from '../borrower-defaults.constant';

export class BorrowersTabsComponent implements AfterViewChecked {
  @Input() borrowers: MortgageBorrowerInput[];
  @Input() householdIndex: number;
  @Input() cantRemoveFirst = true;
  @Input() incomeTypes: IncomeType[];
  @Input() borrowersForm: NgForm;
  @Input() households: MortgageHouseholdInput[];
  @Input() household: MortgageHouseholdInput;
  @ViewChild('borrowerTabs', { static: false }) borrowerTabs;

  constructor(protected cdRef: ChangeDetectorRef) {}

  ngAfterViewChecked() {
    // explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
    this.cdRef.detectChanges();
  }

  selectLast() {
    this.borrowerTabs.selectLast();
  }

  setProfessionToDefault(professionOfPublicTrust, borrower) {
    borrower.profession = !professionOfPublicTrust ? 'other' : null;
  }

  addBorrowerTab() {
    if (this.borrowers.length < 4) {
      this.borrowers.push(_.cloneDeep(BORROWER_DEFAULTS_CONSTANT));
    }
    this.borrowerTabs.selectLast();
  }

  removeBorrowerTab($event) {
    const index = $event.index;
    this.borrowers.splice(index, 1);
    const tab = this.borrowerTabs.tabs.toArray()[index - 1] || _.last(this.borrowerTabs.tabs.toArray());
    this.borrowerTabs.selectTab(tab);
  }
}
