import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { KnowledgeViewComponent } from './knowledge-view/knowledge-view.component';
import { itemAttributeServiceProvider } from '../../services/providers/knowledge-base/item-attribute-service.provider';
import { vendorProductTypeServiceProvider } from '../../services/providers/knowledge-base/vendor-product-category-service.provider';
import { sectionServiceProvider } from '../../services/providers/knowledge-base/section-service.provider';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { SectionFilterComponent } from './components/section-filter/section-filter.component';
import { VendorFilterModalComponent } from './components/vendor-filter-modal/vendor-filter-modal.component';
import { KnowledgeBaseComponent } from './components/knowledge-base/knowledge-base.component';
import { ExpandableKnowledgeRowModule } from '../../directives/expandable-knowledge-row/expandable-knowledge-row.module';
import { SearchItemAttributesComponent } from './components/search-item-attributes/search-item-attributes.component';

import { ToggleAllSectionsComponent } from './components/toggle-all-sections/toggle-all-sections.component';
import { ExpandableColumnModule } from '../../directives/expandable-column/expandable-column.module';
import { ColumnsSizeModule } from '../../components/common/columns-size/columns-size.module';
import { ScrollTopModule } from '../../components/common/scroll-top/scroll-top.module';
import { attributeDefinitionServiceProvider } from '../../services/providers/knowledge-base/attribute-definitions-service.provider';
import { SpinWithBlockModule } from '../../components/common/spin-with-block/spin-with-block.module';
import { ModalOfferFiltersModule } from '../../components/common/modal-offer-filters/modal-offer-filters.module';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';
import { productTypeServiceProvider } from '../../services/providers/knowledge-base/product-type-service.provider';
import { SectionManagementModalComponent } from './components/section-management-modal/section-management-modal.component';
import { AttributeModalComponent } from './components/knowledge-base/attribute-modal/attribute-modal.component';
import { CommonModule } from '@angular/common';
import { GetCsvButtonComponent } from './components/get-csv-button/get-csv.button.component';
import { TooltipModule } from 'ngx-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    KnowledgeViewComponent,
    SectionFilterComponent,
    VendorFilterModalComponent,
    KnowledgeBaseComponent,
    SearchItemAttributesComponent,
    ToggleAllSectionsComponent,
    SectionManagementModalComponent,
    AttributeModalComponent,
    GetCsvButtonComponent
  ],
  imports: [
    ScrollTopModule,
    SpinWithBlockModule,
    BrowserModule,
    ExpandableKnowledgeRowModule,
    AngularMultiSelectModule,
    FormsModule,
    ExpandableColumnModule,
    ColumnsSizeModule,
    ModalOfferFiltersModule,
    OverlayscrollbarsModule,
    CommonModule,
    TooltipModule,
    FontAwesomeModule
  ],
  exports: [KnowledgeViewComponent],
  providers: [
    itemAttributeServiceProvider,
    attributeDefinitionServiceProvider,
    vendorProductTypeServiceProvider,
    productTypeServiceProvider,
    sectionServiceProvider
  ]
})
export class KnowledgeBaseModule {}
