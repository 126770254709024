export { ScrollToDirective } from './src/scroll-to.directive';

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ScrollToDirective } from './src/scroll-to.directive';

export * from './src/scroll-to.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ScrollToDirective],
  exports: [ScrollToDirective]
})
export class ScrollToModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ScrollToModule
    };
  }
}
