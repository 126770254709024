import { Action } from '@ngrx/store';
import { Vendor } from 'exoffice-js-client';

export const LOAD_VENDORS = '[Vendors - API] Load vendors';
export const LOAD_VENDORS_SUCCESS = '[Vendors - API] Load vendors success';
export const LOAD_VENDORS_FAILURE = '[Vendors - API] Load vendors failure';
export const ADD_SIMULATION_VENDORS = '[Vendors] Add vendors from simulation';

export class VendorTypes implements Action {
  readonly type = LOAD_VENDORS;
  constructor() {}
}

export class VendorTypesSuccess implements Action {
  readonly type = LOAD_VENDORS_SUCCESS;
  constructor(public payload: { content: Vendor[] }) {}
}

export class VendorTypesFailure implements Action {
  readonly type = LOAD_VENDORS_FAILURE;
  constructor() {}
}

export class AddSimulationVendors implements Action {
  readonly type = ADD_SIMULATION_VENDORS;
  constructor(public payload: { vendors: Vendor[] }) {}
}

export type All = VendorTypes | VendorTypesSuccess | VendorTypesFailure | AddSimulationVendors;
