const cashOfferQuery = `
  query cashOffers(
    $simulationId: ID!,
    $sorts: [String!],
    $selected: Boolean,
    $creditworthiness: Boolean,
    $productVendorIdIn: [ID!],
    $productProductTypeIdIn: [ID!],
    $productPromotion: Boolean,
    $productNoEarlyRepaymentFee: Boolean,
    $productInterestRateType: String,
    $noProvision: Boolean ){
  cashOffers(
    simulationId: $simulationId,
    sorts:$sorts,
    selected: $selected,
    creditworthiness: $creditworthiness,
    productVendorIdIn: $productVendorIdIn,
    productProductTypeIdIn: $productProductTypeIdIn,
    productPromotion: $productPromotion,
    productNoEarlyRepaymentFee: $productNoEarlyRepaymentFee,
    productInterestRateType: $productInterestRateType,
    noProvision: $noProvision) {
    nodes{
      nominalInterestRate,
      annualPercentageRate,
      provisionPercentage,
      available,
      currency,
      grossAmount,
      id,
      installment,
      provisionCost,
      simulationId,
      totalSum,
      links{
        rel
      },
      originId,
      product{
        name
        ltvMax
        ltvMin
        description
        provisionDescription
        tranchesSettlementDescription
        earlyLoanPaymentDescription
        realEstateAppraisalDescription
        interestRateType,
        inProgress,
        productType {
          name
        }
        selfRealEstateAppraisal
        vendor {
          name
        }
      },
    }
  }
  }
  `;

export { cashOfferQuery };
