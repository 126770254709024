import * as _ from 'lodash';
import { VisibilityTreeModel } from '../../models/visibilityTree.model';

export class VisibilityTreeService {
  constructor(private tree: VisibilityTreeModel[]) {}

  public getTree(): VisibilityTreeModel[] {
    return this.tree;
  }

  public markAllAsVisible(): void {
    const markAsVisible = tree => {
      tree.map(item => {
        if (!item.isBlocked) {
          item.isHidden = false;
        }
        if (item.children) {
          markAsVisible(item.children);
        }
      });
    };
    markAsVisible(this.tree);
  }

  public isFilterActive(): boolean {
    const isHidden = item => item.isHidden;
    return !_.isEmpty(
      _.find(this.tree, item => {
        return isHidden(item) || _.find(item.children, isHidden);
      })
    );
  }

  public markAllAsHidden(): void {
    const markAsHidden = tree => {
      tree.map(item => {
        if (!item.isBlocked) {
          item.isHidden = true;
        }
        if (item.children) {
          markAsHidden(item.children);
        }
      });
    };
    markAsHidden(this.tree);
  }

  private _findInVisibilityTree(id: string, visibilityTree?: VisibilityTreeModel[]) {
    const tree = visibilityTree || this.tree;
    let l = _.find(tree, { id });
    if (!l) {
      _.forEach(tree, child => {
        if (child.children) {
          l = _.find(child.children, { id });
        }
      });
    }
    return l;
  }

  private _isPropertyInVisibilityTree(id: string, property: string, visibilityTree?: VisibilityTreeModel[]): boolean {
    return this._findInVisibilityTree(id, visibilityTree)
      ? !_.get(this._findInVisibilityTree(id, visibilityTree), property)
      : false;
  }

  isVisible(id: string, visibilityTree?: VisibilityTreeModel[]): boolean {
    return this._isPropertyInVisibilityTree(id, 'isHidden', visibilityTree);
  }

  isDetailsVisible(id: string, visibilityTree?: VisibilityTreeModel[]): boolean {
    return this._isPropertyInVisibilityTree(id, 'isDetailsVisible', visibilityTree);
  }

  toggleItem(id: string, visibilityTree?: VisibilityTreeModel[]): void {
    const item = this._findInVisibilityTree(id, visibilityTree);
    if (_.isEmpty(item)) {
      return;
    }
    item.isHidden = item.isHidden === undefined ? true : !item.isHidden;
  }

  toggleParentAndChildren(id: string, visibilityTree?: VisibilityTreeModel[]): void {
    this.toggleItem(id, visibilityTree);
    const item = this._findInVisibilityTree(id, visibilityTree);
    if (item.children) {
      item.children.map(child => {
        child.isHidden = item.isHidden;
      });
    }
  }

  toggleDetails(id: string, visibilityTree?: VisibilityTreeModel[]): void {
    const item = this._findInVisibilityTree(id, visibilityTree);
    item.isDetailsVisible = item.isDetailsVisible === undefined ? true : !item.isDetailsVisible;
  }
}
