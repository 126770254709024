import { Attribute, Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[collateralValueValidator][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CollateralValueDirective, multi: true }]
})
export class CollateralValueDirective implements Validator {
  constructor(@Attribute('collateralValueValidator') public collateralValueValidator: string) {}

  validate(c: FormControl) {
    const collateralValue = Number(c.value);
    const guaranteedOwnContribution = c.root.value.guaranteedOwnContribution;

    const runValidation = () => {
      if (c.root.get(this.collateralValueValidator)) {
        const loanAmount = Number(c.root.get(this.collateralValueValidator).value);
        if (guaranteedOwnContribution) {
          return null;
        } else if (loanAmount / collateralValue > 0.9) {
          return {
            collateralValue: {
              valid: false
            }
          };
        } else {
          return null;
        }
      }
    };
    return runValidation();
  }
}
