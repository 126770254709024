import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Pipe({ name: 'durationAs' })
export class DurationAsPipe implements PipeTransform {
  transform(value: any, ...args: moment.unitOfTime.All[]): number {
    if (typeof args === 'undefined' || args.length !== 2) {
      throw new Error('DurationPipe: missing required time unit arguments');
    }
    return moment
      .duration(_.parseInt(value || 0), args[0] as moment.unitOfTime.DurationConstructor)
      .as(args[1] as moment.unitOfTime.DurationAs);
  }
}
