import { Component, Input, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { IncomeType, MortgageHouseholdInput } from 'exoffice-js-client';
import { BORROWER_DEFAULTS_CONSTANT } from '../borrower-defaults.constant';

import * as _ from 'lodash';

@Component({
  selector: 'households-tabs',
  styles: [
    `
      :host >>> tabs > .tabs-container .nav-tabs .add-tab-button a:focus {
        background: transparent;
      }
      .borrowers-wrapper {
        margin-bottom: -60px;
      }
    `
  ],
  template: `
    <tabs
      #householdTabs
      [left]="true"
      (addButtonClicked)="addHousehold($event)"
      (removeButtonClicked)="removeHousehold($event)"
    >
      <tab
        *ngFor="let household of households; let i = index"
        tabTitle="Gospodarstwo {{ i + 1 }}"
        [isValid]="hCtrl.valid"
      >
        <div ngModelGroup="h{{ i }}" #hCtrl="ngModelGroup">
          <borrowers-tabs-mortgage
            *ngIf="contentType === 'borrowers'"
            [borrowers]="household.borrowers"
            [households]="households"
            [household]="household"
            [householdIndex]="i"
            [incomeTypes]="incomeTypes"
          ></borrowers-tabs-mortgage>
          <upkeep-costs-form
            [household]="household"
            *ngIf="contentType === 'upkeekCosts' && provinces"
            [provinces]="provinces"
          ></upkeep-costs-form>
        </div>
      </tab>
    </tabs>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class HouseholdsTabsComponent {
  @Input() households: MortgageHouseholdInput[];
  @Input() incomeTypes: IncomeType[];
  @Input() contentType: string;
  @Input() provinces: any[];
  @ViewChild('householdTabs', { static: false }) householdTabs;

  addHousehold($event?) {
    if (this.households.length < 4) {
      this.households.push({ borrowers: [{ ..._.cloneDeep(BORROWER_DEFAULTS_CONSTANT) }] });
      this.householdTabs.selectLast();
    }
  }

  removeHousehold($event?) {
    const index = $event.index;
    this.households.splice(index, 1);
    const tab = this.householdTabs.tabs.toArray()[index - 1];
    this.householdTabs.selectTab(tab);
  }
}
