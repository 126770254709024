// ./effects/auth.effects.ts
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import * as MortgageOffersEntitiesActions from '../../../reducers/offers/mortgage-offers/mortgage-offers.actions';
import { MortgageOffersQLService } from '../../../services/graphql/offers/mortgage/mortgage-offers-ql.service';
import { MortgageOffer } from 'exoffice-js-client';

@Injectable()
export class MortgageOffersEffects {
  // Listen for the 'UPDATE_MORTGAGE_OFFER' action
  @Effect()
  updateMortgageOffer$: Observable<Action> = this.actions$.pipe(
    ofType(MortgageOffersEntitiesActions.UPDATE_MORTGAGE_OFFER),

    mergeMap((action: any) => {
      return this._mortgageOffersQLService
        .updateOffer({ id: action.payload.id, ...action.payload.offer }, action.payload.withRRSO)
        .pipe(
          // If successful, dispatch success action with result
          map(res => ({
            type: MortgageOffersEntitiesActions.UPDATE_ONE,
            id: (res as any).data.updateMortgageOffer.mortgageOffer.id,
            changes: { ...(res as any).data.updateMortgageOffer.mortgageOffer, loading: false }
          }))
        );
    })
  );

  // Listen for the 'GET_MORTGAGE_OFFERS' action
  @Effect()
  getMortgageOffers$: Observable<Action> = this.actions$.pipe(
    ofType(MortgageOffersEntitiesActions.LOAD_MORTGAGE_OFFERS),
    mergeMap((action: any) => {
      return this._mortgageOffersQLService
        .findOffersBySimulationId(
          action.payload.simulationId,
          action.payload.orderBy,
          action.payload.activeOfferFilter,
          action.payload.creditworthinessFilter,
          action.payload.productVendorIdInOfferFilter,
          action.payload.productPromotion,
          action.payload.productNoEarlyRepaymentFee,
          action.payload.productInterestRateType,
          action.payload.noProvision,
          action.payload.productVariantIncludedFilter
        )
        .pipe(
          // If successful, dispatch success action with result
          switchMap(res => [
            {
              type: MortgageOffersEntitiesActions.LOAD_MORTGAGE_OFFERS_SUCCESS,
              payload: { content: (res as any).data.mortgageOffers.nodes as MortgageOffer[] }
            },
            { type: MortgageOffersEntitiesActions.LOAD_MORTGAGE_OFFERS_RRSO, payload: action.payload }
          ]),

          // If request fails, dispatch failed action
          catchError(error => {
            return of({ type: MortgageOffersEntitiesActions.LOAD_MORTGAGE_OFFERS_FAILED });
          })
        );
    })
  );

  // Listen for the 'GET_MORTGAGE_OFFERS_RRSO' action
  @Effect()
  getMortgageOffersRRSO$: Observable<Action> = this.actions$.pipe(
    ofType(MortgageOffersEntitiesActions.LOAD_MORTGAGE_OFFERS_RRSO),
    switchMap((action: any) => {
      return this._mortgageOffersQLService
        .findOffersRRSOBySimulationId(
          action.payload.simulationId,
          action.payload.orderBy,
          action.payload.activeOfferFilter,
          action.payload.creditworthinessFilter,
          action.payload.productVendorIdInOfferFilter,
          action.payload.productPromotion,
          action.payload.productNoEarlyRepaymentFee,
          action.payload.productInterestRateType,
          action.payload.noProvision,
          action.payload.productVariantIncludedFilter
        )
        .pipe(
          // If successful, dispatch success action with result
          map(res => {
            const mortgageOffersWithRRSO = (res as any).data.mortgageOffers.nodes;
            const entityMap = item => {
              const matchedOffer = _.find(mortgageOffersWithRRSO, offer => offer.id === item.id);
              item.annualPercentageRate = _.isEmpty(matchedOffer) ? null : matchedOffer.annualPercentageRate;
              return item;
            };
            return { type: MortgageOffersEntitiesActions.MAP_OFFERS, map: entityMap };
          }),
          // If request fails, dispatch failed action
          catchError(error => {
            return of({ type: MortgageOffersEntitiesActions.LOAD_MORTGAGE_OFFERS_FAILED });
          })
        );
    })
  );

  // Listen for the 'UPDATE_MORTGAGE_OFFERS_CROSS_SELLS' action
  @Effect()
  updateMortgageOffersCrossSells$: Observable<Action> = this.actions$.pipe(
    ofType(MortgageOffersEntitiesActions.UPDATE_MORTGAGE_OFFERS_CROSS_SELLS),
    switchMap((action: any) => {
      return this._mortgageOffersQLService
        .updateOffersCrossSells(action.payload.simulationId, action.payload.selected, action.payload.offerCrossSells)
        .pipe(
          // If successful, dispatch success action with result
          map(res => {
            const updatedOffers = (res as any).data.updateMortgageOffers
              ? (res as any).data.updateMortgageOffers.updatedMortgageOffers
              : [];
            return { type: MortgageOffersEntitiesActions.UPSERT_OFFERS, payload: { offers: updatedOffers } };
          }),
          // If request fails, dispatch failed action
          catchError(error => {
            return of({ type: MortgageOffersEntitiesActions.LOAD_MORTGAGE_OFFERS_FAILED });
          })
        );
    })
  );
  constructor(private actions$: Actions, private _mortgageOffersQLService: MortgageOffersQLService) {}
}
