import { Component } from '@angular/core';
import { VisibilityTreeModel } from '../../../../../models/visibilityTree.model';
import { VisibilityTreeService } from '../../../../../services/visibility-tree/visibility-tree.service';

@Component({
  selector: 'visibility-tree',
  templateUrl: './visibility-tree.component.html',
  styleUrls: ['./visibility-tree.component.scss']
})
export class VisibilityTreeComponent {
  tree: VisibilityTreeModel[];

  constructor(private visibilityTreeService: VisibilityTreeService) {
    this.tree = visibilityTreeService.getTree();
  }

  toggleParentAndChildren(item: VisibilityTreeModel) {
    this.visibilityTreeService.toggleParentAndChildren(item.id);
  }

  markAllAsHidden() {
    this.visibilityTreeService.markAllAsHidden();
  }

  markAllAsVisible() {
    this.visibilityTreeService.markAllAsVisible();
  }

  toggleItem(item: VisibilityTreeModel) {
    this.visibilityTreeService.toggleItem(item.id);
  }
}
