import { Component, Input } from '@angular/core';
import { SaveSimulationComponent } from '../save-simulation.component';
import { MortgageSimulationInput } from 'exoffice-js-client';
import { SimulationNgrxService } from '../../../../../../services/ngrx/simulation-ngrx.service';
import * as _ from 'lodash';

@Component({
  selector: 'save-simulation-mortgage',
  templateUrl: '../save-simulation.component.html',
  styleUrls: ['../save-simulation.component.scss']
})
export class SaveSimulationMortgageComponent extends SaveSimulationComponent {
  @Input()
  simulation: MortgageSimulationInput;

  constructor(public simulationNgrxService: SimulationNgrxService) {
    super(simulationNgrxService);
  }

  updateSimulation() {
    this.simulationNgrxService.updateMortgageSimulation(_.assign({ clientId: this.clientId }, this.simulation));
    this.simulationSaved = true;
  }
}
