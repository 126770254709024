import * as _ from 'lodash';

const isFilterActive = (listOfActiveIds, entitiesIds): boolean => {
  if (listOfActiveIds && entitiesIds) {
    const intersection = _.intersection(listOfActiveIds, entitiesIds);
    return listOfActiveIds.length === 0 || !_.isEqual(intersection.sort(), _.clone(entitiesIds).sort());
  } else {
    return false;
  }
};

export default isFilterActive;
