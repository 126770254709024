import { Component, EventEmitter, Output, Input } from '@angular/core';

import * as _ from 'lodash';
import { MortgageSimulation } from 'exoffice-js-client';
import { Subject } from 'rxjs';
import InstallmentTypeEnum = MortgageSimulation.InstallmentTypeEnum;

@Component({
  selector: 'choose-installment-type',
  templateUrl: './choose-installment-type.component.html',
  styleUrls: ['./choose-installment-type.component.scss']
})
export class ChooseInstallmentTypeComponent {
  installmentTypes = _.values(InstallmentTypeEnum);
  public _unsubscribeAll: Subject<any> = new Subject();

  @Input()
  loading: boolean;

  @Input()
  installmentType: InstallmentTypeEnum;

  @Output()
  installmentTypeOnChange = new EventEmitter<InstallmentTypeEnum>();

  changeInstallmentType(installmentType: InstallmentTypeEnum) {
    this.installmentType = installmentType;
    this.installmentTypeOnChange.emit(installmentType);
  }
}
