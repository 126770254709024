import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild, Input, TemplateRef } from '@angular/core';
import { OfferFiltersNgrxService } from '../../../services/ngrx/offer-filters-ngrx.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { VendorsFilterComponent } from './partials/vendors-filter/vendors-filter.component';
import { VisibilityTreeService } from '../../../services/visibility-tree/visibility-tree.service';
import { OffersAdditionalParamsComponent } from './partials/offers-additional-params/offers-additional-params.component';
import { ProductTypesFilterComponent } from './partials/product-types-filter/product-types-filter.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'modal-offer-filters',
  templateUrl: './modal-offer-filters.component.html',
  styleUrls: ['./modal-offer-filters.component.scss']
})
export class ModalOfferFiltersComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() showProductTypeFilter = false;

  isVendorFilterActive = false;
  isOffersAdditionalParamsFilterActive = false;
  isProductTypesFilterActive = false;
  applyFilters$: Subject<Event> = new Subject();
  private _unsubscribeAll: Subject<any> = new Subject();
  @ViewChild(VendorsFilterComponent, { static: false }) vendorsFilterComponent!: VendorsFilterComponent;
  @ViewChild(ProductTypesFilterComponent, { static: false }) productTypesFilterComponent!: ProductTypesFilterComponent;
  @ViewChild(OffersAdditionalParamsComponent, { static: false })
  offersAdditionalParamsComponent!: OffersAdditionalParamsComponent;
  modalRef: BsModalRef;

  constructor(
    private offerFiltersNgrxService: OfferFiltersNgrxService,
    private visibilityTreeService: VisibilityTreeService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  applyFilters() {
    this.applyFilters$.next();
  }

  isVisibilityTreeActive(): boolean {
    return this.visibilityTreeService.isFilterActive();
  }

  countActiveFilters(): number {
    let counter = 0;
    if (this.isVisibilityTreeActive()) {
      counter += 1;
    }
    if (this.isVendorFilterActive) {
      counter += 1;
    }
    if (this.isProductTypesFilterActive) {
      counter += 1;
    }
    if (this.isOffersAdditionalParamsFilterActive) {
      counter += 1;
    }
    return counter;
  }

  ngAfterViewInit() {
    this.offerFiltersNgrxService.filters.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.isVendorFilterActive = this.vendorsFilterComponent ? this.vendorsFilterComponent.isFilterActive() : false;
    });
    this.offerFiltersNgrxService.params.pipe(takeUntil(this._unsubscribeAll)).subscribe(params => {
      this.isOffersAdditionalParamsFilterActive = this.offersAdditionalParamsComponent
        ? this.offersAdditionalParamsComponent.isFilterActive()
        : false;
    });
    if (this.showProductTypeFilter) {
      this.offerFiltersNgrxService.filters.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        this.isProductTypesFilterActive = this.productTypesFilterComponent
          ? this.productTypesFilterComponent.isFilterActive()
          : false;
      });
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
