import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { OfferFiltersNgrxService } from '../../../../../services/ngrx/offer-filters-ngrx.service';
import { Vendor } from 'exoffice-js-client';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { VendorsNgrxService } from '../../../../../services/ngrx/vendors-ngrx.service';
import { Dictionary } from '@ngrx/entity';
import { Router } from '@angular/router';
import isFilterActive from '../../helpers/is-filter-active';
import { OffersFilters } from '../../../../../reducers/offers-filters/offers-filters';

@Component({
  selector: 'vendors-filter',
  templateUrl: './vendors-filter.component.html',
  styleUrls: ['./vendors-filter.component.scss']
})
export class VendorsFilterComponent implements OnInit, OnDestroy {
  entities: Dictionary<Vendor>;
  entitiesIds: string[] | number[];
  key: keyof OffersFilters = 'productVendorIdIn';
  listOfActive: any;
  private _unsubscribeAll: Subject<any> = new Subject();
  @Input()
  applyFilters$: Subject<Event>;

  constructor(
    private offerFiltersService: OfferFiltersNgrxService,
    private vendorsNgrxService: VendorsNgrxService,
    private router: Router
  ) {}

  ngOnInit() {
    const newForm = this.router.url.includes('new');
    this.vendorsNgrxService.vendors.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.entities = data.entities;
      this.entitiesIds = data.ids;
      if (newForm && data.ids.length) {
        this.setFilter(data.ids);
      }
    });
    this.offerFiltersService.filters.subscribe(data => {
      this.listOfActive = data.get(this.key);
    });
  }

  isFilterActive(): boolean {
    return isFilterActive(this.listOfActive, this.entitiesIds);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  setFilter(listOfActive): void {
    this.offerFiltersService.add({ key: this.key, value: _.clone(listOfActive) });
  }
}
