import { Component, Input } from '@angular/core';
import { OfferCrossSell } from 'exoffice-js-client';

@Component({
  selector: 'tooltip-content-included-required',
  templateUrl: './tooltip-content-included-required.component.html',
  styleUrls: []
})
export class TooltipContentIncludedRequiredComponent {
  @Input()
  offerCrossSell: OfferCrossSell;
}
