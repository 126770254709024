import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'amount' })
export class AmountFormatPipe implements PipeTransform {
  transform(val) {
    if (val) {
      return _.toString(parseFloat(val.replace(/,/g, '.')).toFixed(2)).replace('.', ',');
    }
    return '-';
  }
}
