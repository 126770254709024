const mortgageOfferQuery = `
  query mortgageOffers(
      $simulationId: ID!,
    $sorts: [String!],
    $selected: Boolean,
    $creditworthiness: Boolean,
    $productVendorIdIn: [ID!],
$productPromotion: Boolean,
$productVariantIncluded: Boolean,
$productNoEarlyRepaymentFee: Boolean,
$productInterestRateType: String,
$noProvision: Boolean ){
  mortgageOffers(
    simulationId: $simulationId,
    sorts:$sorts,
    selected: $selected,
    creditworthiness: $creditworthiness,
    productVendorIdIn: $productVendorIdIn,
    productPromotion: $productPromotion,
    productNoEarlyRepaymentFee: $productNoEarlyRepaymentFee,
    productInterestRateType: $productInterestRateType,
    noProvision: $noProvision,
    productVariantIncluded: $productVariantIncluded) {
    pageInfo{
      hasNextPage
    }nodes{
      bgkFeeCost,
      selected,
      id,
      grossLoanAmount,
      currency,
      frozen,
      ltv,
      totalCostSum,
      firstInstallment,
      lastInstallment,
      totalSum,
      sumOfInterest,
      referenceRate,
      maxLoanAmount,
      provisionCost,
      provisionPercentage,
      preparatoryFeeCost,
      preparatoryFeeCostDescription,
      preparatoryFeePercentage,
      preparatoryFeePercentageDescription,
      realEstateAppraisal,
      installmentSchedules{
        installment,
        durationTo,
        durationFrom,
        type,
        firstInstallment,
        lastInstallment,
      },
      additionalCosts{
        initialSum,
          content{
          cost,
          creditTheInitialCost,
          totalCost,
          frequency,
          contributionsFeeFrequency,
          name,
          description
         }
      },
      nominalRateSchedules{
        margin,
        durationTo,
        durationFrom
        value
      },
    offerCrossSells{
      avgCost,
      contributionAmount,
      crossSell{
        automaticallyInclude,
        costless,
        id,
        key,
        kind,
        name
      },
      crossSellId,
      durationFrom,
      durationTo,
      impactOnMargin,
      included,
      maxCost,
      minCost,
      required,
      totalSum,
      vendorCrossSellId,
      vendorCrossSells{
        id,
        sourceOfAdditionalProduct,
        description,
        name,
        durationTo,
        durationFrom,
        variableCostsInPeriod,
        contributionAmount,
        contributionPercentage,
        contributionsFeeFrequency,
        contributionsFeeCalculationBase
      }
    },
      links{
        rel
      }
      product{
        name,
        ltvMax,
        ltvMin,
        description,
        provisionDescription,
        tranchesSettlementDescription,
        earlyLoanPaymentDescription,
        realEstateAppraisalDescription,
        selfRealEstateAppraisal,
        interestRateType,
        inProgress,
        vendorInterbankOfferRate{
          kind,
          term
        },
        productType{
          name
        },
        vendor{
          name
        }
      }
    }
  }}`;

const mortgageOfferRRSOQuery = `
  query mortgageOffers(
      $simulationId: ID!,
    $sorts: [String!],
    $selected: Boolean,
    $creditworthiness: Boolean,
    $productVendorIdIn: [ID!],
$productPromotion: Boolean,
$productVariantIncluded: Boolean,
$productNoEarlyRepaymentFee: Boolean,
$productInterestRateType: String,
$noProvision: Boolean ){
  mortgageOffers(
    simulationId: $simulationId,
    sorts:$sorts,
    selected: $selected,
    creditworthiness: $creditworthiness,
    productVendorIdIn: $productVendorIdIn,
    productPromotion: $productPromotion,
    productNoEarlyRepaymentFee: $productNoEarlyRepaymentFee,
    productInterestRateType: $productInterestRateType,
    noProvision: $noProvision,
    productVariantIncluded: $productVariantIncluded,
) {
    pageInfo{
      hasNextPage
    }nodes{
      annualPercentageRate,
      id
      }
    }
  }`;
export { mortgageOfferQuery, mortgageOfferRRSOQuery };
