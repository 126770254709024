import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
import { AppStore } from '../../models/app-store.model';
import { ProductTypesEntities } from '../../reducers/product-types/product-types.reducer';
import * as ProductTypes from '../../reducers/product-types/product-types.actions';

@Injectable()
export class ProductTypesNgrxService {
  productTypes: Observable<ProductTypesEntities>;

  constructor(private store: Store<AppStore>) {
    this.productTypes = store.select('productTypes');
  }

  loadProductTypes(params?): void {
    this.store.dispatch({ type: ProductTypes.GET_PRODUCT_TYPES, payload: params });
  }
}
