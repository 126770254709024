import * as Immutable from 'immutable';
import * as ExpandableRowsActions from './expandable-rows.actions';
import { ExpandableRows } from './expandable-rows';

type Action = ExpandableRowsActions.All;

const row = Immutable.fromJS({ height: 0 });
const expandableRowsInit: ExpandableRows = {
  productDescription: row,
  productEarlyLoanPaymentDescription: row,
  productTranchesSettlementDescription: row,
  productRealEstateAppraisalDescription: row,
  installmentSchedules: row,
  nominalInterestRate: row,
  provisionDescription: row
};

export const initialState = Immutable.fromJS(expandableRowsInit);

export default function ExpandableRowsReducer(state: Immutable.Record<ExpandableRows> = initialState, action?: Action) {
  switch (action.type) {
    case ExpandableRowsActions.SET_HEIGHT:
      return state.setIn([action.payload.key, 'height'], action.payload.value);

    case ExpandableRowsActions.TOGGLE_EXPAND:
      return state.setIn([action.payload.key, 'isExpanded'], action.payload.value);

    case ExpandableRowsActions.RESET:
      return state.merge(Immutable.fromJS(expandableRowsInit));

    default:
      return state;
  }
}
