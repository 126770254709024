import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wizard-step',
  template: `
    <div class="body" [hidden]="!isCurrent"><ng-content></ng-content></div>
  `,
  styleUrls: ['./wizard-step.component.css']
})
export class WizardStepComponent {
  @Input() title: string;
  @Input() hidden = false;
  @Input() isValid = true;
  @Input() errors: any[] = [];
  @Input() showPrev = true;

  @Output() onNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPrev: EventEmitter<any> = new EventEmitter<any>();
  @Output() onComplete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();

  private _isCurrent = false;

  @Input('isCurrent')
  set isCurrent(isCurrent: boolean) {
    this._isCurrent = isCurrent;
  }

  get isCurrent(): boolean {
    return this._isCurrent;
  }
}
