import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { OrderBy } from '../../../../../models/order-by';
import { OfferFiltersNgrxService } from '../../../../../services/ngrx/offer-filters-ngrx.service';

@Component({
  selector: 'order-by',
  templateUrl: './order-by.component.html',
  styleUrls: ['./order-by.component.scss']
})
export class OrderByComponent implements OnInit {
  @Input()
  orderList: OrderBy[];
  orderBy: OrderBy;
  orderByVendorName: boolean | null = null;

  @Input()
  loading = false;

  constructor(private offerFiltersNgrxService: OfferFiltersNgrxService) {}

  getTooltipMessage(orderByVendorName): string {
    if (orderByVendorName === null) {
      return 'Sortuj po nazwie banku rosnąco';
    } else if (orderByVendorName === false) {
      return 'Sortuj po nazwie banku malejąco';
    } else if (orderByVendorName === true) {
      return 'Wyłącz sortowanie po nazwie banku';
    }
  }

  ngOnInit() {
    this.getDefault();
    this.setOrderBy(this.orderBy);
  }

  setOrderBy(orderBy) {
    if (this.orderByVendorName === null) {
      this.offerFiltersNgrxService.add({ key: 'orderBy', value: [orderBy.value + ' ' + orderBy.direction] });
    } else {
      const productVendorNameSortingDirection = this.orderByVendorName ? ' DESC' : ' ASC';
      this.offerFiltersNgrxService.add({
        key: 'orderBy',
        value: ['product_vendor_name' + productVendorNameSortingDirection, orderBy.value + ' ' + orderBy.direction]
      });
    }
    this.orderBy = orderBy;
  }

  setOrderByVendorName(orderByVendorName) {
    if (orderByVendorName === null) {
      this.orderByVendorName = false;
    } else if (orderByVendorName === false) {
      this.orderByVendorName = true;
    } else if (orderByVendorName === true) {
      this.orderByVendorName = null;
    }
    this.setOrderBy(this.orderBy);
  }

  getFirst() {
    return (this.orderBy = _.first(this.orderList));
  }

  getDefault() {
    return (this.orderBy = this.orderList.find(x => x.isDefault));
  }
}
