import { LoanPurposesEffects } from './loan-purposes/loan-purposes.effects';
import { IncomeTypesDictionaryEffects } from './income-types/income-types.effects';
import { CitiesEffects } from './cities/cities.effects';
import { SimulationEffects } from './simulation/simulation.effects';
import { MortgageOffersEffects } from './offers/mortgage-offers/mortgage-offers.effects';
import { CurrentAccountEffects } from './current-account/current-account.effects';
import { CashOffersEffects } from './offers/cash-offers/cash-offers.effects';
import { VendorsEffects } from './vendors/vendors.effects';
import { ItemAttributesEffects } from './knowledge-base/item-attributes';
import { SectionsEffects } from './knowledge-base/sections';
import { VendorProductTypesEffects } from './knowledge-base/vendor-product-types';
import { AttributeDefinitionsEffects } from './knowledge-base/attribute-definitions';
import { ProductTypesEffects } from './knowledge-base/product-types';

export const effectsArray = [
  AttributeDefinitionsEffects,
  LoanPurposesEffects,
  IncomeTypesDictionaryEffects,
  CitiesEffects,
  SimulationEffects,
  CashOffersEffects,
  MortgageOffersEffects,
  CurrentAccountEffects,
  VendorsEffects,
  ItemAttributesEffects,
  SectionsEffects,
  VendorProductTypesEffects,
  ProductTypesEffects
];
