import { ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CashSimulation, MortgageSimulation } from 'exoffice-js-client';
import { SimulationNgrxService } from '../../../../services/ngrx/simulation-ngrx.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { EditBoxService } from '../../../../services/simulation/edit-box/edit-box.service';

export class SimulationBoxComponent implements OnInit, OnDestroy {
  simulation: MortgageSimulation | CashSimulation;
  Math = Math;
  maskOptions = {
    prefix: '',
    suffix: ' zł',
    thousands: ' ',
    decimal: ',',
    precision: '0'
  };
  public _unsubscribeAll: Subject<any> = new Subject();

  @Output()
  simulationChanged = new EventEmitter<Partial<MortgageSimulation & CashSimulation>>();

  onClick(event) {
    if (this.editBoxService.isActive() && !this._eref.nativeElement.contains(event.target)) {
      this.toggle();
    }
  }

  constructor(
    public _eref: ElementRef,
    private simulationNgrxService: SimulationNgrxService,
    public editBoxService: EditBoxService
  ) {}

  ngOnInit() {
    this.editBoxService.activateEditBox();
    this.simulationNgrxService.simulationContent.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      if (data) {
        this.simulation = data.toJS() as MortgageSimulation | CashSimulation;
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onComplete(form: NgForm) {
    Object.keys(form.controls).map(x => form.controls[x].markAsPristine());
    this.simulationChanged.emit(this.simulation);
  }

  toggle() {
    if (this.editBoxService.isActive()) {
      this.editBoxService.deactivateEditBox();
    } else {
      this.editBoxService.activateEditBox();
    }
  }
}
