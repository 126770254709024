import { HttpClient } from '@angular/common/http';
import { Inject, Optional } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import {
  BASE_PATH,
  CashSimulation,
  CashSimulationInput,
  CashSimulationService as OriginalCashSimulationService,
  Configuration
} from 'exoffice-js-client';
import { SimulationDocumentInput } from 'exoffice-js-client/model/simulationDocumentInput';
import { Observable } from 'rxjs/Rx';
import { ServiceFactory } from '../../service-factory';

export class CashSimulationService extends OriginalCashSimulationService {
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    super(httpClient, basePath, configuration);
  }
  public findSimulationById(id: string): Observable<CashSimulation> {
    return this.findCashSimulationById(id);
  }

  public addSimulation(cashSimulation: CashSimulationInput): Observable<CashSimulation> {
    return this.addCashSimulation(cashSimulation);
  }

  public updateSimulation(id: string, simulation: CashSimulationInput): Observable<CashSimulation> {
    return this.updateCashSimulation(id, simulation);
  }

  public addSimulationDocument(
    id: string,
    productVendorIdIn: string[],
    simulationDocumentInput: SimulationDocumentInput
  ) {
    return this.addCashSimulationDocument(id, productVendorIdIn, simulationDocumentInput);
  }
}

export function cashSimulationServiceFactory(oAuthService: OAuthService, http: HttpClient) {
  return new ServiceFactory(CashSimulationService, oAuthService, http).createService();
}

export let cashSimulationServiceProvider = [
  {
    provide: CashSimulationService,
    useFactory: cashSimulationServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
