import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as ItemAttributesActions from '../../reducers/knowledge-base/item-attributes/item-attributes.actions';
import { ItemAttributeService, MortgageOffer } from 'exoffice-js-client';
import * as MortgageOffersEntitiesActions from '../../reducers/offers/mortgage-offers/mortgage-offers.actions';

@Injectable()
export class ItemAttributesEffects {
  // Listen for the 'GET_ITEM_ATTRIBUTES' action
  @Effect()
  getItemAttributes$: Observable<Action> = this.actions$.pipe(
    ofType(ItemAttributesActions.GET_ITEM_ATTRIBUTES),
    mergeMap((action: ItemAttributesActions.GetItemAttributes) => {
      return this._itemAttributeService.findItemAttributes(null, action.payload.itemIdIn).pipe(
        // If successful, dispatch success action with result
        switchMap(data => [
          {
            type: ItemAttributesActions.LOAD_ITEM_ATTRIBUTES_SUCCESS,
            payload: data.content
          },
          { type: ItemAttributesActions.SET_CAN_DOWNLOAD_REPORT, payload: (data as any).links }
        ]),
        // If request fails, dispatch failed action
        catchError(data => of({ type: ItemAttributesActions.LOAD_ITEM_ATTRIBUTES_FAILURE }))
      );
    })
  );

  @Effect()
  updateItemAttribute$: Observable<Action> = this.actions$.pipe(
    ofType(ItemAttributesActions.UPDATE_ITEM_ATTRIBUTE),
    mergeMap((action: ItemAttributesActions.UpdateItemAttribute) => {
      return this._itemAttributeService.updateItemAttribute(action.id, action.itemAttribute).pipe(
        // If successful, dispatch success action with result
        map(data => ({
          type: ItemAttributesActions.UPDATE_ITEM_ATTRIBUTE_SUCCESS,
          id: action.itemAttribute.id,
          changes: action.itemAttribute
        })),
        // If request fails, dispatch failed action
        catchError(data => of({ type: ItemAttributesActions.UPDATE_ITEM_ATTRIBUTE_FAILURE }))
      );
    })
  );

  constructor(private actions$: Actions, private _itemAttributeService: ItemAttributeService) {}
}
