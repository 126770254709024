import { AfterViewInit, EventEmitter, OnDestroy, OnInit, Input, Output } from '@angular/core';
import { CashOffer, CashSimulation, MortgageSimulation, Product } from 'exoffice-js-client';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs/Rx';
import { VisibilityTreeService } from '../../../../../../services/visibility-tree/visibility-tree.service';
import * as Immutable from 'immutable';
import { ExpandedCrossSells } from '../../../../../../reducers/expanded-cross-sells/expanded-cross-sells.reducer';
import { takeUntil } from 'rxjs/operators';
import { SimulationNgrxService } from '../../../../../../services/ngrx/simulation-ngrx.service';
import { Subject } from 'rxjs';

export class OfferComponent implements AfterViewInit, OnInit, OnDestroy {
  _offer: any;
  offerIsUpdating: boolean;
  nominalRateSchedulesWithOutMarginCount: number;
  simulation: MortgageSimulation | CashSimulation;
  public _unsubscribeAll: Subject<any> = new Subject();
  @Input() onlySelected: boolean;
  @Input() expandedCrossSells: Immutable.Record<ExpandedCrossSells>;
  @Input()
  set offer(value) {
    if (value.offerCrossSells) {
      value.offerCrossSells = value.offerCrossSells.map(offerCrossSell => {
        return offerCrossSell;
      });
    }
    this._offer = value;
  }

  get offer() {
    return this._offer;
  }

  @Input() refreshOffers$: BehaviorSubject<MortgageSimulation>;
  @Output() onOfferSelected: EventEmitter<CashOffer> = new EventEmitter<CashOffer>();
  @Output() onOfferLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public visibilityTreeService: VisibilityTreeService,
    public simulationNgrxService: SimulationNgrxService
  ) {}

  ngOnInit() {
    this.simulationNgrxService.simulationContent.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      if (data) {
        this.simulation = data.toJS() as MortgageSimulation | CashSimulation;
      }
    });
    this.nominalRateSchedulesWithOutMarginCount = this.offer.nominalRateSchedules
      ? this.offer.nominalRateSchedules.filter(schedule => !schedule.margin).length
      : 0;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit() {
    this.onOfferLoaded.emit(true);
  }

  toggleDetailsVisibility(id) {
    this.visibilityTreeService.toggleDetails(id);
  }

  isVisible(id) {
    return this.visibilityTreeService.isVisible(id);
  }

  isDetailsVisible(id) {
    return this.visibilityTreeService.isDetailsVisible(id);
  }

  isLTVinRange(offer): boolean {
    return offer.ltv <= offer.product.ltvMax && offer.ltv >= offer.product.ltvMin;
  }

  warningStatus(offer): boolean {
    return !this.isLTVinRange(offer);
  }

  isEmpty(obj) {
    return _.isEmpty(obj);
  }

  isSelfRealEstateAppraisalSet(product: Product, key: string): boolean {
    return product.selfRealEstateAppraisal && key === 'Wycena nieruchomości';
  }

  canRemove(offer) {
    return offer.links.find(link => {
      return link.rel === 'destroy';
    });
  }

  canClone(offer) {
    return offer.links.find(link => {
      return link.rel === 'create';
    });
  }

  cloneOffer(cloneOfferMethod) {
    this.offerIsUpdating = true;
    cloneOfferMethod.subscribe(response => {
      this.refreshOffers$.next(response);
      this.simulation.selectedOfferIds.push(response.id);
      this.simulationNgrxService.update(this.simulation);
      this.offerIsUpdating = false;
    });
  }

  freezeOffer(cloneOfferMethod, updateOfferMethod, frozen) {
    this.offerIsUpdating = true;
    cloneOfferMethod.subscribe(response => {
      updateOfferMethod({ id: response.id, frozen }, false).subscribe(() => {
        this.refreshOffers$.next(response);
        this.simulation.selectedOfferIds.push(response.id);
        this.simulationNgrxService.update(this.simulation);
        this.offerIsUpdating = false;
      });
    });
  }

  removeOffer(removeOfferMethod, offerId: string) {
    this.offerIsUpdating = true;
    removeOfferMethod.subscribe(response => {
      this.refreshOffers$.next(response);
      _.remove(this.simulation.selectedOfferIds, n => n === offerId);
      this.simulationNgrxService.update(this.simulation);
      this.offerIsUpdating = false;
    });
  }
}
