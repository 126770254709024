import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ModalCrossSellsManagementComponent } from './modal-cross-sells-management.component';
import { FormsModule } from '@angular/forms';
import { UiSwitchModule } from '../ui-switch/ui-switch.module';

@NgModule({
  declarations: [ModalCrossSellsManagementComponent],
  imports: [BrowserModule, RouterModule, FormsModule, UiSwitchModule],
  exports: [ModalCrossSellsManagementComponent]
})
export class ModalCrossSellsManagementModule {}
