import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SectionsActions from '../../reducers/knowledge-base/sections/sections.actions';
import { SectionService } from 'exoffice-js-client';

@Injectable()
export class SectionsEffects {
  // Listen for the 'GET_SECTIONS' action
  @Effect()
  getSections$: Observable<Action> = this.actions$.pipe(
    ofType(SectionsActions.LOAD_SECTIONS),
    mergeMap((action: SectionsActions.Sections) => {
      return this._sectionService.findSections(action.productCategoryCode).pipe(
        // If successful, dispatch success action with result
        map(data => {
          return { type: SectionsActions.LOAD_SECTIONS_SUCCESS, payload: data.content };
        }),
        // If request fails, dispatch failed action
        catchError(data => of({ type: SectionsActions.LOAD_SECTIONS_FAILURE }))
      );
    })
  );

  @Effect()
  removeSection$: Observable<Action> = this.actions$.pipe(
    ofType(SectionsActions.SECTION_REMOVE),
    mergeMap((action: SectionsActions.SectionRemove) => {
      return this._sectionService.deleteSection(action.id).pipe(
        // If successful, dispatch success action with result
        map(data => {
          return { type: SectionsActions.REMOVE_SECTION_SUCCESS, payload: { id: action.id } };
        }),
        // If request fails, dispatch failed action
        catchError(data => of({ type: SectionsActions.REMOVE_SECTION_FAILURE }))
      );
    })
  );

  @Effect()
  updateSection$: Observable<Action> = this.actions$.pipe(
    ofType(SectionsActions.SECTION_UPDATE),
    mergeMap((action: SectionsActions.SectionUpdate) => {
      return this._sectionService.updateSection(action.id, action.changes).pipe(
        // If successful, dispatch success action with result
        map(data => {
          return { type: SectionsActions.UPDATE_SECTION_SUCCESS, id: action.id, changes: action.changes };
        }),
        // If request fails, dispatch failed action
        catchError(data => of({ type: SectionsActions.UPDATE_SECTION_FAILURE }))
      );
    })
  );

  constructor(private actions$: Actions, private _sectionService: SectionService) {}
}
