import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'separator' })
export class SeparatorFormatPipe implements PipeTransform {
  transform(val) {
    if (val) {
      return Number.parseFloat(val)
        .toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })
        .replace(/,/g, ' ')
        .replace('.', ',');
    }
  }
}
