import { OAuthService } from 'angular-oauth2-oidc';
import { Configuration } from 'exoffice-js-client';

export class ServiceConfiguration extends Configuration {
  _apiKeys: { [key: string]: string } = {};

  constructor(private oAuthService: OAuthService) {
    super();
  }

  get apiKeys() {
    if (this.oAuthService.getAccessToken()) {
      this._apiKeys['Authorization'] = 'Bearer ' + this.oAuthService.getAccessToken();
    }
    return this._apiKeys;
  }

  set apiKeys(value) {
    this._apiKeys = value;
  }
}
