import { Action } from '@ngrx/store';
import { VendorProductTypeExtended } from './vendor-product-types.reducer';
import { EntityMap } from '@ngrx/entity';

export const GET_VENDOR_PRODUCTS_TYPES_AND_ITEM_ATTRIBUTES = '[Get vendor products types] Get vendor products types';
export const LOAD_VENDOR_PRODUCT_TYPES = '[Vendor product category API] Load vendor products types';
export const HIDE_VENDOR_PRODUCT_TYPES = '[Vendor product category API] Hide vendor products types';

export const LOAD_VENDOR_PRODUCT_TYPES_SUCCESS = '[Vendor product category API] Load vendor products types success';
export const LOAD_VENDOR_PRODUCT_TYPES_FAILURE = '[Vendor product category API] Load vendor products types failure';

export interface GetVendorsProductsTypesPayloadType {
  sorts?: string;
  productCategoryId?: string;
  productCategoryCode?: string;
  observe?: any;
  reportProgress?: boolean;
}

export class VendorsProductsTypes implements Action {
  readonly type = LOAD_VENDOR_PRODUCT_TYPES;
  constructor() {}
}

export class VendorsProductsTypesSuccess implements Action {
  readonly type = LOAD_VENDOR_PRODUCT_TYPES_SUCCESS;
  constructor(public payload: VendorProductTypeExtended[]) {}
}

export class HideVendorsProductsTypes implements Action {
  readonly type = HIDE_VENDOR_PRODUCT_TYPES;
  constructor(public entityMap: EntityMap<VendorProductTypeExtended>) {}
}

export class VendorsProductsTypesFailure implements Action {
  readonly type = LOAD_VENDOR_PRODUCT_TYPES_FAILURE;
  constructor() {}
}

export class GetVendorsProductsTypesAndItemAttributes implements Action {
  readonly type = GET_VENDOR_PRODUCTS_TYPES_AND_ITEM_ATTRIBUTES;
  constructor(public payload: GetVendorsProductsTypesPayloadType) {}
}

export type All =
  | VendorsProductsTypes
  | VendorsProductsTypesSuccess
  | VendorsProductsTypesFailure
  | HideVendorsProductsTypes
  | GetVendorsProductsTypesAndItemAttributes;
