import { Component, OnInit } from '@angular/core';
import { KnowledgeBaseNgrxService } from '../../../../services/ngrx/knowledge-base-ngrx.service';

@Component({
  selector: 'toggle-all-sections',
  templateUrl: './toggle-all-sections.component.html',
  styleUrls: ['./toggle-all-sections.component.scss']
})
export class ToggleAllSectionsComponent implements OnInit {
  isAnySectionHidden: boolean;

  constructor(private knowledgeBaseNgrxService: KnowledgeBaseNgrxService) {}

  ngOnInit() {
    this.knowledgeBaseNgrxService.sections.subscribe(data => {
      this.isAnySectionHidden = true;
      data.ids.forEach(id => {
        if (data.entities[id].isHidden === false) {
          this.isAnySectionHidden = false;
        }
      });
    });
  }
  toggleSections() {
    this.knowledgeBaseNgrxService.toggleSections(!this.isAnySectionHidden);
  }
}
