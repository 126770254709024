import { OrderBy } from '../../../../../../models/order-by';

export const ORDER_LIST_MORTGAGE: OrderBy[] = [
  {
    label: 'Rata kredytu malejąco',
    direction: 'DESC',
    value: 'common_installment',
    isDefault: false
  },
  {
    label: 'Rata kredytu rosnąco',
    direction: 'ASC',
    value: 'common_installment',
    isDefault: true
  },
  {
    label: 'Marża malejąco',
    direction: 'DESC',
    value: 'margin',
    isDefault: false
  },
  {
    label: 'Marża rosnąco',
    direction: 'ASC',
    value: 'margin',
    isDefault: false
  },
  {
    label: 'Całkowita kwota do spłaty malejąco',
    direction: 'DESC',
    value: 'total_sum',
    isDefault: false
  },
  {
    label: 'Całkowita kwota do spłaty rosnąco',
    direction: 'ASC',
    value: 'total_sum',
    isDefault: false
  },
  {
    label: 'Koszty poczatkowe malejąco',
    direction: 'DESC',
    value: 'initial_cost_sum',
    isDefault: false
  },
  {
    label: 'Koszty poczatkowe rosnąco',
    direction: 'ASC',
    value: 'initial_cost_sum',
    isDefault: false
  }
];
