import { Component, OnDestroy, OnInit } from '@angular/core';
import { KnowledgeBaseNgrxService } from '../../../../services/ngrx/knowledge-base-ngrx.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { AttributeDefinitionsEntities } from '../../../../reducers/knowledge-base/attribute-definitions/attribute-definitions.reducer';
import { SectionsEntities } from '../../../../reducers/knowledge-base/sections/sections.reducer';

interface ItemSelect {
  id?: string;
  isVisible?: boolean;
  label?: string;
  list?: ItemSelect[];
  name?: string;
  orderNo?: number;
  sectionId?: string;
  selected?: boolean;
}

@Component({
  selector: 'section-filter',
  templateUrl: './section-filter.component.html',
  styleUrls: ['./section-filter.component.scss']
})
export class SectionFilterComponent implements OnInit, OnDestroy {
  itemList = [];
  selectedItems = [];
  itemsListLength = 0;
  sections: SectionsEntities;
  attributeDefinitions: AttributeDefinitionsEntities;
  settings = {
    singleSelection: false,
    text: 'Aktywny',
    labelKey: 'label',
    selectAllText: 'Zaznacz wszystkie',
    searchAutofocus: true,
    enableFilterSelectAll: false,
    noDataLabel: 'Brak sekcji',
    searchBy: ['label'],
    filterSelectAllText: 'Zaznacz wszystkie przefiltrowane pola',
    filterUnSelectAllText: 'Odznacz wszystkie przefiltrowane pola',
    unSelectAllText: 'Odznacz wszystkie',
    searchPlaceholderText: 'Wyszukaj',
    enableSearchFilter: true,
    badgeShowLimit: 1,
    groupBy: 'name'
  };
  public _unsubscribeAll: Subject<any> = new Subject();

  constructor(private knowledgeBaseNgrxService: KnowledgeBaseNgrxService) {}

  ngOnInit() {
    let isInitialized = false;
    const combined$ = this.knowledgeBaseNgrxService.attributeDefinitions
      .combineLatest(this.knowledgeBaseNgrxService.sections)
      .subscribe(([attributeDefinitions, sections]) => {
        if (attributeDefinitions.loaded && sections.loaded) {
          this.itemList = _.map(attributeDefinitions.ids, attrDefId => ({
            ...attributeDefinitions.entities[attrDefId],
            name: sections.entities[attributeDefinitions.entities[attrDefId].sectionId].name,
            orderNo: sections.entities[attributeDefinitions.entities[attrDefId].sectionId].orderNo
          }));
          this.itemList = _.orderBy(this.itemList, 'orderNo');
          if (!isInitialized) {
            this.selectedItems = [...this.itemList];
            this.itemsListLength = this.itemList.length;
            isInitialized = true;
          }
        }
      });
    this.knowledgeBaseNgrxService.attributeDefinitions.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.attributeDefinitions = data;
    });
    this.knowledgeBaseNgrxService.sections.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.sections = data;
    });
  }

  onItemSelect(item: ItemSelect) {
    this.attrVisibilityUpdate(item.id, (item.isVisible = true));
    this.makeSectionNotHidden(this.itemList.find(i => i.id === item.id).sectionId);
  }
  onItemDeSelect(item: ItemSelect) {
    this.attrVisibilityUpdate(item.id, (item.isVisible = false));
  }

  onGroupSelect(item: ItemSelect) {
    if (item.list) {
      const sectionId = item.list[0].sectionId;
      this.sectionVisibilityUpdate(sectionId, item.selected);
      this.makeSectionNotHidden(sectionId);
    }
  }

  onSelectAll(items: ItemSelect[]) {
    this.allSectionsVisibilityUpdate(true);
  }

  onDeSelectAll(items: ItemSelect[]) {
    this.allSectionsVisibilityUpdate(false);
  }

  makeSectionNotHidden(sectionId: string) {
    this.knowledgeBaseNgrxService.setSectionNoApi(sectionId, { isHidden: false });
  }

  attrVisibilityUpdate(attrId: number | string, visibility: boolean) {
    this.knowledgeBaseNgrxService.setAttrbuteDefinitionVisibility(attrId, visibility);
    // check if section has no visible attributes
    const sectionId = this.attributeDefinitions.entities[attrId].sectionId;
    const filterFn = id =>
      this.attributeDefinitions.entities[id].isVisible &&
      this.attributeDefinitions.entities[id].sectionId === sectionId;

    if (!visibility && _.isEmpty(_.filter(this.attributeDefinitions.ids, filterFn))) {
      this.knowledgeBaseNgrxService.setSectionAndAttributesVisibility(sectionId, visibility);
    }

    // checking if section is visible when has visible attributes
    if (visibility && !this.sections.entities[sectionId].isVisible) {
      this.knowledgeBaseNgrxService.setSectionVisibility(sectionId, visibility);
    }
  }

  sectionVisibilityUpdate(sectionId: number | string, visibility: boolean, idList?: string[]) {
    this.knowledgeBaseNgrxService.setSectionAndAttributesVisibility(sectionId, visibility);
  }

  allSectionsVisibilityUpdate(visibility: boolean) {
    this.knowledgeBaseNgrxService.setAllSectionVisibility(visibility);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
