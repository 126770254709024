import { CommonModule, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RateFormatPipe } from './rate-format.pipe';
@NgModule({
  imports: [CommonModule],
  declarations: [RateFormatPipe],
  exports: [RateFormatPipe],
  providers: [PercentPipe]
})
export class RateFormatPipeModule {}
