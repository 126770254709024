import { Component, ViewEncapsulation } from '@angular/core';
import {
  CashOfferService,
  cashOfferServiceProvider
} from '../../../../../../services/providers/cash-offer-service.provider';
import { VisibilityTreeService } from '../../../../../../services/visibility-tree/visibility-tree.service';
import { OfferComponent } from '../base/offer.component';
import { OffersNgrxService } from '../../../../../../services/ngrx/offers-ngrx.service';
import { SimulationNgrxService } from '../../../../../../services/ngrx/simulation-ngrx.service';

@Component({
  selector: 'cash-offer',
  templateUrl: '../base/offer.component.html',
  styleUrls: ['../base/offer.component.scss'],
  providers: [cashOfferServiceProvider],
  encapsulation: ViewEncapsulation.None
})
export class CashOfferComponent extends OfferComponent {
  showCreditworthiness = false;

  constructor(
    public offerApi: CashOfferService,
    public visibilityTreeService: VisibilityTreeService,
    public offersNgrxService: OffersNgrxService,
    public simulationNgrxService: SimulationNgrxService
  ) {
    super(visibilityTreeService, simulationNgrxService);
  }

  cloneOffer(offer) {
    this.updateOffer({ id: offer.id, originId: offer.id });
  }

  removeOffer(offer) {
    super.removeOffer(this.offerApi.deleteCashOffer(offer.id), offer.id);
  }

  updateOffer(offer) {
    this.offersNgrxService.updateCashOffer(offer.id, offer);
  }

  selectOffer(offer) {
    offer.selected = !offer.selected;
    this.onOfferSelected.emit(offer);
    this.updateOffer({ id: offer.id, selected: offer.selected });
  }

  warningStatus(offer): boolean {
    return false;
  }
}
