import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'description-modal-content',
  templateUrl: './description-modal-content.component.html',
  styleUrls: ['./description-modal-content.component.scss']
})
export class DescriptionModalContentComponent {
  @Input()
  description: string;
  @Input()
  title: string;
  constructor(public modalRef: BsModalRef) {}
}
