import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'snakeToCamel' })
export class SnakeToCamelPipe implements PipeTransform {
  transform(val) {
    if (val) {
      return _.camelCase(val);
    }
  }
}
