import { Directive } from '@angular/core';
import { FormGroup, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[generalInformationValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: GeneralInformationValidator, multi: true }]
})
export class GeneralInformationValidator implements Validator {
  loanAmountSubscriberWasSet = false;
  guaranteedOwnContributionSubscriberWasSet = false;
  constructor() {}

  validate(group: FormGroup) {
    const collateralValue = group.controls['collateralValue'];
    const loanAmount = group.controls['loanAmount'];
    const loanPeriod = group.controls['loanPeriod'];
    const guaranteedOwnContribution = group.controls['guaranteedOwnContribution'];
    const loanPurposeHelper = group.controls['loanPurposeHelper'];
    const updateCollateralValueValidity = (isLoanAmountTriggered = false) => {
      if (isLoanAmountTriggered) {
        this.loanAmountSubscriberWasSet = true;
      } else {
        this.guaranteedOwnContributionSubscriberWasSet = true;
      }
      collateralValue.updateValueAndValidity();
    };

    if (!this.guaranteedOwnContributionSubscriberWasSet && guaranteedOwnContribution && collateralValue) {
      guaranteedOwnContribution.valueChanges.subscribe(() => {
        updateCollateralValueValidity();
      });
    }

    if (!this.loanAmountSubscriberWasSet && loanAmount && collateralValue) {
      loanAmount.valueChanges.subscribe(() => {
        updateCollateralValueValidity(true);
      });
    }
    if (
      loanAmount &&
      loanAmount.valid &&
      collateralValue &&
      loanPeriod &&
      loanPurposeHelper &&
      loanPurposeHelper.valid &&
      (loanAmount.valid || loanAmount.pristine) &&
      (collateralValue.valid || collateralValue.pristine) &&
      (loanPeriod.valid || loanPeriod.pristine)
    ) {
      return null;
    } else {
      return {
        generalInformation: {
          valid: false
        }
      };
    }
  }
}
