import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import {
  CashOffer,
  CashOfferInput,
  CashOfferList,
  CashOfferService as OriginalCashOfferService
} from 'exoffice-js-client';
import { Observable } from 'rxjs/Observable';
import { ServiceFactory } from '../service-factory';

export class CashOfferService extends OriginalCashOfferService {
  public findOffersBySimulationId(
    simulationId: string,
    limit?: number,
    offset?: number,
    sorts?: string[],
    selected?: boolean,
    creditworthiness?,
    fields?: string[],
    productVendorIdIn?: string[],
    productProductTypeIdIn?: string[],
    productPromotion?: boolean,
    productNoEarlyRepaymentFee?: boolean,
    productInterestRateType?: string,
    noProvision?: boolean
  ): Observable<CashOfferList> {
    return this.findOffersByCashSimulationId(
      simulationId,
      limit,
      offset,
      sorts,
      selected,
      fields,
      productVendorIdIn,
      productProductTypeIdIn,
      productPromotion,
      productNoEarlyRepaymentFee,
      productInterestRateType,
      noProvision
    );
  }

  public updateOffer(id: string, cashOffer: CashOfferInput): Observable<CashOffer> {
    return this.updateCashOffer(id, cashOffer);
  }
}

export function cashOfferServiceFactory(oAuthService: OAuthService, http: HttpClient) {
  return new ServiceFactory(CashOfferService, oAuthService, http).createService();
}

export let cashOfferServiceProvider = [
  {
    provide: CashOfferService,
    useFactory: cashOfferServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
