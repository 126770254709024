import { Action } from '@ngrx/store';
import { MortgageOffer } from 'exoffice-js-client';
import { EntityMap } from '@ngrx/entity';

export const ADD_ALL = '[Mortgage offers] Add all';
export const ADD_ONE = '[Mortgage offers] Add One';
export const GET_ONE = '[Mortgage offers] Get One';
export const UPDATE_MORTGAGE_OFFER = '[Mortgage offers API] Update mortgage offer';
export const UPDATE_ONE = '[Mortgage offers] Update One';
export const DELETE_ONE = '[Mortgage offers] Delete One';
export const REMOVE_ALL = '[Mortgage offers] Remove all';
export const GET_ALL = '[Mortgage offers] Get All';
export const UPSERT_OFFERS = '[Mortgage offers] Upsert offers';
export const LOAD_MORTGAGE_OFFERS = '[Mortgage offers - API] Load mortgage offers';
export const UPDATE_MORTGAGE_OFFERS_CROSS_SELLS = '[Mortgage offers - API] Update mortgage offers cross sells';
export const LOAD_MORTGAGE_OFFERS_RRSO = '[Mortgage offers - API] Load mortgage offers rrso';
export const LOAD_MORTGAGE_OFFERS_SUCCESS = '[Mortgage offers - API] Load mortgage offers success';
export const LOAD_MORTGAGE_OFFERS_FAILED = '[Mortgage offers - API] Load mortgage offers failed';
export const MAP_OFFERS = '[Mortgage offers] Update using map';

export class LoadMortgageOffers implements Action {
  readonly type = LOAD_MORTGAGE_OFFERS;

  constructor(public payload: any) {}
}

export class LoadMortgageOffersRRSO implements Action {
  readonly type = LOAD_MORTGAGE_OFFERS_RRSO;

  constructor(public payload: any) {}
}

export class UpdateMortgageOffersCrossSells implements Action {
  readonly type = UPDATE_MORTGAGE_OFFERS_CROSS_SELLS;

  constructor(public payload: any) {}
}

export class LoadOffersSuccess implements Action {
  readonly type = LOAD_MORTGAGE_OFFERS_SUCCESS;

  constructor(public payload: { content: MortgageOffer[] }) {}
}

export class LoadOffersFailed implements Action {
  readonly type = LOAD_MORTGAGE_OFFERS_FAILED;

  constructor() {}
}

export class AddAll implements Action {
  readonly type = ADD_ALL;

  constructor(public payload: { offers: MortgageOffer[] }) {}
}

export class AddOne implements Action {
  readonly type = ADD_ONE;
  constructor(public offer: MortgageOffer) {}
}

export class GetOne implements Action {
  readonly type = GET_ONE;
  constructor(public id: string) {}
}
export class UpdateMortgageOffer implements Action {
  readonly type = UPDATE_MORTGAGE_OFFER;
  constructor(public id: string, public changes: Partial<MortgageOffer>) {}
}
export class UpdateOne implements Action {
  readonly type = UPDATE_ONE;
  constructor(public id: string, public changes: Partial<MortgageOffer>) {}
}
export class DeleteOne implements Action {
  readonly type = DELETE_ONE;
  constructor(public id: number) {}
}
export class RemoveAll implements Action {
  readonly type = REMOVE_ALL;
  constructor() {}
}
export class GetAll implements Action {
  readonly type = GET_ALL;
  constructor(public offers: MortgageOffer[]) {}
}

export class UpsertOffers implements Action {
  readonly type = UPSERT_OFFERS;

  constructor(public payload: { offers: MortgageOffer[] }) {}
}

export class MapOffers implements Action {
  readonly type = MAP_OFFERS;

  constructor(public map: EntityMap<MortgageOffer>) {}
}

export type All =
  | LoadMortgageOffers
  | LoadMortgageOffersRRSO
  | LoadOffersSuccess
  | LoadOffersFailed
  | UpdateMortgageOffersCrossSells
  | AddAll
  | AddOne
  | GetOne
  | UpdateMortgageOffer
  | UpdateOne
  | DeleteOne
  | RemoveAll
  | GetAll
  | UpsertOffers
  | MapOffers;
