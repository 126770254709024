import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Dictionary } from '../../../../../models/dictionary.model';
import { VisibilityTreeModel } from '../../../../../models/visibilityTree.model';
import { VisibilityTreeService } from '../../../../../services/visibility-tree/visibility-tree.service';
import { TOTAL_SUM_COSTS } from '../../constants/total-sum-costs';
import { SimulationNgrxService } from '../../../../../services/ngrx/simulation-ngrx.service';
import { CashSimulation, MortgageSimulation, MortgageOffer } from 'exoffice-js-client';
import { Router } from '@angular/router';
import { CrossSellService } from '../../../../../services/simulation/cross-sell/cross-sell.service';
import { ExpandedCrossSellsNgrxService } from '../../../../../services/ngrx/expanded-cross-sells-ngrx.service';
import * as Immutable from 'immutable';
import { ExpandedCrossSells } from '../../../../../reducers/expanded-cross-sells/expanded-cross-sells.reducer';
import { Subject } from 'rxjs';
import { INITIAL_COSTS } from '../../constants/initial-costs';
import { MONTHLY_COSTS } from '../../constants/monthly-costs';

@Component({
  selector: 'compare-offers-heading-column',
  templateUrl: './heading-column.component.html',
  styleUrls: ['./heading-column.component.scss']
})
export class HeadingColumnComponent implements OnInit, OnDestroy {
  visibilityTree: VisibilityTreeModel[];
  simulation: MortgageSimulation | CashSimulation;
  initialCosts: Dictionary[] = INITIAL_COSTS;
  totalSumCosts: Dictionary[] = TOTAL_SUM_COSTS;
  monthlySumCosts: Dictionary[] = MONTHLY_COSTS;
  @Input() offersIds: number[] | string[];
  private expandedCrossSells: Immutable.Record<ExpandedCrossSells>;
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private visibilityTreeService: VisibilityTreeService,
    private simulationNgrxService: SimulationNgrxService,
    private crossSellService: CrossSellService,
    private router: Router,
    private expandedCrossSellsNgrxService: ExpandedCrossSellsNgrxService
  ) {
    this.visibilityTree = this.visibilityTreeService.getTree();
  }

  ngOnInit() {
    this.simulationNgrxService.simulationContent.takeUntil(this._unsubscribeAll).subscribe(data => {
      if (data) {
        this.simulation = data.toJS() as MortgageSimulation | CashSimulation;
        if (!(this.simulation as MortgageSimulation).guaranteedOwnContribution) {
          this.visibilityTreeService.toggleItem('offer.bgkFeeCost');
        }
      }
    });
    this.expandedCrossSellsNgrxService.expandedCrossSells.takeUntil(this._unsubscribeAll).subscribe(data => {
      this.expandedCrossSells = data;
    });
  }

  isVisible(id: string): boolean {
    return this.visibilityTreeService.isVisible(id);
  }

  isDetailsVisible(id: string): boolean {
    return this.visibilityTreeService.isDetailsVisible(id);
  }

  toggleNames(): void {
    this.visibilityTreeService.toggleDetails('product.vendor.name');
    this.visibilityTreeService.toggleItem('product.name');
  }

  unselectAllOffers(simulation: MortgageSimulation | CashSimulation): void {
    if (this.router.url.includes('mortgage')) {
      this.simulationNgrxService.unselectAllMortgageOffers(simulation);
    } else {
      this.simulationNgrxService.unselectAllCashOffers(simulation);
    }
  }

  toggleDetailsVisibility(id: string): void {
    this.visibilityTreeService.toggleDetails(id);
  }

  debtBalanceCost(offer: MortgageOffer, name: string) {
    this.crossSellService.findContributionsFeeCalculationBase(offer, name, 'debt_balance');
  }

  debtBalanceCostInRow(name: string): boolean {
    return this.crossSellService.debtBalanceCostInRow(name, this.expandedCrossSells);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
