import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { OffersAdditionalParams } from '../../../../../reducers/offers-filters/offers-filters';
import { OfferFiltersNgrxService } from '../../../../../services/ngrx/offer-filters-ngrx.service';
import { takeUntil } from 'rxjs/operators';
import * as Immutable from 'immutable';

@Component({
  selector: 'offers-additional-params',
  templateUrl: './offers-additional-params.component.html',
  styleUrls: ['./offers-additional-params.component.scss']
})
export class OffersAdditionalParamsComponent implements OnInit, OnDestroy {
  @Input()
  applyFilters$: Subject<Event>;
  offersType: string;
  model: OffersAdditionalParams;
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(private offerFiltersNgrxService: OfferFiltersNgrxService, private router: Router) {}

  ngOnInit() {
    this.router.url.includes('cash') ? (this.offersType = 'cash') : (this.offersType = 'mortgage');
    this.setInitialValues();

    this.applyFilters$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.applyFilter();
    });
    this.offerFiltersNgrxService.params.pipe(takeUntil(this._unsubscribeAll)).subscribe(params => {
      this.model = params ? params.toJS() : {};
    });
  }

  setInitialValues() {
    const isNew: boolean = this.router.url.includes('new');
    const model = {
      productNoEarlyRepaymentFee: null,
      noProvision: null,
      productInterestRateType: null,
      productPromotion: null
    };
    if (isNew) {
      if (this.offersType === 'cash') {
        this.offerFiltersNgrxService.merge(
          Immutable.fromJS({ params: { productNoEarlyRepaymentFee: null, ...model } })
        );
      } else {
        this.offerFiltersNgrxService.add({
          key: 'params',
          value: Immutable.fromJS({ productInterestRateType: null, ...model })
        });
      }
    }
  }

  isFilterActive(): boolean {
    return _.includes(_.values(this.model), true || 'fixed');
  }

  applyFilter(): void {
    this.model = _.mapValues(this.model, value => {
      return value === false ? null : value;
    });
    if (this.offersType === 'cash') {
      this.model.productNoEarlyRepaymentFee = null;
    } else {
      this.model.productInterestRateType = null;
    }
    this.offerFiltersNgrxService.add({ key: 'params', value: Immutable.fromJS(this.model) });
  }

  toggleCheckbox(key): void {
    this.model[key] = this.model[key] ? null : true;
  }

  toggleProductInterestRateType(): void {
    this.model.productInterestRateType === null
      ? (this.model.productInterestRateType = 'fixed')
      : (this.model.productInterestRateType = null);
  }

  showAll(): void {
    this.model = _.mapValues(this.model, value => {
      value = true;
      return value;
    });
    this.model.productInterestRateType = 'fixed';
  }

  hideAll(): void {
    this.model = _.mapValues(this.model, value => {
      value = null;
      return value;
    });
    this.model.productInterestRateType = null;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
