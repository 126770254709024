import * as Actions from './loan-purposes.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { LoanPurpose } from '../../models/loan-purpose.model';

type Action = Actions.All;

export const loanPurposesAdapter = createEntityAdapter<LoanPurpose>();

export interface LoanPurposesEntities extends EntityState<LoanPurpose> {
  loading: boolean;
  loaded: boolean;
}

export const initialState: LoanPurposesEntities = loanPurposesAdapter.getInitialState({
  // additional entity state properties
  loading: false,
  loaded: false
});

export default function LoanPurposesReducer(state: LoanPurposesEntities = initialState, action?: Action): any {
  switch (action.type) {
    case Actions.LOAD_LOAN_PURPOSES: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }

    case Actions.LOAD_LOAN_PURPOSES_SUCCESS: {
      return {
        ...loanPurposesAdapter.addAll(action.payload, state),
        loaded: true,
        loading: false
      };
    }

    case Actions.LOAD_LOAN_PURPOSES_FAILURE: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }
    default:
      return state;
  }
}
