import { Action } from '@ngrx/store';
import { AttributeDefinition } from './attribute-definitions.reducer';
import { EntityMap } from '@ngrx/entity';

export const GET_ATTRIBUTE_DEFINITIONS = '[Get Attribute definitions - API] Get attribute definitions';
export const LOAD_ATTRIBUTE_DEFINITIONS = '[Attribute definitions - API] Load attribute definitions';
export const LOAD_ATTRIBUTE_DEFINITIONS_SUCCESS = '[Attribute definitions - API] Load attribute definitions success';
export const LOAD_ATTRIBUTE_DEFINITIONS_FAILURE = '[Attribute definitions - API] Load attribute definitions failure';
export const UPDATE_ONE = '[Attribute definitions] update one';
export const MAP = '[Attribute definitions] map';

export class UpdateOneAttributeDefinitions implements Action {
  readonly type = UPDATE_ONE;
  constructor(public id: number, public changes: Partial<AttributeDefinition>) {}
}

export class GetAttributeDefinitions implements Action {
  readonly type = GET_ATTRIBUTE_DEFINITIONS;
  constructor(public productCategoryCode: string) {}
}

export class LoadAttributeDefinitions implements Action {
  readonly type = LOAD_ATTRIBUTE_DEFINITIONS;
  constructor() {}
}

export class LoadAttributeDefinitionsSuccess implements Action {
  readonly type = LOAD_ATTRIBUTE_DEFINITIONS_SUCCESS;
  constructor(public payload: AttributeDefinition[]) {}
}

export class LoadAttributeDefinitionsFailure implements Action {
  readonly type = LOAD_ATTRIBUTE_DEFINITIONS_FAILURE;
  constructor() {}
}

export class MapAttributeDefinitions implements Action {
  readonly type = MAP;
  constructor(public entityMap: EntityMap<AttributeDefinition>) {}
}

export type All =
  | GetAttributeDefinitions
  | LoadAttributeDefinitions
  | LoadAttributeDefinitionsSuccess
  | LoadAttributeDefinitionsFailure
  | UpdateOneAttributeDefinitions
  | MapAttributeDefinitions;
