import * as Immutable from 'immutable';
import * as SimulationActions from './simulation.actions';
import { MortgageSimulation, CashSimulation } from 'exoffice-js-client';

type Action = SimulationActions.All;

export interface SimulationStateInterface {
  last: {
    content: MortgageSimulation | CashSimulation;
    error: {
      status: number;
      error: any;
    };
    loading: boolean;
  };
}

const simulationInit = {
  last: {}
};

export const initialState = Immutable.fromJS(simulationInit);

export default function SimulationReducer(
  state: Immutable.Map<string, Partial<SimulationStateInterface>> = initialState,
  action?: Action
): any {
  switch (action.type) {
    case SimulationActions.SET:
      return state.set('last', Immutable.fromJS({ content: action.payload, error: {} }));

    case SimulationActions.SET_IN:
      return state.setIn(action.payload.keyPath, Immutable.fromJS({ content: action.payload.content, error: {} }));

    case SimulationActions.ADD_CASH_SIMULATION:
      return state.setIn(['last', 'loading'], true);

    case SimulationActions.ADD_MORTGAGE_SIMULATION:
      return state.setIn(['last', 'loading'], true);

    case SimulationActions.ADD_SIMULATION_SUCCESS:
      return state.set('last', Immutable.fromJS({ content: action.payload, error: {}, loading: false }));

    case SimulationActions.ADD_SIMULATION_FAILURE: {
      return state.set('last', Immutable.fromJS({ content: {}, error: { ...action.payload }, loading: false }));
    }

    case SimulationActions.FIND_CASH_SIMULATION_BY_ID:
      return state.setIn(['last', 'loading'], true);

    case SimulationActions.FIND_MORTGAGE_SIMULATION_BY_ID:
      return state.setIn(['last', 'loading'], true);

    case SimulationActions.FIND_SIMULATION_BY_ID_SUCCESS:
      return state.set('last', Immutable.fromJS({ content: action.payload, error: {}, loading: false }));

    case SimulationActions.FIND_SIMULATION_BY_ID_FAILURE: {
      return state.set('last', Immutable.fromJS({ content: {}, error: { ...action.payload }, loading: false }));
    }

    case SimulationActions.UPDATE_SIMULATION_SUCCESS:
      return state.set('last', Immutable.fromJS({ content: action.payload, error: {} }));

    case SimulationActions.UPDATE_SIMULATION_FAILURE: {
      return state.set('last', Immutable.fromJS({ content: {}, error: { ...action.payload } }));
    }

    case SimulationActions.REMOVE_LAST:
      return state.set('last', Immutable.fromJS({}));

    case SimulationActions.REMOVE_ALL:
      return state.clear();

    default:
      return state;
  }
}
