import { Component, Input } from '@angular/core';

@Component({
  selector: 'offers-counter',
  templateUrl: './offers-counter.component.html',
  styleUrls: ['./offers-counter.component.scss']
})
export class OffersCounterComponent {
  @Input() offersIds: string[] | number[];
}
