import { Action } from '@ngrx/store';
import { LoanPurpose } from '../../models/loan-purpose.model';

export const LOAD_LOAN_PURPOSES = '[Loan purposes - API] Load loan purposes';
export const LOAD_LOAN_PURPOSES_SUCCESS = '[Loan purposes - API] Load loan purposes success';
export const LOAD_LOAN_PURPOSES_FAILURE = '[Loan purposes - API] Load loan purposes failure';

export class LoanPurposeTypes implements Action {
  readonly type = LOAD_LOAN_PURPOSES;
  constructor() {}
}

export class LoanPurposeTypesSuccess implements Action {
  readonly type = LOAD_LOAN_PURPOSES_SUCCESS;
  constructor(public payload: LoanPurpose[]) {}
}

export class LoanPurposeTypesFailure implements Action {
  readonly type = LOAD_LOAN_PURPOSES_FAILURE;
  constructor() {}
}

export type All = LoanPurposeTypes | LoanPurposeTypesSuccess | LoanPurposeTypesFailure;
