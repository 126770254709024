import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AccountService } from 'exoffice-js-client';

import * as CurrentAccountActions from '../../reducers/current-account/current-account.actions';

@Injectable()
export class CurrentAccountEffects {
  // Listen for the 'LOAD_CURRENT_ACCOUNT' action
  @Effect()
  login$: Observable<Action> = this.actions$.pipe(
    ofType(CurrentAccountActions.LOAD_CURRENT_ACCOUNT),
    mergeMap(() =>
      this._accountApi.getCurrentAccount().pipe(
        // If successful, dispatch success action with result
        map(data => ({ type: CurrentAccountActions.LOAD_CURRENT_ACCOUNT_SUCCESS, payload: data })),
        // If request fails, dispatch failed action
        catchError(() => of({ type: CurrentAccountActions.LOAD_CURRENT_ACCOUNT_FAILURE }))
      )
    )
  );

  constructor(private actions$: Actions, private _accountApi: AccountService) {}
}
