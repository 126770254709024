import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { ServiceConfiguration } from './service-configuration';

export class ServiceFactory {
  constructor(private ctor: any, private oAuthService: OAuthService, private http: HttpClient) {}

  createService() {
    const configuration = new ServiceConfiguration(this.oAuthService);
    return new this.ctor(this.http, environment.basePath, configuration);
  }
}
