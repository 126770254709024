import * as Actions from './income-types.actions';
import { IncomeType } from 'exoffice-js-client';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

type Action = Actions.All;

export const incomeTypesAdapter = createEntityAdapter<IncomeType>();

export interface IncomeTypesEntities extends EntityState<IncomeType> {
  loading: boolean;
  loaded: boolean;
}

export const initialState: IncomeTypesEntities = incomeTypesAdapter.getInitialState({
  // additional entity state properties
  loading: false,
  loaded: false
});

export default function IncomeTypesReducer(state: IncomeTypesEntities = initialState, action?: Action): any {
  switch (action.type) {
    case Actions.LOAD_INCOME_TYPES: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }

    case Actions.LOAD_INCOME_TYPES_SUCCESS: {
      return {
        ...incomeTypesAdapter.addAll(action.payload, state),
        loaded: true,
        loading: false
      };
    }

    case Actions.LOAD_INCOME_TYPES_FAILURE: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }
    default:
      return state;
  }
}
