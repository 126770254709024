import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { BorrowerIncomeInput, IncomeType, MortgageBorrowerInput, MortgageHouseholdInput } from 'exoffice-js-client';
import * as _ from 'lodash';
import { BORROWER_INCOME_CONSTANT } from '../../borrower-defaults.constant';
import { takeUntil } from 'rxjs/operators';
import { DictionariesNgrxService } from '../../../../services/ngrx/dictionaries-ngrx.service';
import { Subject } from 'rxjs';
import { Dictionary } from '@ngrx/entity';

@Component({
  selector: 'borrower-income',
  templateUrl: './borrower-income.component.html',
  styleUrls: ['./borrower-income.component.scss']
})
export class BorrowerIncomeComponent implements OnInit, OnDestroy {
  @Input() income: BorrowerIncomeInput;
  incomeTypes: Dictionary<IncomeType>;
  incomeTypesIds: string[] | number[];
  @Input() borrower: MortgageBorrowerInput;
  @Input() borrowerIndex: number;
  @Input() borrowers: MortgageBorrowerInput[];
  @Input() household: MortgageHouseholdInput;
  @Input() householdIndex: number;
  @Output() onCreditworthinessNetIncomeValidation = new EventEmitter<boolean>();
  selectedIncome: BorrowerIncomeInput;
  selectedIncomeIndex: number;
  public _unsubscribeAll: Subject<any> = new Subject();

  incomePipsConfig: object = {
    mode: 'count',
    values: 7,
    density: 4,
    stepped: true
  };

  constructor(private dictionariesNgrxService: DictionariesNgrxService) {}

  ngOnInit() {
    this.selectedIncome = _.first(this.borrower.incomes);
    this.selectedIncomeIndex = 0;
    this.dictionariesNgrxService.incomeTypes.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.incomeTypes = data.entities;
      this.incomeTypesIds = data.ids;
    });
  }

  addBorrowerIncome(borrower: MortgageBorrowerInput) {
    this.selectedIncomeIndex = borrower.incomes.length;
    borrower.incomes.push(_.clone(BORROWER_INCOME_CONSTANT));
    this.selectedIncome = _.last(borrower.incomes);
  }

  removeIncome(index) {
    this.borrower.incomes.splice(index, 1);
    this.selectIncome(_.last(this.borrower.incomes), this.borrower.incomes.length - 1);
  }

  selectIncome(income, index) {
    this.selectedIncome = income;
    this.selectedIncomeIndex = index;
  }

  getIncomeTypeLabel(incomeTypeId) {
    const selectedIncomeType = _.find(this.incomeTypes, incomeType => {
      return incomeType.id === incomeTypeId;
    });
    return selectedIncomeType ? selectedIncomeType.label : 'Źródło dochodu';
  }

  hasSelectedIncomeTypeFinitePeriod(selectedIncomeTypeId) {
    const match = _.find(this.incomeTypes, { id: selectedIncomeTypeId });
    if (match) {
      return !match.indefinitePeriod;
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  hasAllMonthlyCreditLiabilitiesFilled(): boolean {
    const allMonthlyCreditLiabilitiesFilled = _.filter(this.borrowers, value => value.monthlyCreditLiabilities);
    return this.borrowers.length === allMonthlyCreditLiabilitiesFilled.length;
  }

  hasAtLeastOneNetVal(): boolean {
    const allIncomes = _.flatMap(this.borrowers, value => [...value.incomes]);
    return _.filter(allIncomes, value => value.net).length > 0;
  }

  hasCreditworthinessNetIncomeValidation(): boolean {
    const flag =
      this.hasAtLeastOneNetVal() &&
      ((this.household ? this.household.cityId === undefined : true) || !this.hasAllMonthlyCreditLiabilitiesFilled());
    this.onCreditworthinessNetIncomeValidation.emit(flag);
    return flag;
  }
}
