import { OfferCrossSell, MortgageOffer } from 'exoffice-js-client';
import { Injectable } from '@angular/core';
import * as Immutable from 'immutable';
import { ExpandedCrossSells } from '../../../reducers/expanded-cross-sells/expanded-cross-sells.reducer';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CrossSellService {
  findOfferCrossSell(offer: MortgageOffer, name: string): OfferCrossSell {
    return offer.offerCrossSells.find(offerCrossSell => offerCrossSell.crossSell.name === name);
  }

  findContributionsFeeCalculationBase(
    offer: MortgageOffer,
    name: string,
    contributionsFeeCalculationBase: string
  ): boolean {
    const offerCrossSell: OfferCrossSell = this.findOfferCrossSell(offer, name);
    if (offerCrossSell && offerCrossSell.included) {
      const vendorCrossSell = offerCrossSell.vendorCrossSells.find(vcs => offerCrossSell.vendorCrossSellId === vcs.id);
      return vendorCrossSell && vendorCrossSell.contributionsFeeCalculationBase === contributionsFeeCalculationBase;
    } else {
      return false;
    }
  }

  debtBalanceCostInRow(name: string, expandedCrossSells: Immutable.Record<ExpandedCrossSells>): boolean {
    const crossSellKey = _.findKey(expandedCrossSells.toJS(), o => {
      return o.key === name;
    });
    return expandedCrossSells.getIn([crossSellKey, 'value']);
  }
}
