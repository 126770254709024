import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import * as Immutable from 'immutable';
import * as Actions from '../../reducers/current-account/current-account.actions';
import { Account } from 'exoffice-js-client';
import { AppStore } from '../../models/app-store.model';

@Injectable()
export class CurrentAccountNgrxService {
  account: Observable<Immutable.Record<Account>>;

  constructor(private store: Store<AppStore>) {
    this.account = store.select('currentAccount');
  }

  load(): void {
    this.store.dispatch({ type: Actions.LOAD_CURRENT_ACCOUNT });
  }

  update(payload: Account): void {
    this.store.dispatch({
      type: Actions.LOAD_CURRENT_ACCOUNT_SUCCESS,
      payload
    });
  }
}
