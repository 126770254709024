import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { LoanTypeLabelsComponent } from './components/loan-type-labels/loan-type-labels.component';
import { NavTitleComponent } from './components/nav-title/nav-title.component';
import { FullWidthNavbarComponent } from './full-width-navbar.component';
import { ModalErrorsReportingModule } from '../../../views/simulations/compare-offers/components/offer/components/modal-errors-reporting/modal-errors-reporting.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { KnowledgeBaseNavigationComponent } from './components/knowledge-base-navigation/knowledge-base-navigation.component';
import { NavLogoComponent } from './components/nav-logo/nav-logo.component';
import { TooltipModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [
    FullWidthNavbarComponent,
    KnowledgeBaseNavigationComponent,
    LoanTypeLabelsComponent,
    NavTitleComponent,
    KnowledgeBaseNavigationComponent,
    NavLogoComponent
  ],
  imports: [BsDropdownModule.forRoot(), BrowserModule, RouterModule, TooltipModule, ModalErrorsReportingModule],
  exports: [FullWidthNavbarComponent]
})
export class FullWidthNavbarModule {}
