import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { ItemAttribute } from 'exoffice-js-client';
import * as _ from 'lodash';
import { KnowledgeBaseNgrxService } from '../../../../../services/ngrx/knowledge-base-ngrx.service';
@Component({
  selector: 'attribute-modal',
  templateUrl: 'attribute-modal.component.html'
})
export class AttributeModalComponent implements OnInit {
  attribute: ItemAttribute;
  attributeCopy: ItemAttribute;

  title: string;
  editMode = false;

  constructor(public modalRef: BsModalRef, public knowledgeBaseNgrxService: KnowledgeBaseNgrxService) {}

  ngOnInit() {
    this.attributeCopy = _.cloneDeep(this.attribute);
  }

  revert() {
    this.attribute = _.cloneDeep(this.attributeCopy);
    this.toggleEditMode();
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  hasAuth(): boolean {
    return _.some((this.attribute as any).links, link => link.rel === 'update');
  }

  save(itemAttribute: ItemAttribute): void {
    this.knowledgeBaseNgrxService.itemAttributeUpdate(itemAttribute);
    this.modalRef.hide();
  }
}
