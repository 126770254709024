import { VisibilityTreeModel } from '../../../models/visibilityTree.model';
import { VisibilityTreeService } from '../visibility-tree.service';

const MORTGAGE_TREE: VisibilityTreeModel[] = [
  {
    name: 'Nazwa banku',
    id: 'product.vendor.name',
    isDetailsVisible: true,
    isBlocked: true
  },
  {
    name: 'Nazwa produktu',
    id: 'product.name',
    isBlocked: true
  },
  {
    name: 'Kwota kredytu brutto/LTV',
    id: 'offer.grossLoanAmount',
    isBlocked: true
  },
  {
    name: 'Raty kredytu',
    id: 'installmentSchedules',
    isBlocked: true
  },
  {
    name: 'Całkowita kwota do spłaty',
    id: 'offer.totalSum',
    isBlocked: true
  },
  {
    name: 'Koszty początkowe',
    id: 'offer.initialCost',
    isBlocked: true
  },
  {
    name: 'Koszty miesięczne',
    id: 'offer.totalMonthlyCostsMortgage',
    isBlocked: true
  },
  {
    name: 'Suma kosztów kwartalnych',
    id: 'offer.quarterlySumCost',
    isHidden: true
  },
  {
    name: 'Suma kosztów półrocznych',
    id: 'offer.semiannuallySumCost',
    isHidden: true
  },
  {
    name: 'Suma kosztów rocznych',
    id: 'offer.annuallySumCost',
    isBlocked: true
  },
  {
    name: 'Suma kosztów całkowitych',
    id: 'offer.totalCostSum',
    isBlocked: true
  },
  {
    name: 'Oprocentowanie stałe',
    id: 'nominalRateSchedulesWithoutMargin',
    isBlocked: true
  },
  {
    name: 'Marża',
    id: 'nominalRateSchedulesWithMargin',
    isBlocked: true
  },
  {
    name: 'Typ stawki referencyjnej',
    id: 'product.vendorInterbankOfferRate',
    isBlocked: true
  },
  {
    name: 'Oprocentowanie nominalne',
    id: 'nominalRateSchedules',
    isBlocked: true
  },
  {
    name: 'RRSO',
    id: 'rrso',
    isBlocked: true
  },
  {
    name: 'Wskaźnik BGK średniego kwartalnego oprocentowania',
    id: 'simulation.bgkAverageInterestRate',
    isBlocked: true
  },
  {
    name: 'Produkty dodatkowe',
    id: 'crossSells',
    isBlocked: true,
    children: [
      {
        name: 'Ubezpieczenie pomostowe',
        id: 'crossSell.bridgingInsurance',
        isBlocked: true
      },
      {
        name: 'Ubezpieczenie nieruchomości',
        id: 'crossSell.realEstateInsurance',
        isBlocked: true
      },
      {
        name: 'Ubezpieczenie na życie',
        id: 'crossSell.lifeInsurance',
        isBlocked: true
      },
      {
        name: 'Ubezpieczenie od utraty pracy',
        id: 'crossSell.unemploymentInsurance',
        isHidden: true
      },
      {
        name: 'Ubezpieczenia niskiego wkładu',
        id: 'crossSell.lowContributionInsurance',
        isHidden: true
      },
      {
        name: 'Wariant produktu',
        id: 'crossSell.productVariant',
        isBlocked: true
      },
      {
        name: 'Konto bankowe',
        id: 'crossSell.account',
        isBlocked: true
      },
      {
        name: 'Karta kredytowa',
        id: 'crossSell.creditCard',
        isBlocked: true
      }
    ]
  },
  {
    name: 'Maksymalna zdolność kredytowa',
    id: 'offer.maxLoanAmount',
    isHidden: true
  },
  {
    name: 'Typ oprocentowania',
    id: 'product.interestRateType',
    isHidden: true
  },
  {
    name: 'Prowizja',
    id: 'offer.provision',
    isHidden: false
  },
  {
    name: 'Wycena nieruchomości',
    id: 'offer.realEstateAppraisal',
    isBlocked: true
  },
  {
    name: 'Opłata BGK (niekredytowana)',
    id: 'offer.bgkFeeCost',
    isBlocked: true
  },
  {
    name: 'Opis do wyceny nieruchomości',
    id: 'product.realEstateAppraisalDescription',
    isHidden: true
  },
  {
    name: 'Opis wcześniejszej spłaty kredytu',
    id: 'product.earlyLoanPaymentDescription',
    isBlocked: true
  },
  {
    name: 'Opis rozliczenia transz',
    id: 'product.tranchesSettlementDescription',
    isHidden: true
  },
  {
    name: 'Opis prowizji',
    id: 'product.provisionDescription',
    isHidden: true
  },
  {
    name: 'Opis produktu',
    id: 'product.description',
    isBlocked: true
  }
];

export function visibilityTreeMortgageServiceFactory() {
  return new VisibilityTreeService(MORTGAGE_TREE);
}

export let visibilityTreeMortgageServiceProvider = [
  {
    provide: VisibilityTreeService,
    useFactory: visibilityTreeMortgageServiceFactory
  }
];
