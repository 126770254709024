import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandling401Component } from './401/401.component';
import { ErrorHandling403Component } from './403/403.component';
import { ErrorHandling404Component } from './404/404.component';
import { ErrorHandling500Component } from './500/500.component';

@NgModule({
  declarations: [
    ErrorHandling401Component,
    ErrorHandling403Component,
    ErrorHandling404Component,
    ErrorHandling500Component
  ],
  imports: [BrowserModule]
})
export class ErrorHandlingModule {}
