import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'top-navigation',
  templateUrl: 'top-navigation.html'
})
export class TopNavigationComponent implements AfterViewInit, OnDestroy {
  bodyElementClassList = document.getElementsByTagName('body').item(0).classList;
  homeLink: string;

  constructor(private route: Router) {
    this.route.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.homeLink = this.getHomeLink(event.url);
      }
    });
  }

  getHomeLink(routeUrl) {
    if (routeUrl.indexOf('mortgage') > -1) {
      return '/simulations/new/mortgage';
    }
    if (routeUrl.indexOf('cash') > -1) {
      return '/simulations/new/cash';
    }
    return '';
  }

  ngAfterViewInit() {
    this.bodyElementClassList.add('gray-bg');
    this.bodyElementClassList.add('top-navigation');
  }

  ngOnDestroy() {
    this.bodyElementClassList.remove('gray-bg');
    this.bodyElementClassList.remove('top-navigation');
  }
}
