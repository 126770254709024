import { Component, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { KnowledgeBaseNgrxService, SearchItemAttributes } from '../../../../services/ngrx/knowledge-base-ngrx.service';

@Component({
  selector: 'search-item-attributes',
  templateUrl: './search-item-attributes.component.html',
  styleUrls: ['./search-item-attributes.component.scss']
})
export class SearchItemAttributesComponent implements OnInit {
  phrase: string;
  @Output()
  phraseUpdate$ = new Subject<string>();
  @Output()
  searchItemAttributes$ = new Subject<SearchItemAttributes>();

  constructor(private knowledgeBaseNgrxService: KnowledgeBaseNgrxService) {}

  ngOnInit() {
    this.phraseUpdate$
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .flatMap(data => {
        return this.knowledgeBaseNgrxService.searchItemAttributes(data);
      })
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.searchItemAttributes$.next(value);
      });
  }

  clearSearch() {
    this.phrase = '';
    this.searchItemAttributes$.next({
      attributeDefinitionAndItemIds: [],
      itemIds: [],
      attributeDefinitionIds: [],
      attributeDefinitionLabelIds: []
    });
  }
}
