import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MortgageBorrowerInput, MortgageHouseholdInput } from 'exoffice-js-client';
import { BorrowersTabsComponent } from '../borrowers-tabs.component';

@Component({
  selector: 'borrowers-tabs-mortgage',
  styleUrls: ['borrowers-tabs-mortgage.component.scss'],
  templateUrl: 'borrower-tabs-mortgage.component.html'
})
export class BorrowersTabsMortgageComponent extends BorrowersTabsComponent {
  @Input() borrowers: MortgageBorrowerInput[];
  @Input() households: MortgageHouseholdInput[];
  @Input() household: MortgageHouseholdInput;
  monthlyCreditLiabilitiesWarning = false;

  constructor(protected cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  setMonthlyCreditLiabilitiesWarning(flag: boolean) {
    this.monthlyCreditLiabilitiesWarning = flag;
  }
}
