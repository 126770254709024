import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { ProductTypeService } from 'exoffice-js-client';
import { ServiceFactory } from '../../service-factory';

export function ProductTypesServiceFactory(oAuthService: OAuthService, httpClient: HttpClient) {
  return new ServiceFactory(ProductTypeService, oAuthService, httpClient).createService();
}

export let productTypeServiceProvider = [
  {
    provide: ProductTypeService,
    useFactory: ProductTypesServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
