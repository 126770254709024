import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ModalOfferFiltersComponent } from './modal-offer-filters.component';
import { TabsModule } from 'ngx-bootstrap';
import { VendorsFilterComponent } from './partials/vendors-filter/vendors-filter.component';
import { VisibilityTreeComponent } from './partials/visibility-tree/visibility-tree.component';
import { OffersAdditionalParamsComponent } from './partials/offers-additional-params/offers-additional-params.component';
import { FormsModule } from '@angular/forms';
import { ProductTypesFilterComponent } from './partials/product-types-filter/product-types-filter.component';
import { ListOfEntitiesComponent } from '../list-of-entities/list-of-entities.component';

@NgModule({
  declarations: [
    ModalOfferFiltersComponent,
    VendorsFilterComponent,
    ProductTypesFilterComponent,
    VisibilityTreeComponent,
    OffersAdditionalParamsComponent,
    ListOfEntitiesComponent
  ],
  imports: [BrowserModule, RouterModule, FormsModule, TabsModule.forRoot()],
  exports: [ModalOfferFiltersComponent, VendorsFilterComponent, ListOfEntitiesComponent]
})
export class ModalOfferFiltersModule {}
