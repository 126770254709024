import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as MortgageOffersActions from '../../reducers/offers/mortgage-offers/mortgage-offers.actions';
import * as CashOffersActions from '../../reducers/offers/cash-offers/cash-offers.actions';

import { Store } from '@ngrx/store';
import { AppStore } from '../../models/app-store.model';
import { MortgageOffersEntities } from '../../reducers/offers/mortgage-offers/mortgage-offers.reducer';
import { CashOffersEntities } from '../../reducers/offers/cash-offers/cash-offers.reducer';
import { CashOffer, MortgageOffer } from 'exoffice-js-client';

@Injectable({
  providedIn: 'root'
})
export class OffersNgrxService {
  mortgageOffers: Observable<MortgageOffersEntities>;
  cashOffers: Observable<CashOffersEntities>;
  constructor(private store: Store<AppStore>) {
    this.mortgageOffers = this.store.select('mortgageOffers');
    this.cashOffers = this.store.select('cashOffers');
  }

  getMortgageOfferById(id: string | number): Observable<MortgageOffer> {
    return this.store.select((state: AppStore) => state.mortgageOffers.entities[id]);
  }

  getCashOfferById(id: string | number): Observable<CashOffer> {
    return this.store.select((state: AppStore) => state.cashOffers.entities[id]);
  }

  loadMortgageOffers(params): void {
    this.store.dispatch({ type: MortgageOffersActions.LOAD_MORTGAGE_OFFERS, payload: params });
  }

  updateMortgageOffer(id: string | number, offer: MortgageOffer, withRRSO?: boolean): void {
    this.store.dispatch({ type: MortgageOffersActions.UPDATE_ONE, id, changes: { loading: true } });
    this.store.dispatch({ type: MortgageOffersActions.UPDATE_MORTGAGE_OFFER, payload: { id, offer, withRRSO } });
  }

  updateMortgageOffers(offers: MortgageOffer[]): void {
    this.store.dispatch({ type: MortgageOffersActions.UPSERT_OFFERS, payload: { offers } });
  }

  updateMortgageOffersCrossSells(simulationId, selected, offerCrossSells): void {
    this.store.dispatch({
      type: MortgageOffersActions.UPDATE_MORTGAGE_OFFERS_CROSS_SELLS,
      payload: { simulationId, selected, offerCrossSells }
    });
  }

  updateCashOffer(id: string | number, offer: CashOffer): void {
    this.store.dispatch({ type: CashOffersActions.UPDATE_ONE, id, changes: { loading: true } });
    this.store.dispatch({ type: CashOffersActions.UPDATE_CASH_OFFER, payload: { id, offer } });
  }

  loadCashOffers(params): void {
    this.store.dispatch({ type: CashOffersActions.LOAD_CASH_OFFERS, payload: params });
  }
}
