import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

@Directive({
  selector: '[ngModel][amount]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmountDirective),
      multi: true
    }
  ]
})
export class AmountDirective implements ControlValueAccessor {
  @HostListener('blur', ['$event.target.value'])
  blur(value) {
    this.writeValue(this.modifyValue(value));
  }
  @HostListener('input', ['$event.target.value'])
  input(value) {
    this.onChange(this.modifyValue(value));
  }
  constructor(private el: ElementRef, private renderer: Renderer2, private _decimalPipe: DecimalPipe) {}

  onChange = (fn: any) => {};
  onTouched = () => {};

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  modifyValue(value) {
    return value
      .split(',')
      .join('.')
      .split(' ')
      .join('')
      .split(' ')
      .join('');
  }

  writeValue(value: any): void {
    if (!_.isUndefined(value)) {
      this.renderer.setProperty(
        this.el.nativeElement,
        'value',
        this._decimalPipe.transform(parseFloat(value), '1.0-2')
      );
    }
  }
}
