import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/pluck';
import { OrderBy } from '../../../../models/order-by';

import { OfferFiltersNgrxService } from '../../../../services/ngrx/offer-filters-ngrx.service';
import { MortgageSimulationService } from '../../../../services/simulation/mortgage/mortgage-simulation.service';
import { visibilityTreeMortgageServiceProvider } from '../../../../services/visibility-tree/mortgage/visibility-tree.mortgage.provider';
import { VisibilityTreeService } from '../../../../services/visibility-tree/visibility-tree.service';
import { CompareOffersBaseComponent } from '../base/compare-offers-base.component';
import { ORDER_LIST_MORTGAGE } from '../components/order-by/mortgage/compare-offers-mortgage.order-list.constant';

import { MortgageSimulation, MortgageOffer } from 'exoffice-js-client';
import * as _ from 'lodash';
import { SimulationNgrxService } from '../../../../services/ngrx/simulation-ngrx.service';
import { OffersNgrxService } from '../../../../services/ngrx/offers-ngrx.service';
import { takeUntil } from 'rxjs/operators';
import { mortgageOfferServiceProvider } from '../../../../services/providers/mortgage-offer-service.provider';
import { ExpandableRowNgrxService } from '../../../../services/ngrx/expandable-row-ngrx.service';

import { ExpandedCrossSellsNgrxService } from '../../../../services/ngrx/expanded-cross-sells-ngrx.service';
import { Dictionary } from '@ngrx/entity';
import * as Immutable from 'immutable';
import { ExpandedCrossSells } from '../../../../reducers/expanded-cross-sells/expanded-cross-sells.reducer';
import InstallmentTypeEnum = MortgageSimulation.InstallmentTypeEnum;
import { EditBoxService } from '../../../../services/simulation/edit-box/edit-box.service';
import { Product } from 'exoffice-js-client/model/product';
import ProductInterestRateTypeEnum = Product.InterestRateTypeEnum;

@Component({
  selector: 'compare-offers-mortgage',
  templateUrl: './compare-offers-mortgage.component.html',
  styleUrls: ['../base/compare-offers-base.component.scss'],
  providers: [mortgageOfferServiceProvider, visibilityTreeMortgageServiceProvider, OfferFiltersNgrxService]
})
export class CompareOffersMortgageComponent extends CompareOffersBaseComponent {
  orderList: OrderBy[] = ORDER_LIST_MORTGAGE;
  expandedCrossSells: Immutable.Record<ExpandedCrossSells>;

  constructor(
    @Inject(VisibilityTreeService) visibilityTreeService,
    @Inject(MortgageSimulationService) mortgageSimulationService,
    @Inject(OfferFiltersNgrxService) offerFiltersService,
    @Inject(ActivatedRoute) activatedRoute,
    @Inject(SimulationNgrxService) simulationNgrxService,
    @Inject(OffersNgrxService) offersNgrxService,
    @Inject(ExpandableRowNgrxService) expandableRowNgrxService,
    @Inject(OffersNgrxService) offersEntitiesNgrxService,
    private editBoxService: EditBoxService,
    private expandedCrossSellsNgrxService: ExpandedCrossSellsNgrxService
  ) {
    super(
      visibilityTreeService,
      mortgageSimulationService,
      offerFiltersService,
      activatedRoute,
      simulationNgrxService,
      offersNgrxService,
      params => offersNgrxService.loadMortgageOffers(params),
      expandableRowNgrxService
    );

    let previousEntitiesIds = [];
    this.offersEntitiesNgrxService.mortgageOffers.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.entities = data.entities;
      this.entitiesIds = data.ids;
      this.loading = data.loading;
      if (this.entitiesIds !== previousEntitiesIds) {
        previousEntitiesIds = this.entitiesIds;
      }
      this.setExternalCrossSells(this.entitiesIds, this.entities);
    });
  }

  isEditBoxActive(): boolean {
    return this.editBoxService.isActive();
  }

  setExternalCrossSells(entitiesIds: string[] | number[], entities: Dictionary<MortgageOffer>) {
    this.expandedCrossSellsNgrxService.loadInitialExpandedCrossSells();
    this.expandedCrossSellsNgrxService.expandedCrossSells.takeUntil(this._unsubscribeAll).subscribe(data => {
      this.expandedCrossSells = data;
    });
    for (const id of entitiesIds) {
      entities[id].offerCrossSells.forEach(offerCrossSell => {
        const vendorCrossSell = offerCrossSell.vendorCrossSells.find(
          vcs => offerCrossSell.vendorCrossSellId === vcs.id
        );
        if (
          offerCrossSell.included &&
          vendorCrossSell &&
          vendorCrossSell.contributionsFeeCalculationBase === 'debt_balance'
        ) {
          this.expandedCrossSellsNgrxService.setExpandedCrossSell(offerCrossSell.crossSell.name, true);
        }
      });
    }
  }

  updateSimulation(simulation?: MortgageSimulation) {
    this.loading = true;
    _.assign(this.simulation, simulation);
    this.simulationNgrxService.updateMortgageSimulation(this.simulation);
  }

  productInterestRateTypeOnChange(productInterestRateType: ProductInterestRateTypeEnum) {
    console.log(productInterestRateType);
  }

  updateInstallmentType(installmentType: InstallmentTypeEnum) {
    this.loading = true;
    this.simulationNgrxService.updateMortgageSimulation({ ...this.simulation, installmentType });
  }
}
