import { Component, Input, OnInit } from '@angular/core';
import { OfferFiltersNgrxService } from '../../../../../services/ngrx/offer-filters-ngrx.service';

@Component({
  selector: 'product-variant-included-filter',
  templateUrl: './product-variant-included-filter.component.html',
  styleUrls: ['./product-variant-included-filter.component.scss']
})
export class ProductVariantIncludedFilterComponent implements OnInit {
  productVariantIncludedValue: boolean | null;
  key: 'productVariantIncluded' = 'productVariantIncluded';

  @Input() loading = false;

  setFilter(value) {
    this.offerFiltersService.add({ key: this.key, value });
  }

  constructor(private offerFiltersService: OfferFiltersNgrxService) {}

  ngOnInit() {
    this.offerFiltersService.filters.subscribe(data => {
      this.productVariantIncludedValue = data.get(this.key);
    });
  }
}
