import * as Actions from './attribute-definitions.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { AttributeDefinition as AttributeDefinitionBase } from 'exoffice-js-client';

type Action = Actions.All;

export interface AttributeDefinition extends AttributeDefinitionBase {
  isVisible: boolean;
  sectionId: string;
}
export const attributeDefinitionsAdapter = createEntityAdapter<AttributeDefinition>();
export interface AttributeDefinitionsEntities extends EntityState<AttributeDefinition> {
  loading: boolean;
  loaded: boolean;
}
export const initialState: AttributeDefinitionsEntities = attributeDefinitionsAdapter.getInitialState({
  // additional entity state properties
  loading: false,
  loaded: false
});
export default function AttributeDefinitionsReducer(
  state: AttributeDefinitionsEntities = initialState,
  action?: Action
): any {
  switch (action.type) {
    case Actions.UPDATE_ONE:
      return attributeDefinitionsAdapter.updateOne({ id: action.id, changes: action.changes }, state);

    case Actions.LOAD_ATTRIBUTE_DEFINITIONS: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }
    case Actions.LOAD_ATTRIBUTE_DEFINITIONS_SUCCESS: {
      return {
        ...attributeDefinitionsAdapter.map(
          item => ({ ...item, isVisible: true }),
          attributeDefinitionsAdapter.addAll(action.payload, state)
        ),
        loaded: true,
        loading: false
      };
    }
    case Actions.LOAD_ATTRIBUTE_DEFINITIONS_FAILURE: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }
    case Actions.MAP: {
      return attributeDefinitionsAdapter.map(action.entityMap, state);
    }
    default:
      return state;
  }
}
