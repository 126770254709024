import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { Product } from 'exoffice-js-client/model/product';
import InterestRateTypeEnum = Product.InterestRateTypeEnum;
import { OfferFiltersNgrxService } from '../../../../../services/ngrx/offer-filters-ngrx.service';
import * as Immutable from 'immutable';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'choose-product-interest-rate-type',
  templateUrl: './choose-product-interest-rate-type.component.html',
  styleUrls: ['./choose-product-interest-rate-type.component.scss']
})
export class ChooseProductInterestRateTypeComponent implements OnInit, OnDestroy {
  interestRateTypes = _.values(InterestRateTypeEnum);
  public _unsubscribeAll: Subject<any> = new Subject();
  @Input()
  loading: boolean;
  interestRateType: InterestRateTypeEnum;

  constructor(private offerFiltersService: OfferFiltersNgrxService) {}

  ngOnInit() {
    this.offerFiltersService.params.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.interestRateType = data.get('productInterestRateType');
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
  }

  changeInterestRateType(interestRateType: InterestRateTypeEnum) {
    this.interestRateType = interestRateType;
    this.offerFiltersService.add({
      key: 'params',
      value: Immutable.fromJS({ productInterestRateType: interestRateType })
    });
  }
}
