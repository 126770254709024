export const OFFER_FILTERS_LIST: object[] = [
  {
    key: 'productVendorIdIn',
    type: 'array'
  },
  {
    key: 'productProductTypeIdIn',
    type: 'array'
  },
  {
    key: 'active',
    type: 'boolean'
  },
  {
    key: 'creditworthiness',
    type: 'boolean'
  },
  {
    key: 'offersIds',
    type: 'array'
  }
];
