import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { VendorService } from 'exoffice-js-client';
import { ServiceFactory } from '../service-factory';

export function VendorServiceFactory(oAuthService: OAuthService, http: HttpClient) {
  return new ServiceFactory(VendorService, oAuthService, http).createService();
}

export let vendorServiceProvider = [
  {
    provide: VendorService,
    useFactory: VendorServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
