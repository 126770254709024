import { Action } from '@ngrx/store';
import { City } from 'exoffice-js-client';

export const LOAD_CITIES = '[Cities - API] Load cities';
export const LOAD_CITIES_SUCCESS = '[Cities - API] Load cities success';
export const LOAD_CITIES_FAILURE = '[Cities - API] Load cities failure';

export class LoadCities implements Action {
  readonly type = LOAD_CITIES;
  constructor() {}
}

export class LoadCitiesSuccess implements Action {
  readonly type = LOAD_CITIES_SUCCESS;
  constructor(public payload: City[]) {}
}

export class LoadCitiesFailure implements Action {
  readonly type = LOAD_CITIES_FAILURE;
  constructor() {}
}

export type All = LoadCities | LoadCitiesSuccess | LoadCitiesFailure;
