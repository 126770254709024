import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { ItemAttributeService } from 'exoffice-js-client';
import { ServiceFactory } from '../../service-factory';

export function itemAttributeServiceFactory(oAuthService: OAuthService, httpClient: HttpClient) {
  return new ServiceFactory(ItemAttributeService, oAuthService, httpClient).createService();
}

export let itemAttributeServiceProvider = [
  {
    provide: ItemAttributeService,
    useFactory: itemAttributeServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
