import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import * as LoanPurposesActions from '../../reducers/loan-purposes/loan-purposes.actions';
import * as IncomeTypesActions from '../../reducers/income-types/income-types.actions';
import * as ProvincesActions from '../../reducers/cities/cities.actions';

import { AppStore } from '../../models/app-store.model';
import { IncomeTypesEntities } from '../../reducers/income-types/income-types.reducer';
import { LoanPurposesEntities } from '../../reducers/loan-purposes/loan-purposes.reducer';
import { CitiesEntities } from '../../reducers/cities/cities.reducer';

@Injectable()
export class DictionariesNgrxService {
  loanPurposes: Observable<LoanPurposesEntities>;
  isLoanPurposesLoading: Observable<boolean>;
  isLoanPurposesLoaded: Observable<boolean>;

  incomeTypes: Observable<IncomeTypesEntities>;
  isIncomeTypesLoading: Observable<boolean>;
  isIncomeTypesLoaded: Observable<boolean>;

  cities: Observable<CitiesEntities>;
  citiesLoading: Observable<boolean>;
  citiesLoaded: Observable<boolean>;

  constructor(private store: Store<AppStore>) {
    this.loanPurposes = store.select('loanPurposes');
    this.isLoanPurposesLoading = store.select(state => state.loanPurposes.loading);
    this.isLoanPurposesLoaded = store.select(state => state.loanPurposes.loaded);
    this.incomeTypes = store.select('incomeTypes');
    this.isIncomeTypesLoading = store.select(state => state.incomeTypes.loading);
    this.isIncomeTypesLoaded = store.select(state => state.incomeTypes.loaded);
    this.cities = store.select('cities');
    this.citiesLoading = store.select(state => state.cities.loading);
    this.citiesLoaded = store.select(state => state.cities.loaded);
  }

  loadLoanPurposes(): void {
    this.store.dispatch({ type: LoanPurposesActions.LOAD_LOAN_PURPOSES });
  }

  loadIncomeTypes(): void {
    this.store.dispatch({ type: IncomeTypesActions.LOAD_INCOME_TYPES });
  }

  loadCities(): void {
    this.store.dispatch({ type: ProvincesActions.LOAD_CITIES });
  }
}
