import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SnakeToCamelPipe } from './snake-to-camel.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [SnakeToCamelPipe],
  exports: [SnakeToCamelPipe]
})
export class SnakeToCamelPipeModule {}
