import { OnDestroy, OnInit, PipeTransform, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Rx';
import { AmountFormatPipe } from '../../../../pipes/amount-format/amount-format.pipe';
import { takeUntil } from 'rxjs/operators';
import { DictionariesNgrxService } from '../../../../services/ngrx/dictionaries-ngrx.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { VendorsNgrxService } from '../../../../services/ngrx/vendors-ngrx.service';
import { OfferFiltersNgrxService } from '../../../../services/ngrx/offer-filters-ngrx.service';

export class SimulationWizardBaseComponent implements OnInit, OnDestroy {
  @ViewChild('tabs', { static: false }) tabs;
  @ViewChild('tabsUpkeepCosts', { static: false }) tabsUpkeepCosts;
  @ViewChild('formWizard', { static: false }) formWizard;
  errors: any[] = [];
  loading: boolean;
  amountFormatPipe: PipeTransform;
  loanPeriodIsMonthly = false;
  loanPeriodYears: number;
  simulationId: string;
  loadingNewSimulation = false;
  editMode = false;
  clientId: string;
  data: any;
  provinces: any[];
  public _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public dictionariesNgrxService: DictionariesNgrxService,
    public toastr: ToastrService,
    public vendorsNgrxService: VendorsNgrxService,
    public offerFiltersNgrxService: OfferFiltersNgrxService
  ) {
    this.amountFormatPipe = new AmountFormatPipe();
    this.editMode = this.router.url.includes('edit');
  }

  ngOnInit(): void {
    this.offerFiltersNgrxService.remove('active');
    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(params => {
      this.clientId = params['clientId'];
    });
    this.setInitialModel();
    this.vendorsNgrxService.loadVendors();
  }

  getProvinces(): any {
    return new Observable(observer => {
      this.dictionariesNgrxService.cities.pipe(takeUntil(this._unsubscribeAll)).subscribe(cities => {
        const provinces = _(_.values(cities.entities))
          .groupBy(lp => lp.province.id)
          .map(group => {
            return {
              cities: group,
              name: _.first(group).province.name,
              id: _.first(group).province.id
            };
          })
          .value();
        observer.next(provinces);
      });
    });
  }

  getSimulationError(err) {
    this.formWizard.goToFirstStep();
    this.errors = err.error.errors;
    this.toastr.error('Błąd 422. Sprawdź ponownie wprowadzone dane.');
    this.loading = false;
    this.formWizard.setAsNotCompleted();
  }

  loadIncomeTypes() {
    this.dictionariesNgrxService.isIncomeTypesLoading.pipe(takeUntil(this._unsubscribeAll)).subscribe(loading => {
      this.loading = loading;
    });
  }

  monthsAreDivisibleToYears(months) {
    if (months % 12 === 0) {
      this.loanPeriodIsMonthly = false;
      this.loanPeriodYears = months / 12;
    } else {
      this.loanPeriodIsMonthly = true;
    }
  }

  setInitialModel() {}

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
