import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
import * as ExpandableKnowledgeRowsActions from '../../reducers/knowledge-base/expandable-knowledge-rows/expandable-knowledge-rows.actions';

import { AppStore } from '../../models/app-store.model';
import { EntityState } from '@ngrx/entity';
import { ExpandableKnowledgeRow } from '../../reducers/knowledge-base/expandable-knowledge-rows/expandable-knowledge-rows.reducer';

@Injectable({
  providedIn: 'root'
})
export class ExpandableKnowledgeRowNgrxService {
  rows: Observable<EntityState<ExpandableKnowledgeRow>>;

  constructor(private store: Store<AppStore>) {
    this.rows = store.select('expandableKnowledgeRows');
  }

  setHeight(id: string, height: ExpandableKnowledgeRow['height']): void {
    this.store.dispatch({
      type: ExpandableKnowledgeRowsActions.UPSERT_EXPANDABLE_KNOWLEDGE_ROW,
      payload: { id, height }
    });
  }

  toggleExpand(id: string, isExpanded: ExpandableKnowledgeRow['isExpanded']): void {
    this.store.dispatch({
      type: ExpandableKnowledgeRowsActions.UPSERT_EXPANDABLE_KNOWLEDGE_ROW,
      payload: { id, isExpanded }
    });
  }

  getItemById(id: string): Observable<ExpandableKnowledgeRow> {
    return this.store.select((state: AppStore) => state.expandableKnowledgeRows.entities[id]);
  }
}
