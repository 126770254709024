import { AfterViewInit, Directive, ElementRef, Input, HostListener, Renderer2 } from '@angular/core';
import { ExpandableRowNgrxService } from '../../services/ngrx/expandable-row-ngrx.service';
import { ExpandableRows } from '../../reducers/expandable-rows/expandable-rows';

@Directive({
  selector: '[expandableRowHeading]'
})
export class ExpandableRowHeadingDirective implements AfterViewInit {
  height: number;
  storageIsExpanded: boolean;
  @Input()
  expandableRowId: keyof ExpandableRows;
  @Input()
  isNotExpandable = false;

  @HostListener('click', ['$event.target'])
  onClick() {
    this.expandableRowNgrxService.toggleExpand(this.expandableRowId, !this.storageIsExpanded);
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private expandableRowNgrxService: ExpandableRowNgrxService
  ) {}

  ngAfterViewInit() {
    if (!this.isNotExpandable) {
      this.renderer.addClass(this.el.nativeElement, 'expandable-row-heading');
    }
    this.expandableRowNgrxService.getItemById(this.expandableRowId).subscribe(data => {
      if (data) {
        const storedHeight = data.get('height');
        this.storageIsExpanded = data.get('isExpanded') || this.isNotExpandable;
        this.el.nativeElement.style.height = this.storageIsExpanded && storedHeight !== 0 ? storedHeight + 'px' : null;
        if (this.storageIsExpanded) {
          this.renderer.addClass(this.el.nativeElement, 'expandable-row-heading-transform');
        } else {
          this.renderer.removeClass(this.el.nativeElement, 'expandable-row-heading-transform');
        }
      }
    });
    if (!this.isNotExpandable) {
      this.el.nativeElement.firstChild.insertAdjacentHTML('afterEnd', '<i class="fa fa-chevron-down"></i>');
    }
  }
}
