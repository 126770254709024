import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { SimulationNgrxService } from '../../../../../services/ngrx/simulation-ngrx.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'edit-simulation',
  templateUrl: './edit-simulation.component.html',
  styleUrls: ['./edit-simulation.component.scss']
})
export class EditSimulationComponent implements OnInit, OnDestroy {
  simulationId: string;
  @Input()
  clientId: string;
  public _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public simulationNgrxService: SimulationNgrxService
  ) {}

  ngOnInit() {
    this.simulationNgrxService.simulationId.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.simulationId = data;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  editSimulation() {
    this.router.navigate([`/simulations/edit/${_.first(this.route.snapshot.url).path}`, this.simulationId], {
      queryParams: { clientId: this.clientId }
    });
  }
}
