import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { BasicComponent } from './basic.component';
import { BlankComponent } from './blank.component';

import { FooterModule } from '../footer/footer.module';
import { FullWidthNavbarModule } from '../full-width-navbar/full-width-navbar.module';
import { NavigationModule } from '../navigation/navigation.module';
import { TopnavbarModule } from '../topnavbar/topnavbar.module';
import { TopNavigationComponent } from './top-navigation.component';

@NgModule({
  declarations: [BlankComponent, BasicComponent, TopNavigationComponent],
  imports: [BrowserModule, RouterModule, NavigationModule, TopnavbarModule, FooterModule, FullWidthNavbarModule],
  exports: [BlankComponent, BasicComponent, TopNavigationComponent]
})
export class LayoutsModule {}
