import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EditBoxService {
  active = false;

  constructor() {}

  activateEditBox(): void {
    this.active = true;
  }
  deactivateEditBox(): void {
    this.active = false;
  }
  isActive(): boolean {
    return this.active;
  }
}
