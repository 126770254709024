import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'nav-logo',
  templateUrl: './nav-logo.component.html',
  styleUrls: ['./nav-logo.component.scss']
})
export class NavLogoComponent implements OnInit {
  activeUrl: string;

  constructor(private router: Router) {}

  ngOnInit() {
    this.activeUrl = this.router.url;
    this.router.events.subscribe(() => {
      this.activeUrl = this.router.url;
    });
  }

  isUrlIncluded(phrase: string) {
    return this.activeUrl.includes(phrase);
  }
}
