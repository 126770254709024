import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import * as VendorProductTypes from '../../reducers/knowledge-base/vendor-product-types/vendor-product-types.actions';
import { VendorProductTypeService } from 'exoffice-js-client';
import * as ItemAttributesActions from '../../reducers/knowledge-base/item-attributes/item-attributes.actions';

@Injectable()
export class VendorProductTypesEffects {
  // Listen for the 'GET_VENDOR_PRODUCTS_CATEGORIES' action
  @Effect()
  getVendorProductType$: Observable<Action> = this.actions$.pipe(
    ofType(VendorProductTypes.GET_VENDOR_PRODUCTS_TYPES_AND_ITEM_ATTRIBUTES),
    mergeMap((action: VendorProductTypes.GetVendorsProductsTypesAndItemAttributes) => {
      return this._vendorProductTypeService
        .findVendorProductTypes(
          action.payload.sorts,
          action.payload.productCategoryId,
          action.payload.productCategoryCode
        )
        .pipe(
          // If successful, dispatch success action with result
          switchMap(data => [
            {
              type: ItemAttributesActions.GET_ITEM_ATTRIBUTES,
              payload: { itemIdIn: data.content.map(item => item.id) }
            },
            { type: VendorProductTypes.LOAD_VENDOR_PRODUCT_TYPES_SUCCESS, payload: data.content }
          ]),
          // If request fails, dispatch failed action
          catchError(data => of({ type: VendorProductTypes.LOAD_VENDOR_PRODUCT_TYPES_FAILURE }))
        );
    })
  );

  constructor(private actions$: Actions, private _vendorProductTypeService: VendorProductTypeService) {}
}
