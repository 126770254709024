import { Component, Input, OnInit } from '@angular/core';
import { Dictionary } from '../../../../../../../models/dictionary.model';
import { VisibilityTreeService } from '../../../../../../../services/visibility-tree/visibility-tree.service';
import * as _ from 'lodash';
import { AdditionalCostsPipe } from '../../../../../../../pipes/additional-costs/additional-costs.pipe';
import { FindByPipe } from '../../../../../../../pipes/find-by/find-by.pipe';
import { FrequencyType } from '../../../../../../../components/common/frequency-type/frequency-type';
import { MONTHLY_COSTS } from '../../../../constants/monthly-costs';

@Component({
  selector: 'cost-sum',
  templateUrl: './cost-sum.component.html',
  styleUrls: ['./cost-sum.component.scss']
})
export class CostSumComponent implements OnInit {
  monthlyCosts: Dictionary[] = MONTHLY_COSTS;

  @Input()
  offer: any;

  @Input()
  identity: string;

  @Input()
  frequency: FrequencyType;

  constructor(
    public visibilityTreeService: VisibilityTreeService,
    private additionalCostsPipe: AdditionalCostsPipe,
    private findByPipe: FindByPipe
  ) {}

  ngOnInit() {}

  isEmpty(obj) {
    return _.isEmpty(obj);
  }

  isDetailsVisible(identity) {
    return this.visibilityTreeService.isDetailsVisible(identity);
  }

  toggleDetailsVisibility(id) {
    this.visibilityTreeService.toggleDetails(id);
  }

  getCostsSum() {
    const costs = this.additionalCostsPipe.transform(this.offer.additionalCosts.content, this.frequency);
    const fn = (sum, item) => {
      const totalCost = this.getCostByKeyAndValue(costs, item.key)
        ? parseFloat(this.getCostByKeyAndValue(costs, item.key).totalCost)
        : 0;
      return sum + totalCost;
    };
    return _.reduce(this.monthlyCosts, fn, 0).toFixed(2);
  }

  getCostByKeyAndValue(costs, value, key = 'name') {
    return this.findByPipe.transform(costs, key, value);
  }

  findCost(key: string) {
    const costs = this.additionalCostsPipe.transform(this.offer.additionalCosts.content, this.frequency);
    return this.getCostByKeyAndValue(costs, key);
  }
}
