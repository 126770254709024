import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { SpinWithBlockModule } from '../../../../components/common/spin-with-block/spin-with-block.module';
import { AmountModule } from '../../../../directives/amount/amount.module';
import { AmountFormatPipeModule } from '../../../../pipes/amount-format/amount-format.module';
import { RateFormatPipeModule } from '../../../../pipes/rate-format/rate-format.module';
import { HeadingColumnComponent } from '../components/heading-column/heading-column.component';
import { CrossSellComponent } from '../components/offer/components/cross-sell/cross-sell.component';
import { EditOfferAttributeComponent } from '../components/offer/components/edit-offer-attribute/edit-offer-attribute.component';
import { EditOfferMultipleAttributeComponent } from '../components/offer/components/edit-offer-multiple-attribute/edit-offer-multiple-attribute.component';

import { CompareOffersMortgageComponent } from '../mortgage/compare-offers-mortgage.component';

import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OfferContentModule } from '../../../../components/common/offer-content/offer-content.module';
import { SanitizatorsModule } from '../../../../components/common/sanitizators/sanitizators.module';
import { ScrollToModule } from '../../../../components/common/scroll-to/index';
import { UiSwitchModule } from '../../../../components/common/ui-switch/ui-switch.module';
import { AdditionalCostsPipeModule } from '../../../../pipes/additional-costs/amount-costs.module';
import { SnakeToCamelPipeModule } from '../../../../pipes/snake-to-camel/snake-to-camel.module';
import { SnakeToCamelPipe } from '../../../../pipes/snake-to-camel/snake-to-camel.pipe';
import { CompareOffersCashComponent } from '../cash/compare-offers-cash.component';
import { CreditWorthinessComponent } from '../components/credit-worthiness/credit-worthiness.component';
import { EditSimulationComponent } from '../components/edit-simulation/edit-simulation.component';
import { FilterByComponent } from '../components/filter-by/filter-by.component';
import { CashOfferComponent } from '../components/offer/cash/offer-cash.component';
import { TooltipContentIncludedRequiredComponent } from '../components/offer/components/cross-sell/tooltip-content-included-required/tooltip-content-included-required.component';
import { MortgageOfferComponent } from '../components/offer/mortgage/offer-mortgage.component';
import { OrderByComponent } from '../components/order-by/order-by.component';
import { SaveSimulationCashComponent } from '../components/save-simulation/cash/save-simulation-cash.component';
import { SaveSimulationMortgageComponent } from '../components/save-simulation/mortgage/save-simulation-mortgage.component';

import { CostlessCrossSellPipeModule } from '../../../../pipes/costless-cross-sell/costless-cross-sell.module';
import { getReducers, reducerToken } from '../../../../reducers';
import { ChooseInstallmentTypeComponent } from '../components/choose-installment-type/choose-installment-type.component';
import { CrossSellHeadingCellComponent } from '../components/cross-sell-heading-cell/cross-sell-heading-cell.component';
import { LabelContentIncludedRequiredComponent } from '../components/offer/components/cross-sell/label-content-included-required/label-content-included-required.component';
import { TooltipContentIncludedComponent } from '../components/offer/components/cross-sell/tooltip-content-included/tooltip-content-included.component';
import { FindByPipeModule } from '../../../../pipes/find-by/find-by.module';
import { CommonModule } from '@angular/common';
import { MortgageOfferHeadingComponent } from '../components/offer/mortgage/mortgage-offer-heading/mortgage-offer-heading.component';
import { CashOfferHeadingComponent } from '../components/offer/cash/cash-offer-heading/cash-offer-heading.component';

import { ModalOfferFiltersModule } from '../../../../components/common/modal-offer-filters/modal-offer-filters.module';
import { FindByPipe } from '../../../../pipes/find-by/find-by.pipe';
import { CostlessCrossSellPipe } from '../../../../pipes/costless-cross-sell/costless-cross-sell.pipe';
import { AdditionalCostsPipe } from '../../../../pipes/additional-costs/additional-costs.pipe';
import { OffersCounterComponent } from '../components/offers-counter/offers-counter.component';
import { ModalErrorsReportingModule } from '../components/offer/components/modal-errors-reporting/modal-errors-reporting.module';
import { HorizontalScrollArrowsComponent } from '../components/horizontal-scroll-arrows/horizontal-scroll-arrows.component';
import { CostsHeadingComponent } from '../components/costs-heading/costs-heading.component';
import { CrossSellEditModalComponent } from '../components/offer/components/cross-sell/cross-sell-edit-modal/cross-sell-edit-modal.component';

import { ExpandableRowModule } from '../../../../directives/expandable-row/expandable-row.module';
import { CreditTheInitialCostsComponent } from '../components/credit-the-initial-costs/credit-the-initial-costs.component';
import { CostSumComponent } from '../components/offer/components/cost-sum/cost-sum.component';
import { CrossSellMonthlyCostComponent } from '../components/offer/components/cross-sell-monthly-cost/cross-sell-monthly-cost.component';
import { CrossSellCostComponent } from '../components/offer/components/cross-sell-cost/cross-sell-cost.component';
import { CrossSellService } from '../../../../services/simulation/cross-sell/cross-sell.service';
import { DescriptionModalContentComponent } from '../components/offer/components/description-item/description-modal-content/description-modal-content.component';
import { DescriptionItemComponent } from '../components/offer/components/description-item/description-item.component';
import { CostSumMonthlyComponent } from '../components/offer/components/cost-sum-monthly/cost-sum-monthly.component';
import { SimulationBoxCashComponent } from '../simulation-box/cash/simulation-box-cash.component';
import { SimulationBoxMortgageComponent } from '../simulation-box/mortgage/simulation-box-mortgage.component';
import { CollateralValueModule } from '../../../../directives/collateral-value/collateral-value.module';
import { GeneralInformationFormModule } from '../../../../directives/general-information-form/general-information-form.module';
import { CustomFormsModule } from 'ng2-validation';
import { ExpandableColumnModule } from '../../../../directives/expandable-column/expandable-column.module';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';
import { ChooseProductInterestRateTypeComponent } from '../components/choose-product-interest-rate-type/choose-product-interest-rate-type.component';
import { EditNominalRateSchedulesAttributeComponent } from '../components/offer/components/edit-nominal-rate-schedules-attribute/edit-nominal-rate-schedules-attribute.component';
import { JumpingDotsModule } from '../../../../components/common/jumping-dots/jumping-dots.module';
import { TooltipModule } from 'ngx-bootstrap';
import { ProductVariantIncludedFilterComponent } from '../components/product-variant-included-filter/product-variant-included-filter.component';
import { ModalCrossSellsManagementModule } from '../../../../components/common/modal-cross-sells-management/modal-cross-sells-management.module';

@NgModule({
  declarations: [
    CompareOffersCashComponent,
    CompareOffersMortgageComponent,
    HeadingColumnComponent,
    CrossSellHeadingCellComponent,
    CashOfferComponent,
    MortgageOfferComponent,
    MortgageOfferHeadingComponent,
    CashOfferHeadingComponent,
    CrossSellComponent,
    CostSumComponent,
    CostsHeadingComponent,
    CreditTheInitialCostsComponent,
    EditOfferAttributeComponent,
    EditOfferMultipleAttributeComponent,
    EditNominalRateSchedulesAttributeComponent,
    TooltipContentIncludedRequiredComponent,
    LabelContentIncludedRequiredComponent,
    TooltipContentIncludedComponent,
    OrderByComponent,
    FilterByComponent,
    CrossSellMonthlyCostComponent,
    CostSumMonthlyComponent,
    CrossSellCostComponent,
    CreditWorthinessComponent,
    ProductVariantIncludedFilterComponent,
    EditSimulationComponent,
    SaveSimulationMortgageComponent,
    SaveSimulationCashComponent,
    ChooseInstallmentTypeComponent,
    ChooseProductInterestRateTypeComponent,
    OffersCounterComponent,
    HorizontalScrollArrowsComponent,
    CrossSellEditModalComponent,
    DescriptionModalContentComponent,
    DescriptionItemComponent,
    SimulationBoxCashComponent,
    SimulationBoxMortgageComponent
  ],
  imports: [
    GeneralInformationFormModule,
    BrowserModule,
    CommonModule,
    CollateralValueModule,
    CustomFormsModule,
    SpinWithBlockModule,
    FormsModule,
    TranslateModule,
    AmountFormatPipeModule,
    RateFormatPipeModule,
    SnakeToCamelPipeModule,
    AmountModule,
    UiSwitchModule,
    RouterModule,
    OfferContentModule,
    SanitizatorsModule,
    ScrollToModule,
    AdditionalCostsPipeModule,
    FindByPipeModule,
    CostlessCrossSellPipeModule,
    ModalOfferFiltersModule,
    ModalCrossSellsManagementModule,
    ModalErrorsReportingModule,
    StoreModule.forRoot(reducerToken),
    ScrollingModule,
    ExpandableRowModule,
    ExpandableColumnModule,
    OverlayscrollbarsModule,
    JumpingDotsModule,
    TooltipModule.forRoot()
  ],
  exports: [CompareOffersCashComponent, CompareOffersMortgageComponent],
  providers: [
    SnakeToCamelPipe,
    FindByPipe,
    AdditionalCostsPipe,
    CostlessCrossSellPipe,
    CrossSellService,
    {
      provide: reducerToken,
      useFactory: getReducers
    }
  ]
})
export class CompareOffersBaseModule {}
