import { Injectable } from '@angular/core';
import * as Immutable from 'immutable';

import { Store } from '@ngrx/store';
import { AppStore } from '../../models/app-store.model';
import * as ExpandedCrossSellsActions from '../../reducers/expanded-cross-sells/expanded-cross-sells.actions';
import { ExpandedCrossSells } from '../../reducers/expanded-cross-sells/expanded-cross-sells.reducer';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExpandedCrossSellsNgrxService {
  expandedCrossSells: Observable<Immutable.Record<ExpandedCrossSells>>;

  constructor(private store: Store<AppStore>) {
    this.expandedCrossSells = this.store.select('expandedCrossSells');
  }

  getExpandedCrossSellByKey(key: keyof ExpandedCrossSells) {
    return this.store.select((state: AppStore) => state.expandedCrossSells.get(key));
  }

  loadInitialExpandedCrossSells(): void {
    this.store.dispatch({ type: ExpandedCrossSellsActions.LOAD_INITIAL_EXPANDED_CROSS_SELL });
  }

  setExpandedCrossSell(name: string, value: boolean): void {
    this.store.dispatch({ type: ExpandedCrossSellsActions.SET_EXPANDED_CROSS_SELL, payload: { key: name, value } });
  }
}
