import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { KnowledgeBaseNgrxService } from '../../../../services/ngrx/knowledge-base-ngrx.service';
import { Subject } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import { VendorProductType } from 'exoffice-js-client';
import { OffersFilters } from '../../../../reducers/offers-filters/offers-filters';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'vendor-filter-modal',
  templateUrl: './vendor-filter-modal.component.html',
  styleUrls: ['./vendor-filter-modal.component.scss']
})
export class VendorFilterModalComponent implements OnInit, OnDestroy {
  entities: Dictionary<VendorProductType>;
  entitiesIds: string[] | number[];
  key: keyof OffersFilters = 'productVendorIdIn';
  private _unsubscribeAll: Subject<any> = new Subject();
  @Input()
  applyFilters$: Subject<Event> = new Subject();
  modalRef: BsModalRef;

  constructor(private knowledgeBaseNgrxService: KnowledgeBaseNgrxService, private modalService: BsModalService) {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit() {
    this.knowledgeBaseNgrxService.vendorProductsTypes.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.entities = data.entities;
      this.entitiesIds = data.ids;
      if (data.ids.length) {
        this.setFilter(data.ids);
      }
    });
  }

  applyFilter(): void {
    this.applyFilters$.next();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  setFilter(listOfActive): void {
    this.knowledgeBaseNgrxService.hideVendorProductsTypes(listOfActive);
  }
}
