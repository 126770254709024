import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { CashSimulation, MortgageSimulation } from 'exoffice-js-client';
import { SimulationNgrxService } from '../../../../../services/ngrx/simulation-ngrx.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'credit-the-initial-costs',
  templateUrl: './credit-the-initial-costs.component.html'
})
export class CreditTheInitialCostsComponent implements OnInit, OnDestroy {
  simulation: Partial<MortgageSimulation & CashSimulation>;
  public _unsubscribeAll: Subject<any> = new Subject();

  @Input()
  loading: boolean;

  @Output()
  simulationChanged = new EventEmitter<Partial<MortgageSimulation & CashSimulation>>();

  constructor(private simulationNgrxService: SimulationNgrxService) {}

  ngOnInit() {
    this.simulationNgrxService.simulationContent.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      if (data) {
        this.simulation = data.toJS() as Partial<MortgageSimulation & CashSimulation>;
      }
    });
  }

  toggleCreditTheInitialCosts() {
    this.simulation.creditTheInitialCosts = !this.simulation.creditTheInitialCosts;
    this.simulationChanged.emit(this.simulation);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
