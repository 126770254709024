// ./effects/auth.effects.ts
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as CashOffersEntitiesActions from '../../../reducers/offers/cash-offers/cash-offers.actions';
import { CashOffersQlService } from '../../../services/graphql/offers/cash/cash-offers-ql.service';
import { CashOffer } from 'exoffice-js-client';

@Injectable()
export class CashOffersEffects {
  // Listen for the 'UPDATE_CASH_OFFER' action
  @Effect()
  updateCashOffer$: Observable<Action> = this.actions$.pipe(
    ofType(CashOffersEntitiesActions.UPDATE_CASH_OFFER),

    mergeMap((action: any) => {
      return this._cashOffersQLService.updateOffer({ id: action.payload.id, ...action.payload.offer }).pipe(
        // If successful, dispatch success action with result
        map(res => ({
          type: CashOffersEntitiesActions.UPDATE_ONE,
          id: (res as any).data.updateCashOffer.cashOffer.id,
          changes: { ...(res as any).data.updateCashOffer.cashOffer, loading: false }
        }))
      );
    })
  );

  // Listen for the 'LOAD_CASH_OFFERS' action
  @Effect()
  loadCashOffers$: Observable<Action> = this.actions$.pipe(
    ofType(CashOffersEntitiesActions.LOAD_CASH_OFFERS),

    mergeMap((action: any) => {
      return this._cashOffersQLService
        .findOffersBySimulationId(
          action.payload.simulationId,
          action.payload.orderBy,
          action.payload.activeOfferFilter,
          action.payload.creditworthinessFilter,
          action.payload.productVendorIdInOfferFilter,
          action.payload.productPromotion,
          action.payload.productNoEarlyRepaymentFee,
          action.payload.productInterestRateType,
          action.payload.noProvision,
          action.payload.productTypeIdInOfferFilter
        )
        .pipe(
          // If successful, dispatch success action with result
          map(res => ({
            type: CashOffersEntitiesActions.LOAD_CASH_OFFERS_SUCCESS,
            payload: { content: (res as any).data.cashOffers.nodes as CashOffer[] }
          })),
          // If request fails, dispatch failed action
          catchError(() => of({ type: CashOffersEntitiesActions.LOAD_CASH_OFFERS_FAILED }))
        );
    })
  );

  constructor(private actions$: Actions, private _cashOffersQLService: CashOffersQlService) {}
}
