import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import * as ProductTypes from '../../reducers/product-types/product-types.actions';
import { ProductTypeService } from 'exoffice-js-client';

@Injectable()
export class ProductTypesEffects {
  // Listen for the 'GET_PRODUCT TYPES' action
  @Effect()
  getProductType$: Observable<Action> = this.actions$.pipe(
    ofType(ProductTypes.GET_PRODUCT_TYPES),
    mergeMap((action: ProductTypes.GetProductTypes) => {
      return this._ProductTypeService
        .findProductTypes(action.payload.sorts, action.payload.withVendorProductTypes)
        .pipe(
          // If successful, dispatch success action with result
          switchMap(data => [{ type: ProductTypes.LOAD_PRODUCT_TYPES_SUCCESS, payload: data.content }]),
          // If request fails, dispatch failed action
          catchError(data => of({ type: ProductTypes.LOAD_PRODUCT_TYPES_FAILURE }))
        );
    })
  );

  constructor(private actions$: Actions, private _ProductTypeService: ProductTypeService) {}
}
