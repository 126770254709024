import { Injectable } from '@angular/core';

import { GraphQLService } from '../../graphql.service';
import { cashOfferQuery } from './queries/cash-offer-query';
import { cashOfferMutation } from './queries/cash-offer-mutation';

@Injectable({
  providedIn: 'root'
})
export class CashOffersQlService {
  constructor(readonly graphQLService: GraphQLService) {}

  findOffersBySimulationId(
    simulationId: string,
    sorts?: string[],
    selected?: boolean,
    creditworthiness?: boolean,
    productVendorIdIn?: string[],
    productPromotion?: boolean,
    productNoEarlyRepaymentFee?: boolean,
    productInterestRateType?: string,
    noProvision?: boolean,
    productProductTypeIdIn?: string[]
  ) {
    return this.graphQLService.fetch(cashOfferQuery, {
      simulationId,
      sorts,
      selected,
      creditworthiness,
      productVendorIdIn,
      productPromotion,
      productNoEarlyRepaymentFee,
      productInterestRateType,
      noProvision,
      productProductTypeIdIn
    });
  }

  updateOffer(offer) {
    return this.graphQLService.mutate(cashOfferMutation, { input: offer });
  }
}
