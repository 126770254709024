import { CashOffer } from 'exoffice-js-client';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as CashOffersEntitiesActions from './cash-offers.actions';

type Action = CashOffersEntitiesActions.All;

export const offersAdapter = createEntityAdapter<CashOffer>();

export interface CashOffersEntities extends EntityState<CashOffer> {
  loading: boolean;
  loaded: boolean;
  totalElements: number;
}

export const initialState: CashOffersEntities = offersAdapter.getInitialState({
  // additional entity state properties
  loading: false,
  loaded: false,
  totalElements: 0
});

export default function CashOffersReducer(state: Readonly<CashOffersEntities> = initialState, action?: Action): any {
  switch (action.type) {
    case CashOffersEntitiesActions.ADD_ONE:
      return offersAdapter.addOne(action.offer, state);
    case CashOffersEntitiesActions.ADD_ALL: {
      return offersAdapter.addAll(action.payload.offers, state);
    }
    case CashOffersEntitiesActions.UPSERT_OFFERS: {
      return offersAdapter.upsertMany(action.payload.offers, state);
    }
    case CashOffersEntitiesActions.UPDATE_ONE:
      return offersAdapter.updateOne({ id: action.id, changes: action.changes }, state);

    case CashOffersEntitiesActions.REMOVE_ALL:
      return offersAdapter.removeAll(state);

    case CashOffersEntitiesActions.LOAD_CASH_OFFERS: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }

    case CashOffersEntitiesActions.LOAD_CASH_OFFERS_SUCCESS: {
      return {
        ...offersAdapter.addAll(action.payload.content, state),
        loaded: true,
        loading: false
      };
    }

    case CashOffersEntitiesActions.LOAD_CASH_OFFERS_FAILED: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }

    case CashOffersEntitiesActions.MAP_OFFERS: {
      return offersAdapter.map(action.map, state);
    }

    default:
      return state;
  }
}
