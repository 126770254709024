import { Component, Input, OnInit } from '@angular/core';
import { OfferCrossSell, VendorCrossSell } from 'exoffice-js-client';

@Component({
  selector: 'bank-content',
  templateUrl: './bank-content.component.html',
  styleUrls: ['./bank-content.component.scss']
})
export class BankContentComponent implements OnInit {
  @Input()
  offerCrossSell: OfferCrossSell;
  @Input()
  vendorCrossSell: VendorCrossSell;
  @Input()
  showDescription = false;
  @Input()
  useInnerHtml = false;
  @Input()
  size = 'small';

  constructor() {}

  ngOnInit() {}
}
