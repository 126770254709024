import { ExpandableColumn } from '../../reducers/expandable-columns/expandable-columns.reducer';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppStore } from '../../models/app-store.model';
import * as Actions from '../../reducers/expandable-columns/expandable-columns.actions';
import { EntityState } from '@ngrx/entity';

@Injectable({
  providedIn: 'root'
})
export class ExpandableColumnsNgrxService {
  columns: Observable<EntityState<ExpandableColumn>>;

  constructor(private store: Store<AppStore>) {
    this.columns = store.select('expandableColumns');
  }

  getItemById(id: string): Observable<ExpandableColumn> {
    return this.store.select((state: AppStore) => state.expandableColumns.entities[id]);
  }

  setWidth(id: string | number, width: number) {
    return this.store.dispatch({ type: Actions.UPSERT_EXPANDABLE_COLUMNS, payload: { id, width } });
  }

  updateAll(updates: { id: string; changes: Partial<ExpandableColumn> }) {
    return this.store.dispatch({ type: Actions.UPDATE_ALL_EXPANDABLE_COLUMNS, updates });
  }
}
