import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { ReviewsService } from 'exoffice-js-client';
import { ServiceFactory } from '../../service-factory';

export function reviewsServiceFactory(oAuthService: OAuthService, http: HttpClient) {
  return new ServiceFactory(ReviewsService, oAuthService, http).createService();
}

export let reviewServiceProvider = [
  {
    provide: ReviewsService,
    useFactory: reviewsServiceFactory,
    deps: [OAuthService, HttpClient]
  }
];
