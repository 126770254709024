import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { TabComponent } from './tab.component';

@Component({
  selector: 'tabs',
  styleUrls: ['tabs.scss'],
  template: `
    <div class="tabs-container">
      <div [class.tabs-left]="left">
        <ul class="nav nav-tabs">
          <li
            *ngFor="let tab of tabs; let i = index"
            (click)="selectTab(tab)"
            [class.active]="tab.active"
            [class.notValid]="!tab.isValid"
          >
            <a class="t"
              >{{ tab.tabTitle }}
              <i
                (click)="removeTab($event, i)"
                *ngIf="tabs.length > 1 && cantRemoveFirst"
                class="fa fa-minus-circle"
              ></i>
            </a>
          </li>
          <li *ngIf="tabs.length < maxTabsCount" (click)="addTab($event)" [class.active]="false" class="add-tab-button">
            <a *ngIf="tabs.length">Dodaj <i class="fa fa-plus-circle"></i> </a>
          </li>
        </ul>
        <div *ngIf="!tabs.length" (click)="addTab($event)" class="text-center add-tab--big">
          <span
            ><button class="btn b-r-xl">Dodaj<i class="fa add-tab--big__icon fa-plus-circle"></i></button>
          </span>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  `
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @Input() left = false;
  @Input() cantRemoveFirst = true;
  @Input() maxTabsCount = 4;
  @Input() allTabsValid: boolean;
  @Output() addButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  // contentChildren are update
  ngAfterContentInit() {
    // get all active tabs2t
    const activeTabs = this.tabs.filter(tab => tab.active);

    // if there is no active tab update, activate the first
    if (activeTabs.length === 0) {
      setTimeout(_ => this.selectTab(this.tabs.first));
    }
  }

  addTab($event) {
    this.addButtonClicked.emit($event);
  }

  removeTab($event, index) {
    this.removeButtonClicked.emit({ event: $event, index });
    return false;
  }

  selectLast() {
    setTimeout(_ => this.selectTab(this.tabs.last));
  }

  selectTab(selectedTab: TabComponent) {
    // deactivate all tabs
    this.tabs.toArray().forEach(tab => (tab.active = false));
    // activate the tab the user has clicked on.
    if (selectedTab) {
      selectedTab.active = true;
    }

    return false;
  }
}
