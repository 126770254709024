import { Action } from '@ngrx/store';
import { ExpandableKnowledgeRow } from './expandable-knowledge-rows.reducer';

export const UPSERT_EXPANDABLE_KNOWLEDGE_ROW = '[Expandable knowledge rows] Upsert one';

export class UpsertExpandableKnowledgeRow implements Action {
  readonly type = UPSERT_EXPANDABLE_KNOWLEDGE_ROW;
  constructor(public payload: ExpandableKnowledgeRow) {}
}

export type All = UpsertExpandableKnowledgeRow;
