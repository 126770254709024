import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { createSelector, Store } from '@ngrx/store';
import * as Immutable from 'immutable';
import * as ExpandableRowsActions from '../../reducers/expandable-rows/expandable-rows.actions';

import { AppStore } from '../../models/app-store.model';
import { ExpandableRows, ExpandableRow } from '../../reducers/expandable-rows/expandable-rows';

export const selectItems = (state: AppStore) => state.expandableRows;

export const getItemById = id =>
  createSelector(
    selectItems,
    allItems => {
      if (allItems) {
        return allItems.get(id);
      } else {
        return {};
      }
    }
  );

@Injectable({
  providedIn: 'root'
})
export class ExpandableRowNgrxService {
  rows: Observable<Immutable.Record<ExpandableRows>>;

  constructor(private store: Store<AppStore>) {
    this.rows = store.select('expandableRows');
  }

  setHeight(key: keyof ExpandableRows, value: ExpandableRow['height']): void {
    this.store.dispatch({ type: ExpandableRowsActions.SET_HEIGHT, payload: { key, value } });
  }

  toggleExpand(key: keyof ExpandableRows, value: ExpandableRow['isExpanded']): void {
    this.store.dispatch({ type: ExpandableRowsActions.TOGGLE_EXPAND, payload: { key, value } });
  }

  reset(): void {
    this.store.dispatch({ type: ExpandableRowsActions.RESET });
  }

  getItemById(id: keyof ExpandableRows): Observable<Immutable.Record<ExpandableRow>> {
    return this.store.select(getItemById(id));
  }
}
