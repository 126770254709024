import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SnakeToCamelPipe } from '../../../../../../../pipes/snake-to-camel/snake-to-camel.pipe';

@Component({
  selector: 'edit-offer-attribute',
  templateUrl: './edit-offer-attribute.component.html',
  styleUrls: ['./edit-offer-attribute.component.scss']
})
export class EditOfferAttributeComponent {
  editMode = false;
  originalValue: string;
  @Input() offer: any;
  @Input() enableEditing = true;
  @Input() linkRelName: any;
  @Input() value: string | null;
  @Input() unit = '%';
  @Input() key: string;
  @Input() allowNegativeValues = false;
  @Output() valueChange: EventEmitter<object> = new EventEmitter();

  constructor(private _SnakeToCamelPipe: SnakeToCamelPipe) {}

  isPossibleToRevert() {
    if (this.offer.links) {
      return this.offer.links.find(link => {
        return link.rel === this.linkRelName;
      });
    } else {
      return false;
    }
  }

  setFlagToRevertAndUpdateOffer() {
    this.offer[this._SnakeToCamelPipe.transform(this.linkRelName)] = true;
    this.valueChange.emit({
      id: this.offer.id,
      [this._SnakeToCamelPipe.transform(this.linkRelName)]: true
    });
    this.editMode = false;
  }

  updateOfferIfFieldChanged() {
    this.editMode = false;
    if (this.originalValue !== this.value) {
      this.originalValue = this.value;
    }
    const offerToUpdate = {
      id: this.offer.id,
      [this.key]: Number.parseFloat(this.value)
    };
    this.valueChange.emit(offerToUpdate);
  }

  cancelEdit() {
    this.editMode = false;
    this.value = this.originalValue;
  }

  openEdit() {
    this.editMode = true;
    this.originalValue = this.value;
  }
}
