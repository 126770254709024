import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'rate' })
export class RateFormatPipe implements PipeTransform {
  constructor(private _percentPipe: PercentPipe) {}
  transform(val) {
    if (_.isNumber(val) || val) {
      return this._percentPipe.transform(val / 100, '1.0-2');
    }
  }
}
