import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as Actions from './expandable-columns.actions';

type Action = Actions.All;
export interface ExpandableColumn {
  id: number | string;
  width: number;
}

export const expandableColumnsAdapter = createEntityAdapter<ExpandableColumn>();
export const initialState: EntityState<ExpandableColumn> = expandableColumnsAdapter.getInitialState();

export default function ExpandableColumnsReducer(
  state: EntityState<ExpandableColumn> = initialState,
  action?: Action
): any {
  switch (action.type) {
    case Actions.UPSERT_EXPANDABLE_COLUMNS: {
      return expandableColumnsAdapter.upsertOne(action.payload, state);
    }
    case Actions.UPDATE_ALL_EXPANDABLE_COLUMNS: {
      return expandableColumnsAdapter.updateMany(action.updates, state);
    }
    default: {
      return state;
    }
  }
}
