import { Action } from '@ngrx/store';
import { IncomeType } from 'exoffice-js-client';

export const LOAD_INCOME_TYPES = '[Income Types - API] Load income types';
export const LOAD_INCOME_TYPES_SUCCESS = '[Income Types - API] Load income types success';
export const LOAD_INCOME_TYPES_FAILURE = '[Income Types - API] Load income types failure';

export class LoadIncomeTypes implements Action {
  readonly type = LOAD_INCOME_TYPES;
  constructor() {}
}

export class LoadIncomeTypesSuccess implements Action {
  readonly type = LOAD_INCOME_TYPES_SUCCESS;
  constructor(public payload: IncomeType[]) {}
}

export class LoadIncomeTypesFailure implements Action {
  readonly type = LOAD_INCOME_TYPES_FAILURE;
  constructor() {}
}

export type All = LoadIncomeTypes | LoadIncomeTypesSuccess | LoadIncomeTypesFailure;
