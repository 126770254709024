import { Component, OnInit } from '@angular/core';
import { CashOfferComponent } from '../offer-cash.component';

@Component({
  selector: 'cash-offer-heading',
  templateUrl: './cash-offer-heading.component.html',
  styleUrls: ['./cash-offer-heading.component.scss']
})
export class CashOfferHeadingComponent extends CashOfferComponent implements OnInit {
  ngOnInit() {}
}
